import { Link } from "react-router-dom/dist"
import Logo from "../images/psrd.png"
import "./footer.css"

import Alert from "../alert/Alert"

import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { CreateNewsletter, clearErrors} from "../../actions/newsletterAction"


const Footer = () => {


  const { loading, message, error } = useSelector((state) => state.newsletter)
  const [email, setEmail] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (message) {
      setEmail("")
      dispatch(clearErrors())
    }


  }, [message])

  const submitNewsletter = () => {

    const newsletter = {
      email: email
    }
    dispatch(CreateNewsletter(newsletter))
  }

  const handleClick = () => {
    window.scrollTo(0, 0);
  };


  return (
    <>
      <section className="totalFooter">

        {message && <Alert type={true} time={4} message={message}></Alert>}

        <section className="news-latter">
          <div className="flex">
            <div>
              <h3>
                To subscribe to our <span>Newsletters</span>
              </h3>
            </div>
            <div>
              <div className="flex">
                <input type="text" placeholder="Your Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                <button onClick={() => submitNewsletter()}>{loading ? "loading ..." : "Subscribe"}</button>
                <p style={{ display: `${error ? "block" : "none"}` }}><i className="fa-solid fa-triangle-exclamation"></i> {error}</p>
              </div>

            </div>
          </div>
        </section>
        <footer>
          <div className="flex">
            <div className="footer-1  flex column">
              <div>
                <img src={Logo} alt="" />
              </div>
              <p>At PSRD, we aim to provide the disabled with quality education and medical treatment for their bright future. We believe that they are not disabled just Differently Abled.</p>
              <div>
                <div className="footer-social flex">
                  <a href="https://www.facebook.com/profile.php?id=100085683547755" target={"_blank"} rel="noreferrer"><i className="fa-brands fa-facebook-f flex"></i></a>
                  <a href="https://instagram.com/psrd.org.pk?igshid=YmMyMTA2M2Y=" target={"_blank"} rel="noreferrer"><i className="fa-brands fa-instagram flex"></i></a>
                  <a href="mailto:psrdsocial@gmail.com" target={"_blank"} rel="noreferrer"><i className="fa-solid fa-envelope flex"></i></a>
                  <a href="https://youtube.com/channel/UCWOanPUFY07jLLtWO7-C82w" target={"_blank"} rel="noreferrer"><i className="fa-brands fa-youtube flex"></i></a>
                  <a href="https://www.tiktok.com/@psrd.org.pk?_t=8kRZlT946WV&_r=1" target={"_blank"} rel="noreferrer"><i className="fa-brands fa-tiktok flex"></i></a>
                  <a href="https://www.linkedin.com/company/psrd-official/" target={"_blank"} rel="noreferrer"><i className="fa-brands fa-linkedin-in flex"></i></a>
                </div>
              </div>
            </div>
            <div className="footer-2 flex column">
              <h4>Quick Links​</h4>
              <ul className="flex column">
                <li className="flex">
                  <i class="fa-solid fa-check"></i><Link to={"/"} onClick={() => handleClick()}>Home</Link>
                </li>
                <li className="flex">
                  <i class="fa-solid fa-check"></i><Link to={"/history"} onClick={() => handleClick()}>History</Link>
                </li>
                <li className="flex">
                  <i class="fa-solid fa-check"></i><Link to={"/donate-now"} onClick={() => handleClick()} >Donate</Link>
                </li>
                <li className="flex">
                  <i class="fa-solid fa-check"></i><Link to={"/gallery"} onClick={() => handleClick()}>Gallery</Link>
                </li>
                <li className="flex">
                  <i class="fa-solid fa-check"></i><Link to={"/contact-us"} onClick={() => handleClick()}>Contact us</Link>
                </li>
              </ul>
            </div>
            <div className="footer-3 flex column">
              <h4>Our Departments​</h4>
              <div className="footer-departments flex">
                <ul className="flex column">
                  <li className="flex">
                    <i class="fa-solid fa-check"></i><Link to={"/psrd-hospital"} onClick={() => handleClick()}>PSRD Hospital</Link>
                  </li>
                  <li className="flex">
                    <i class="fa-solid fa-check"></i><Link to={"/our-patient-department-psrd"} onClick={() => handleClick()}>Out Patient Department</Link>
                  </li>
                  <li className="flex">
                    <i class="fa-solid fa-check"></i><Link to={"/physiotherapy-centre"} onClick={() => handleClick()}>Physiotherapy Centre</Link>
                  </li>
                  <li className="flex">
                    <i class="fa-solid fa-check"></i><Link to={"/orthotic-and-prosthetic-centre"} onClick={() => handleClick()}>Orthotics & Prosthetics Centre</Link>
                  </li>
                  <li className="flex">
                    <i class="fa-solid fa-check"></i><Link to={"/occupational-therapy-and-speech-therapy"} onClick={() => handleClick()}>Occupational Therapy & Speech Therapy</Link>
                  </li>
                  <li className="flex">
                    <i class="fa-solid fa-check"></i><Link to={"/advocacy-and-public-relations-unit/"} onClick={() => handleClick()}>Advocacy and Public Relations Unit</Link>
                  </li>
                </ul>
                <ul className="flex column">
                  <li className="flex">
                    <i class="fa-solid fa-check"></i><Link to={"/skill-development-centre"} onClick={() => handleClick()}>Skill Development Centre</Link>
                  </li>
                  <li className="flex">
                    <i class="fa-solid fa-check"></i> <Link to={"/vocational-rehabilitation-centre"} onClick={() => handleClick()}>Vocational Rehabilitation Centre</Link>
                  </li>

                  <li className="flex">
                    <i class="fa-solid fa-check"></i><Link to={"/psrd-high-school"} onClick={() => handleClick()}>PSRD High School</Link>
                  </li>
                  <li className="flex">
                    <i class="fa-solid fa-check"></i><Link to={"/psrd-college-of-rehabilitation-sciences"} onClick={() => handleClick()}>PSRD College of Rehabilitation Sciences</Link>
                  </li>
                  <li className="flex">
                    <i class="fa-solid fa-check"></i><Link className="flex" nk to={"/community-based-rehabilitation-outreach-services"} onClick={() => handleClick()}>Community Based Rehabilitation Outreach Services</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        <section className="copyRight">
          Copyright [1957] © psrd.org.pk
        </section>
      </section>
    </>

  )
}
export default Footer