import report from '../../images/annualReports/psrd Jan- June, 2023.pdf'




const NewsJulySep = () => {
    return (
        <>
            <section className="newsletterPages">
                <h2>PSRD News <br /><strong>Jan - June, 2023</strong></h2>

                <div className="flex">
                    <embed src={report} type="application/pdf" width="70%" height="800px" style={{ border: "2px solid var(--blue)", margin: "2vmax" }} />
                </div>
            </section>

        </>
    )
}
export default NewsJulySep