import {
    CREATE_NEWSLETTER_REQUEST,
    CREATE_NEWSLETTER_SUCCESS,
    CREATE_NEWSLETTER_FAIL,
    GET_ALL_NEWSLETTER_REQUEST,
    GET_ALL_NEWSLETTER_SUCCESS,
    GET_ALL_NEWSLETTER_FAIL,
    DELETE_NEWSLETTER_REQUEST,
    DELETE_NEWSLETTER_SUCCESS,
    DELETE_NEWSLETTER_FAIL,
    GET_NEWSLETTER_DETAILS_REQUEST,
    GET_NEWSLETTER_DETAILS_SUCCESS,
    GET_NEWSLETTER_DETAILS_FAIL,
    CLEAR_ERRORS,
}
    from "../constants/newsLetterconstants"


export const CreateNewsletterReducer = (state = { newsletter: {} }, action) => {
    switch (action.type) {
        case CREATE_NEWSLETTER_REQUEST:
        case GET_ALL_NEWSLETTER_REQUEST:
        case GET_NEWSLETTER_DETAILS_REQUEST:
        case DELETE_NEWSLETTER_REQUEST:
            return {
                loading: true,
            }
        case CREATE_NEWSLETTER_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                message: action.payload.message
            }
        case GET_ALL_NEWSLETTER_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                newsletter: action.payload.newsletter,
                total: action.payload.total
            }
        case GET_NEWSLETTER_DETAILS_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                isViewed: true
            }
        case DELETE_NEWSLETTER_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                isDelete: true
            }
        case CREATE_NEWSLETTER_FAIL:
        case GET_ALL_NEWSLETTER_FAIL:
        case GET_NEWSLETTER_DETAILS_FAIL:
        case DELETE_NEWSLETTER_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}