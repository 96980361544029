import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import Loader from "../../../loader/Loader"
import { DonategetFormDetails } from "../../../actions/DonateFormAction"
import "./checkout.css"
import axios from "axios"



const FormDetails = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { loading, donateData, success } = useSelector((state) => state.DonationDetails)

    const [token, setToken] = useState("")

    const [formData, setFormData] = useState({
        CURRENCY_CODE: 'PKR',
        MERCHANT_ID: '19896',
        MERCHANT_NAME: "PSRD",
        PROCCODE: "00",
        SIGNATURE: "donation",
        TXNDESC: "sadqat",
        SUCCESS_URL: "https://psrd.org.pk/success",
        FAILURE_URL: "http://psrd.org.pk/failure",
        CHECKOUT_URL: "http://psrd.org.pk/checkout"
    });

    useEffect(() => {

        dispatch(DonategetFormDetails(id))


    }, []);
    useEffect(() => {

        const fetchData = async () => {

            try {
                const response = await axios.post('/sub/api/v1/pay', {
                    MERCHANT_ID: '19896',
                    BASKET_ID: donateData._id,
                    TXNAMT: donateData.amount
                });
                setToken(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        if (success) {
            fetchData();
        }


    }, [success]);



    return (
        <>
            {
                loading || !token ? <Loader /> :
                    <section className="checkOut">
                        <h2>Empower Change, <br />
                            <span>
                                Donate Today!
                            </span>
                        </h2>
                        <form id='PayFast_payment_form' className="flex maxwidth" name='PayFast-payment-form' method='post' action="https://ipg1.apps.net.pk/Ecommerce/api/Transaction/PostTransaction">
                            <div>
                                <p>Name</p>
                                <input type="text" value={donateData && donateData.name} readOnly />
                            </div>
                            <div>
                                <p>Donation Amount</p>
                                <input type="text" name="TXNAMT" value={donateData && donateData.amount} readOnly />
                            </div>
                            <div>
                                <p>Email</p>
                                <input type="text" name="CUSTOMER_EMAIL_ADDRESS" value={donateData && donateData.email} readOnly />
                            </div>
                            <div>
                                <p>Number</p>
                                <input type="text" name="CUSTOMER_MOBILE_NO" value={donateData && donateData.number} readOnly />
                            </div>
                            <div>
                                <p>Donation Type</p>
                                <input type="text" name="VERSION" value={donateData && donateData.donationType} readOnly />
                            </div>
                            <div>
                                <p>Address</p>
                                <input type="text" value={donateData && donateData.address} readOnly />
                            </div>
                            <div style={{ display: "none" }}>
                                {/* <div> */}
                                Currency Code:
                                <input type="TEXT" name="CURRENCY_CODE" value={formData.CURRENCY_CODE} />
                                <br />
                                Merchant ID:
                                <input type="TEXT" name="MERCHANT_ID" value={formData.MERCHANT_ID} />
                                <br />
                                Merchant Name:
                                <input type="TEXT" name="MERCHANT_NAME" value={formData.MERCHANT_NAME} />
                                <br />
                                Token:
                                <input type="TEXT" name="TOKEN" value={token} /><br />
                                Success URL: <input type="TEXT" name="SUCCESS_URL" value={formData.SUCCESS_URL} />
                                <br />
                                Failure URL:
                                <input type="TEXT" name="FAILURE_URL" value={formData.FAILURE_URL} />
                                <br />
                                Checkout URL:
                                <input type="TEXT" name="CHECKOUT_URL" value={formData.CHECKOUT_URL} />
                                <br />
                                Basket ID:
                                <input type="TEXT" name="BASKET_ID" value={id} />
                                <br />
                                Transaction Date:
                                <input type="TEXT" name="ORDER_DATE" value={donateData && donateData.createdAt.slice(0, 10)} />
                                <br />
                                Signature:
                                <input type="TEXT" name="SIGNATURE" value={formData.SIGNATURE} />
                                <br />
                                Item Description:
                                <input type="TEXT" name="TXNDESC" value={formData.TXNDESC} /><br />
                                Proccode:
                                <input type="TEXT" name="PROCCODE" value={formData.PROCCODE} />
                            </div>
                            <p><i className="fa-solid fa-circle-info"></i>Remember, returning to the merchant website is crucial to ensure that your invaluable donation history is securely recorded and acknowledged.</p>
                            <div style={{ width: "100%", margin: "2vmax 0" }}>
                                <button type="submit" className="button2" >Donate Now</button>
                                <button type="submit" className="button2 button3" >Cancel Payment</button>
                            </div>

                        </form>
                    </section >

            }

        </>

    )
}
export default FormDetails