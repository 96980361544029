
import PhysiotherapyCentreImg from "../../images/departments/Physiotherapy Centre-01.png"
import "./physiotherapists.css"
import skewBg from "../../images/message-01.png"

import { useEffect } from "react"

const PhysiotherapyCentre = () => {

    useEffect(() => {
        const department = document.getElementById("department");
        department.classList.add("active-page");
        document.title="Physiotherapy Centre"
        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);
    return (
        <>
            <section className="skew-style physiotherapy-centre" >
                <h2 className="mobile-heading">physiotherapy <span>centre</span>
                <br/><strong>Achievements: July 2022 - June 2023</strong>
                </h2>

                <img src={skewBg} alt="" className="skew-img" />
                <div className="maxwidth flex column">
                    <div className="flex heading-skew">
                        <div>
                            <h2>physiotherapy <span>centre</span>
                            <br/><strong>Achievements: July 2022 - June 2023</strong>
                            </h2>
                            <p>
                                The Physiotherapy Centre is well equipped with modern
                                equipment and manned by qualified physiotherapists to
                                provide the best treatment to patients and school children with
                                different musculoskeletal, neurological impairments, traumatic
                                injuries and other post-operative conditions. The PT Centre is easily
                                accessible to all segments of society. PSRD provides substantial financial
                                relief to patients according to their category determined by the social
                                welfare officers where E Category patients are treated free of cost.
                            </p>
                        </div>

                        <div>
                            <img src={PhysiotherapyCentreImg} alt="" />
                        </div>
                    </div>

                </div>
            </section>
            <section className="physiotherapy-centre-2" >
                <div className="maxwidth" style={{ padding: "40px 50px" }}>
                    <p style={{ color: "white" }}>
                        The daily patient average has reached 180 plus, and in an effort to enhance accessibility and
                        continuity of care, we now provide accommodation for patients who travel from distant areas
                        to undergo physiotherapy treatment at the hospital.<br /><br />
                        With the grace of Allah, PSRD is dedicated to delivering top-notch treatment at remarkably
                        low costs. The success rate is commendable, with patients expressing complete satisfaction.
                        Notably, in the past year, we acquired a hydrocolator, enhancing our capacity for providing
                        improved treatment to our patients.<br /><br />
                        Over the last year, we welcomed a new physiotherapist specifically focused on school
                        children. This practitioner administers treatments directly within the school premises. The
                        overall health and well-being of these school children has witnessed significant improvement
                        and increased satisfaction among their parents.
                    </p>
                </div>
            </section>
            <section className="physiotherapy-centre-3">
                <div className="maxwidth flex column">
                    <h3 className="h2-style" style={{ textAlign: "center", paddingBottom: "40px" }}>Total number of <br />patients and treatments</h3>
                    <div>
                        <div className="flex physiotherapy-centre-table">
                            <h5 className="flex">Total patients</h5>
                            <p className="flex">12,291</p>
                        </div>
                        <div className="flex physiotherapy-centre-table">
                            <h5 className="flex">Total treatments</h5>
                            <p className="flex">103,230
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="physiotherapy-centre-success-story">
                <div className="maxwidth">
                    <div>
                        <i class="fa-solid fa-award"></i>
                        <h3>Success Story</h3>
                    </div>
                    <div>
                        <h3 className="mobile-error-1">Success Story</h3>

                        <p>Muhammad Usman Butt,
                            a 29-year-old tailor by
                            profession, sought help for
                            excruciating lower back pain
                            radiating to his right leg,
                            accompanied by numbness in
                            the forefoot.
                        </p>
                    </div>
                    <div>
                        <p>Following two weeks of dedicated physiotherapy sessions at PSRD’s
                            physiotherapy department, Usman experienced a significant
                            improvement. He now walks with ease, the radiating pain has subsided,
                            and his activities of daily living are gradually returning to normal.</p>
                    </div>
                </div>
            </section>

        </>
    )
}
export default PhysiotherapyCentre