
import "./pr_department.css"
import outPtient from "../../images/departments/pr circle img.png"
import skewBg from "../../images/message-01.png"

import Img1 from "../../images/departments/pr Department.png"
import Img2 from "../../images/departments/pr department 2.png"
import Img3 from "../../images/departments/pr department 3.png"
import Img4 from "../../images/departments/pr department 4.jpeg"


import { useEffect } from "react"


const PR_Department = () => {

    useEffect(() => {
        const department = document.getElementById("department");
        department.classList.add("active-page");
        document.title="Advocacy and Public Relations Department"
        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);
    return (
        <>
            <section className="skew-style">
                <h2 className="mobile-heading">ADVOCACY AND PUBLIC<br /> RELATIONS <span>Department</span>
                <br/><strong>Achievements: July 2022 - June 2023</strong>
                </h2>
                <img src={skewBg} alt="" className="skew-img" />
                <div className="maxwidth flex column">
                    <div className="P-M-first-child flex heading-skew outPatient-child-1">
                        <div>
                            <h2 className="orthaticesHeading">ADVOCACY AND PUBLIC<br /> RELATIONS <span>Department</span>
                            <br/><strong>Achievements: July 2022 - June 2023</strong>
                            </h2>
                            <p style={{ padding: "0", marginTop: "4vmax" }}>
                                The Advocacy, Fundraising & Public Relations
                                Department at PSRD diligently organized a series
                                of activities and events aimed at advertising, advancing,
                                and creating awareness about the services offered for the
                                betterment of the differently-abled community. Strong
                                emphasis was placed on fundraising initiatives and event
                                organization to champion the rights of individuals with disabilities.
                            </p>
                        </div>
                        <div>
                            <img src={outPtient} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="pr_department">
                <div className="maxwidth" >
                    <div className="flex">
                        <p>The Zakat campaign for Ramadan was
                            launched with printed pamphlets,
                            circulars, and extensive promotion
                            on print, electronic, and social
                            media platforms such as Facebook,
                            Instagram, and the website. SMS
                            marketing, streamers, rickshaw
                            flexes, leaflets, and banners were
                            strategically utilized for the marketing
                            of zakat collection.
                        </p>
                        <div>
                            <img src={Img1} alt="" />
                        </div>
                    </div>
                    <div>
                        <p>The visit of the First Lady, Mrs. Samina Alvi in August was organized by the PR Department,
                            ensuring a warm welcome and successful arrangements for the event. The coverage of her visit
                            spanned electronic, print, and social media platforms.
                        </p>
                        <div>
                            <img src={Img2} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="pr_department-2">
                <div className="maxwidth" >
                    <p>
                        PR efforts extended to coordinating with news channels for spreading awareness about
                        the organization and publicizing information regarding ongoing and scheduled activities.
                        Participation in internship fairs across educational campuses and building connections with
                        corporate entities further advanced the organization’s agenda and highlighted the excellent
                        work at PSRD for empowering the differently abled.<br /><br />
                        The PR Department also orchestrated competitions in collaboration with different
                        organizations to secure media coverage. A total of 2,532 visitors from different schools,
                        colleges, and medical institutions were given informative tours of PSRD and briefed on
                        its services. Additionally, the department coordinated with 228 students from different
                        institutes for their internships at PSRD.
                    </p>
                    <div className="flex">
                        <div>
                            <img src={Img3} alt="" />
                        </div>
                        <div>
                            <img src={Img4} alt="" />
                        </div>

                    </div>
                    <p>
                        The PR and Advocacy department has played a pivotal role in referring 167 students and cases to
                        various organizations for employment, training and other assistance. In addition, assistance was
                        provided to 228 students in obtaining their ID Cards, Khidmat Cards, and Disability Certificates.

                    </p>
                </div>

            </section>
        </>
    )
}
export default PR_Department