
import { useEffect } from "react"
import Img1 from "../../images/checked.png"
import "./EmailVerification.css"
import { useSelector } from "react-redux"

import { useNavigate } from "react-router-dom"



const EmailVerification = () => {

    const navigate = useNavigate()

    const { email } = useSelector((state) => state.ForgotPassword)
    const { user } = useSelector((state) => state.user)

    useEffect(() => {
        const footer = document.querySelector(".totalFooter")
        footer.style.display = "none"
        document.title="Email Verification"
        return () => {
            footer.style.display = "block"
        }
    }, [])
    useEffect(() => {
        if (!email) {
            navigate("/")
        }
        if (user) {
            navigate("/")
        }
    }, [email, user])
    return (
        <>
            <section className="Emailverification flex absolute">
                <div className="flex column">
                    <img src={Img1} alt="" />
                    <p>A verification Message has been <br />send to <span>{email}</span> successfully</p>
                    <a href="https://www.gmail.com" target={"_blank"} rel="noreferrer" className="button2">Open Gmail</a>
                </div>
            </section>
        </>
    )
}
export default EmailVerification