


import { useEffect } from "react"


import Logo from "../../images/psrd.png"
import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import { RegisterUser } from "../../../actions/userAction"


import Alert from "../../alert/Alert"



const Login = () => {
    const dispatch = useDispatch()
    const Navigate = useNavigate()

    const { loading, success, error } = useSelector((state) => state.user)
    const [isName, setisName] = useState(true)
    const [isEmail, setisEmail] = useState(true)
    const [isPassword, setisPassword] = useState(true)

    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)


    const submitForm = (e) => {
        e.preventDefault()

        if (!name) {
            setisName(false)
        } else {
            setisName(true)
        }



        if (!email) {
            setisEmail(false)
        }
        else {
            setisEmail(true)
        }



        if (!password) {
            setisPassword(false)
        } else {
            setisPassword(true)
        }


        const user = {
            name: name,
            email: email,
            password: password,
        }



        if (isName && isEmail && isPassword) {
            dispatch(RegisterUser(user))
        } else {
            return
        }

    }



    useEffect(() => {
        const footer = document.querySelector(".totalFooter")
        footer.style.display = "none"
        document.title="Register -- PSRD"

        return () => {
            footer.style.display = "block"
        }
    }, [])

    useEffect(() => {
        if (success) {
            Navigate("/")
        }
    }, [success])
    return (
        <>
            <section className="login absolute">
                {
                    error ? <Alert type={false} time={4} message={error} ></Alert> : ""
                }
                <div>
                    <div className="login-left">
                        <div>
                            <img src={Logo} alt="" />
                        </div>
                        <h3 style={{ color: "white" }}>WELCOME</h3>
                        <p>Join Hands with PSRD!<br />
                            Your contribution as donations, zakat or sadqat can help us shape future of Differetly Abled children</p>
                    </div>
                    <div className="loginContent signupContent">
                        <h2>Register your account</h2>
                        <p>Enter your email & password to continue</p>
                        <form onSubmit={submitForm}>
                            <div>
                                <div><i className="fa-solid fa-user"></i></div>
                                <input
                                    type="text" placeholder="Full Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <p style={{ display: `${isName ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Please Enter your Name</p>
                            </div>
                            <div>
                                <div><i className="fa-solid fa-envelope"></i></div>
                                <input
                                    type="text" placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <p style={{ display: `${isEmail ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Please Enter your Email</p>
                            </div>
                            <div>
                                <div><i className="fa-solid fa-lock"></i></div>
                                <input type="password" placeholder="Passsword"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <p style={{ display: `${isPassword ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Please Enter Password</p>
                            </div>
                            <div>
                            </div>
                            <div>
                                <button type="submit" className="button2" style={{ background: "var(--purple)" }} disabled={loading ? true : false}>{loading ? "loading..." : "register"}</button>
                            </div>
                            <div className="SwitchToAccount">
                                <p>Already Have an account? <Link to={"/Admin-panel/login-psrd"} style={{ color: "var(--purple)", borderBottom: "0.1vmax solid var(--purple)" }}>Login</Link> </p>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login