
import { createStore, combineReducers, applyMiddleware, compose } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { RegisterUserReducer, forgotPasswordReducer } from "./reducers/userReducer";
import { CreateFormReducer, GetAllFormReducer, updateFormReducer, DeleteFormReducer, getFormReducer } from "./reducers/FormReducer";
import { DonateCreateFormReducer, DonateDeleteFormReducer, DonateGetAllFormReducer, DonategetFormReducer, DonateupdateFormReducer } from "./reducers/DonateFormReducer";
import { CreateNewsletterReducer } from "./reducers/newsletterreducers.js"

const reducers = combineReducers({
    user: RegisterUserReducer,
    ForgotPassword: forgotPasswordReducer,
    Form: CreateFormReducer,
    allFormData: GetAllFormReducer,
    updateForm: updateFormReducer,
    DeleteForm: DeleteFormReducer,
    formDetails: getFormReducer,

    Donate: DonateCreateFormReducer,
    allDonations: DonateGetAllFormReducer,
    updateDonation: DonateupdateFormReducer,
    DeleteDonation: DonateDeleteFormReducer,
    DonationDetails: DonategetFormReducer,

    newsletter: CreateNewsletterReducer,
});


let initialState = {};

const middleware = applyMiddleware(thunk);

let enhancer = middleware;

if (process.env.NODE_ENV === 'development') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(middleware);
}

const store = createStore(
    reducers,
    initialState,
    enhancer
);

export default store;
