

import { useEffect } from "react";
import skewBg from "../../images/message-01.png"


import Director from "../../images/about/Director Photo.jpg"

const DirectorMessage = () => {
    useEffect(() => {
        const aboutButton = document.getElementById("about");
        aboutButton.classList.add("active-page");
        document.title = "Directorate";

        // Cleanup function
        return () => {
            aboutButton.classList.remove("active-page");
        };
    }, []);
    return (
        <>
            <section className="skew-style">
                <h2 className="mobile-heading">Directorate</h2>
                <img src={skewBg} alt="" className="skew-img" />
                <div className="maxwidth flex column">
                    <div className="P-M-first-child flex heading-skew">
                        <div>
                            <h2>Directorate</h2>
                            <h3>
                                Usman Ahmad Malik <br />
                                <span>Director <span>PSRD</span></span>
                            </h3>
                        </div>
                        <div>
                            <img src={Director} alt="" />
                        </div>
                    </div>
                    <p>
                        As the executive lead, the Director’s main role is to implement
                        <br />the strategy
                        of the organization under the direction and decisions of the governing body
                        in coordination with heads of departments.<br />
                        <br />
                        The Director maintains correspondence with Government agencies and
                        International Organizations and keep liaison with the print and electronic
                        media. The directorate office provides administrative support to all the
                        departments of PSRD.<br />
                        <br />
                        This year, the Directorate has also been responsible for the maintenance of
                        buildings, transport, and other assets of PSRD.<br />
                        <br />
                        In addition, all the personnel management is handled by this office. There is
                        a total of 278 employees in PSRD.
                        <br />
                        <br />

                    </p>
                </div>
            </section>
        </>
    )
}
export default DirectorMessage