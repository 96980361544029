

import {
    CREATE_DONATE_REQUEST,
    CREATE_DONATE_SUCCESS,
    CREATE_DONATE_FAIL,

    GET_ALL_DONATE_REQUEST,
    GET_ALL_DONATE_SUCCESS,
    GET_ALL_DONATE_FAIL,

    DELETE_DONATE_REQUEST,
    DELETE_DONATE_SUCCESS,
    DELETE_DONATE_FAIL,

    UPDATE_DONATE_REQUEST,
    UPDATE_DONATE_SUCCESS,
    UPDATE_DONATE_FAIL,

    GET_DONATE_DETAILS_REQUEST,
    GET_DONATE_DETAILS_SUCCESS,
    GET_DONATE_DETAILS_FAIL,

    CLEAR_ERRORS,
}  from "../constants/DonateFormConstants"


export const DonateCreateFormReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_DONATE_REQUEST:
            return {
                loading: true,
            }
        case CREATE_DONATE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                donateData: action.payload.donateData,
            }
        case CREATE_DONATE_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const DonateGetAllFormReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ALL_DONATE_REQUEST:
            return {
                loading: true,
            }
        case GET_ALL_DONATE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                donateData: action.payload.donateData,
                totalDonations: action.payload.totalDonation,
            }
        case GET_ALL_DONATE_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const DonateDeleteFormReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_DONATE_REQUEST:
            return {
                loading: true,
            }
        case DELETE_DONATE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
            }
        case DELETE_DONATE_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const DonateupdateFormReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_DONATE_REQUEST:
            return {
                loading: true,
            }
        case UPDATE_DONATE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                donateData: action.payload.donateData,
            }
        case UPDATE_DONATE_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const DonategetFormReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_DONATE_DETAILS_REQUEST:
            return {
                loading: true,
            }
        case GET_DONATE_DETAILS_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                donateData: action.payload.donateData
            }
        case GET_DONATE_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}