import { Link } from "react-router-dom/dist"
import Logo from "../images/psrd.png"
import "./header.css"
import { useNavigate } from "react-router-dom"
import { useState } from "react"



import HeaderImg1 from "../images/header/home.png"
import HeaderImg2 from "../images/header/team.png"
import HeaderImg3 from "../images/header/annual-calendar.png"
import HeaderImg4 from "../images/header/structure.png"
import HeaderImg5 from "../images/header/kindness.png"
import HeaderImg6 from "../images/header/gallery.png"
import HeaderImg7 from "../images/header/phone-call.png"
import HeaderImg8 from "../images/header/newsletter.png"



const Header = () => {


    const navigate = useNavigate()
    const [issubPage, setIssubPage] = useState(false)
    const [active, setActive] = useState(false)
    const [showHeader, setShowHeader] = useState(false)



    const DonateNow = () => {
        navigate("/donate-now")
    }

    const job = () => {
        navigate("/job-application")
    }
    const setSubPage = (value) => {
        setIssubPage(true)
        setActive(value)
    }


    return (
        <>
            <header className="header">
                <div className="header-social-media flex ">
                    <div className="flex">
                        <h5><a href="https://maps.google.com/maps?ll=31.539464,74.32016&z=13&t=m&hl=en&gl=US&mapclient=embed&cid=552813199286808749" target="_blank" rel="noreferrer" ><i className="fa-solid fa-location"></i>
                            111 - FEROZEPUR ROAD, LAHORE-54600 PAKISTAN</a></h5>
                        <h5><a href="mailto:info@psrd.org.pk"><i className="fa-regular fa-envelope"></i>
                            info@psrd.org.pk</a></h5>
                        <h5><a href="tel:042 37427130"><i className="fa-solid fa-phone"></i>042 37427130</a></h5>
                        <h5><a href="https://wa.me/+923065555201" target="_blank" rel="noreferrer" ><i className="fa-brands fa-whatsapp"></i>+92-306-5555201</a></h5>
                    </div>
                    <div className="flex">
                        <h5><a href="https://www.facebook.com/profile.php?id=100085683547755" target={"_blank"} rel="noreferrer" ><i className="fa-brands fa-facebook-f"></i></a></h5>
                        <h5><a href="https://youtube.com/channel/UCWOanPUFY07jLLtWO7-C82w" target={"_blank"} rel="noreferrer" ><i className="fa-brands fa-youtube"></i></a></h5>
                        <h5><a href="https://instagram.com/psrd.org.pk?igshid=YmMyMTA2M2Y=" target={"_blank"} rel="noreferrer" ><i className="fa-brands fa-instagram"></i></a></h5>
                        <h5><a href="https://www.tiktok.com/@psrd.org.pk?_t=8kRZlT946WV&_r=1" target={"_blank"} rel="noreferrer" ><i className="fa-brands fa-tiktok"></i></a></h5>
                        <h5><a href="https://www.linkedin.com/company/psrd-official/" target={"_blank"} rel="noreferrer" ><i className="fa-brands fa-linkedin-in"></i></a></h5>
                    </div>
                </div>
                <div className="megaMenu flex">
                    <div className="logo flex">
                        <a href="/"><img src={Logo} alt="" /></a>
                    </div>
                    <nav className="navbar">
                        <ul>
                            <li>
                                <Link to={"/"} id="home">Home</Link>
                            </li>
                            <li>
                                <Link to={"#about"} id="about">About Us</Link>
                                <ul>
                                    <li><Link to={"/history"}>History</Link></li>
                                    <li><Link to={"/president/message"}>President's message</Link></li>
                                    <li><Link to={"/board/of/governors"}>Board of governors</Link></li>
                                    <li><Link to={"/director/message"}>Directorate</Link></li>
                                </ul>

                            </li>
                            <li>
                                <Link to={"#Departments"} id="department">Departments</Link>
                                <ul>
                                    <li><Link to={"/our-patient-department-psrd"}>Out Patient Department</Link></li>
                                    <li><Link to={"/psrd-hospital"}>PSRD hospital</Link></li>
                                    <li><Link to={"/physiotherapy-centre"}>Physiotherapy Centre</Link></li>
                                    <li><Link to={"/orthotic-and-prosthetic-centre/"}>Orthotics & Prosthetics Centre</Link></li>
                                    <li><Link to={"/occupational-therapy-and-speech-therapy/"}>Occupational Therapy & Speech Therapy</Link></li>
                                    <li><Link to={"/community-based-rehabilitation-outreach-services/"}>community based Rehabilitation outreach services</Link></li>
                                    <li><Link to={"/vocational-rehabilitation-centre/"}>Vocational Rehabilitation Centre</Link></li>
                                    <li><Link to={"/skill-development-centre/"}>Skill Development Centre</Link></li>
                                    <li><Link to={"/psrd-high-school"}>PSRD High school</Link></li>
                                    <li><Link to={"/psrd-college-of-rehabilitation-sciences/"}>PSRD College Of Rehabilitation Sciences</Link></li>
                                    <li><Link to={"/advocacy-and-public-relations-unit/"}>Advocacy and Public Relations Unit</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to={"#annualReport"} id="annual_report">annual report</Link>
                                <ul>
                                    <li><Link to={"/annual-report-2021-2022/"}>Annual Report 2021-2022</Link></li>
                                    <li><Link to={"/annual-report-2022-2023/"}>Annual Report 2022-2023</Link></li>
                                    <li><Link to={"/financial-summary-2020-2023/"}>Financial Summary 2022-2023</Link></li>
                                </ul>

                            </li>
                            <li>
                                <Link to={"#newsletter"} id="newsletter">NEWSLETTER</Link>
                                <ul>
                                    <li><Link to={"/newsletter-Jan-to-mar-2024/"}>PSRD NEWS Jan-Mar, 2024</Link></li>
                                    <li><Link to={"/newsletter-Oct-to-Dec-2023/"}>PSRD NEWS Oct-Dec, 2023</Link></li>
                                    <li><Link to={"/newsletter-july-to-sept-2023/"}>PSRD NEWS July-Sept, 2023</Link></li>

                                    <li><Link to={"/newsletter-Jan-to-June-2023/"}>PSRD NEWS Jan-June, 2023</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to={"#donate"} id="donate">Donate</Link>
                                <ul>
                                    <li><Link to={"/donate-now"}>Donate Now</Link></li>
                                    <li><Link to={"/donation-category"}>Donation Category</Link></li>
                                    <li><Link to={"/donors-list/"}>Donors List</Link></li>
                                </ul>
                            </li>

                            <li>
                                <Link to={"/gallery"} id="gallery">gallery</Link>
                            </li>

                            <li>
                                <Link to={"/contact-us"} id="contact">contact us</Link>
                            </li>
                        </ul>
                    </nav>

                    <nav className="header-btn">
                        <div className="flex">
                            <button className="button2 button3" style={{ overflow: "hidden" }} onClick={() => DonateNow()}>Donate Now</button>
                        </div>
                    </nav>
                </div>
            </header>
            <header className="header2">
                <div className="header-tab-1 flex ">
                    <Link to={"/donate-now"} className="button2 button3">
                        Donate Now
                    </Link>
                </div>
                <div className="megaMenu-2 flex">
                    <div className="logo-2 flex">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="humberg">
                        <button className="flex column" onClick={() => setShowHeader(true)}><i></i><i></i><i></i></button>
                    </div>
                    <nav className="navbar-2" style={{ transform: `${showHeader ? "translateX(0%)" : "translateX(-100%)"}` }}>
                        <button className="cancelbutton" onClick={() => setShowHeader(false)}><i className="fa-solid fa-xmark"></i></button>
                        <div className="flex column">
                            <div className="tab-header-logo">
                                <img src={Logo} alt="" />
                            </div>
                            <ul>
                                <li>
                                    <img src={HeaderImg1} alt="" />  <Link to={"/"} onClick={() => setShowHeader(false)} id="home">Home</Link>
                                </li>
                                <li>
                                    <img src={HeaderImg2} alt="" />  <Link to={"#about"} id="about" onClick={() => setSubPage("about")}>About Us</Link>
                                </li>
                                <li>
                                    <img src={HeaderImg4} alt="" /> <Link to={"#Departments"} id="department" onClick={() => setSubPage("departments")}>Departments</Link>
                                </li>
                                <li>
                                    <img src={HeaderImg3} alt="" /> <Link to={"#annualReport"} id="annual_report" onClick={() => setSubPage("reports")}>annual report</Link>
                                </li>
                                <li>
                                    <img src={HeaderImg8} alt="" />  <Link to={"#newsletter"} id="newsletter" onClick={() => setSubPage("newsletter")}>NEWSLETTER</Link>
                                </li>

                                <li>
                                    <img src={HeaderImg5} alt="" /> <Link to={"#donate"} id="donate" onClick={() => setSubPage("donate")}>Donate</Link>

                                </li>
                                <li>
                                    <img src={HeaderImg6} alt="" />  <Link to={"/gallery"} onClick={() => setShowHeader(false)} id="gallery">gallery</Link>
                                </li>

                                <li>
                                    <img src={HeaderImg7} alt="" /> <Link to={"/contact-us"} id="contact" onClick={() => setShowHeader(false)}>contact us</Link>
                                </li>
                            </ul>
                            <div className="flex tab-header-social-media">
                                <a
                                    href="https://www.facebook.com/profile.php?id=100085683547755" target={"_blank"}
                                    rel="noreferrer">
                                    <i className="fa-brands fa-facebook-f"></i>
                                </a>
                                <a
                                    href="https://youtube.com/channel/UCWOanPUFY07jLLtWO7-C82w"
                                    target={"_blank"}
                                    rel="noreferrer">
                                    <i className="fa-brands fa-youtube"></i>
                                </a>
                                <a
                                    href="https://instagram.com/psrd.org.pk?igshid=YmMyMTA2M2Y="
                                    target={"_blank"}
                                    rel="noreferrer">
                                    <i className="fa-brands fa-instagram"></i>
                                </a>
                                <a
                                    href="https://www.tiktok.com/@psrd.org.pk?_t=8kRZlT946WV&_r=1"
                                    target={"_blank"}
                                    rel="noreferrer">
                                    <i className="fa-brands fa-tiktok"></i>
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/psrd-official/"
                                    target={"_blank"}
                                    rel="noreferrer">
                                    <i className="fa-brands fa-linkedin-in"></i>
                                </a>
                            </div>
                            <div className="subpages" style={{ left: `${issubPage ? "0%" : "100%"}` }}>
                                <button onClick={() => setIssubPage(false)}><i className="fa-solid fa-chevron-left"></i> back</button>
                                <ul style={{ display: `${active === "about" ? "block" : "none"}` }} >
                                    <li><Link to={"/history"} onClick={() => setShowHeader(false)}>History</Link></li>
                                    <li><Link to={"/president/message"} onClick={() => setShowHeader(false)}>President's message</Link></li>
                                    <li><Link to={"/board/of/governors"} onClick={() => setShowHeader(false)}>Board of governors</Link></li>
                                    <li><Link to={"/director/message"} onClick={() => setShowHeader(false)}>Directorate</Link></li>
                                </ul>
                                <ul style={{ display: `${active === "reports" ? "block" : "none"}` }}>
                                    <li><Link to={"/annual-report-2021-2022/"} onClick={() => setShowHeader(false)}>Annual Report 2021-2022</Link></li>
                                    <li><Link to={"/annual-report-2022-2023/"} onClick={() => setShowHeader(false)}>Annual Report 2022-2023</Link></li>
                                    <li><Link to={"/financial-summary-2020-2023/"} onClick={() => setShowHeader(false)}> Financial Summary 2022-2023</Link></li>
                                </ul>
                                <ul style={{ display: `${active === "departments" ? "block" : "none"}` }}>
                                    <li><Link to={"/our-patient-department-psrd"} onClick={() => setShowHeader(false)}>Out Patient Department PSRD</Link></li>
                                    <li><Link to={"/psrd-hospital"} onClick={() => setShowHeader(false)}>PSRD hospital</Link></li>
                                    <li><Link to={"/physiotherapy-centre"} onClick={() => setShowHeader(false)}>Physiotherapy Centre</Link></li>
                                    <li><Link to={"/orthotic-and-prosthetic-centre/"} onClick={() => setShowHeader(false)}>Orthotics & Prosthetics Centre</Link></li>
                                    <li><Link to={"/occupational-therapy-and-speech-therapy/"} onClick={() => setShowHeader(false)}>Occupational Therapy & Speech Therapy</Link></li>
                                    <li><Link to={"community-based-rehabilitation-outreach-services/"} onClick={() => setShowHeader(false)}>community based Rehabilitation outreach services</Link></li>
                                    <li><Link to={"/vocational-rehabilitation-centre/"} onClick={() => setShowHeader(false)}>Vocational Rehabilitation Centre</Link></li>
                                    <li><Link to={"/skill-development-centre/"} onClick={() => setShowHeader(false)}>Skill Development Centre</Link></li>
                                    <li><Link to={"/psrd-high-school"} onClick={() => setShowHeader(false)}>PSRD High school</Link></li>
                                    <li><Link to={"/psrd-college-of-rehabilitation-sciences/"} onClick={() => setShowHeader(false)}>PSRD College Of Rehabilitation Sciences</Link></li>
                                    <li><Link to={"/advocacy-and-public-relations-unit/"} onClick={() => setShowHeader(false)}>Advocacy and Public Relations Unit</Link></li>
                                </ul>
                                <ul style={{ display: `${active === "donate" ? "block" : "none"}` }}>
                                    <li><Link to={"/donate-now"} onClick={() => setShowHeader(false)}>Donate Now</Link></li>
                                    <li><Link to={"/donation-category"} onClick={() => setShowHeader(false)}>Donation Category</Link></li>
                                    <li><Link to={"/donors-list/"} onClick={() => setShowHeader(false)}>Donors List</Link></li>
                                </ul>
                                <ul style={{ display: `${active === "newsletter" ? "block" : "none"}` }}>
                                    <li><Link to={"/newsletter-Jan-to-mar-2024/"} onClick={() => setShowHeader(false)}>PSRD NEWS Jan-Mar, 2024</Link></li>
                                    <li><Link to={"/newsletter-Oct-to-Dec-2023/"} onClick={() => setShowHeader(false)}>PSRD NEWS Oct-Dec, 2023</Link></li>
                                    <li><Link to={"/newsletter-july-to-sept-2023/"} onClick={() => setShowHeader(false)}>PSRD NEWS July-Sept, 2023</Link></li>

                                    <li><Link to={"/newsletter-Oct-to-Dec-2023/"} onClick={() => setShowHeader(false)}>PSRD NEWS Oct-Dec, 2023</Link></li>
                                </ul>
                            </div>
                        </div>
                    </nav>

                </div>
            </header>
        </>
    )
}
export default Header