


export const CREATE_DONATE_REQUEST = "CREATE_DONATE_REQUEST"
export const CREATE_DONATE_SUCCESS = "CREATE_DONATE_SUCCESS"
export const CREATE_DONATE_FAIL = "CREATE_DONATE_FAIL"

export const GET_ALL_DONATE_REQUEST = "GET_ALL_DONATE_REQUEST"
export const GET_ALL_DONATE_SUCCESS = "GET_ALL_DONATE_SUCCESS"
export const GET_ALL_DONATE_FAIL = "GET_ALL_DONATE_FAIL"

export const DELETE_DONATE_REQUEST = "DELETE_DONATE_REQUEST"
export const DELETE_DONATE_SUCCESS = "DELETE_DONATE_SUCCESS"
export const DELETE_DONATE_FAIL = "DELETE_DONATE_FAIL"

export const UPDATE_DONATE_REQUEST = "UPDATE_DONATE_REQUEST"
export const UPDATE_DONATE_SUCCESS = "UPDATE_DONATE_SUCCESS"
export const UPDATE_DONATE_FAIL = "UPDATE_DONATE_FAIL"

export const GET_DONATE_DETAILS_REQUEST = "GET_DONATE_DETAILS_REQUEST"
export const GET_DONATE_DETAILS_SUCCESS = "GET_DONATE_DETAILS_SUCCESS"
export const GET_DONATE_DETAILS_FAIL = "GET_DONATE_DETAILS_FAIL"


export const CLEAR_ERRORS = "CLEAR_ERRORS"