
import "./outReachServices.css"
import skewBg from "../../images/message-01.png"
import CommunityBased from "../../images/departments/psrd cbr-01.PNG"
import BeforeSurgery from "../../images/departments/before surgeries child.jpg.jpg"
import afterSurgery from "../../images/departments/after surgeries child.jpg"
import Kamahan_Lahore from "../../images/departments/Kamahan Lahore.jpg"


import { useEffect } from "react"



const OutReach = () => {




    useEffect(() => {
        const department = document.getElementById("department");
        department.classList.add("active-page");
        document.title="Community Based Rehabilitation Outreach Services"
        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);
    return (
        <>
            <section className="CommunityBased skew-style">
                <h2 className="mobile-heading orthaticesHeading">Community Based Rehabilitation<br />
                    <span>OUTREACH SERVICES</span> <br/>
                    <strong>Achievements: July 2022 - June 2023</strong></h2>
                <img src={skewBg} alt="" className="skew-img" />
                <div className="maxwidth flex column">
                    <div className="flex heading-skew">
                        <div>
                            <h2 className="orthaticesHeading">Community Based Rehabilitation<br />
                                <span> OUTREACH SERVICES</span>
                                <br/><strong>Achievements: July 2022 - June 2023</strong>
                                </h2>
                            <h3 style={{ paddingTop: "6vmax" }}>CBR VISION</h3> 
                            <p style={{ margin: "1.6vmax 0 0 3.4vmax" }}>
                                Our vision is to forge a nurturing environment for
                                marginalized<br /> individuals, especially persons with
                                disabilities.
                            </p>
                        </div>
                        <div>
                            <img src={CommunityBased} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="CommunityBased-2">
                <div className="maxwidth flex column" style={{ alignItems: "flex-start" }}>
                    <h3 style={{ paddingTop: "0", marginBottom: "-2vmax" }}>CBR OBJECTIVES</h3>
                    <div className="communityBasedPoints">
                        <ul>
                            <li>
                                <p>The objective of women’s empowerment is to raise awareness among rural women
                                    and provide them with comprehensive education, enabling them to play a positive
                                    role in society</p>
                            </li>
                            <li>
                                <p>To ensure that they are National ID Cards holders and have equal rights in society</p>
                            </li>
                            <li>
                                <p>To raise their living standards by surveying their homes, children and lifestyle</p>
                            </li>
                            <li>
                                <p>To educate rural women on hygiene</p>
                            </li>
                            <li>
                                <p>To teach them skills, so that they are financially independent</p>
                            </li>
                            <li>
                                <p>To give small loans to differently abled and needy women</p>
                            </li>
                            <li>
                                <p>To provide the sick with medicines and treatment, free of cost</p>
                            </li>
                            <li>
                                <p>To provide adult education</p>
                            </li>
                        </ul>
                    </div>

                </div>
            </section>
            <section className="CommunityBased-3">
                <div className="maxwidth flex column" style={{ alignItems: "flex-start", gap: "0.7vmax" }}>
                    <h3>DOOR TO DOOR SURVEY:</h3>
                    <p style={{ marginLeft: "3.4vmax" }}>The CBR staff has been surveying different villages since 1993.</p>
                    <div className="Medical-treatment">
                        <h3 style={{ color: "var(--purple)", textAlign: "center", width: "100%" }}>Medical treatment administered in Kamahan, Lahore</h3>
                        <div className="table-2 flex">
                            <h5>No. of patients examined by lady doctor</h5>
                            <p>2,480</p>
                        </div>
                        <div className="table-2 flex">
                            <h5>No. of physically disabled women</h5>
                            <p>593</p>
                        </div>
                        <div className="table-2 flex">
                            <h5>No. of physically disabled children</h5>
                            <p>27</p>
                        </div>
                        <div className="table-2 flex">
                            <h5>No. of patients treated with physiotherapy</h5>
                            <p>511</p>
                        </div>
                        <div className="table-2 flex">
                            <h5>No. of visits</h5>
                            <p>72</p>
                        </div>
                    </div>

                </div>
            </section>
            <section className="communityBased-4">
                <div className="maxwidth flex">
                    <div>
                        <h3>CBR Activities </h3>
                        <h5 >New village “Kamahan Lahore”</h5>
                        <p >The village exhibited poor hygiene
                            conditions with open, foul-smelling drains
                            and streets. Our dedicated efforts focused
                            on clearing garbage heaps, cleaning, and
                            covering the drains and streets to improve
                            the overall sanitation.</p>
                    </div>
                    <div>
                        <img src={Kamahan_Lahore} alt="" />
                    </div>
                </div>
            </section>
            <section className="communityBased-5">
                <div className="maxwidth">
                    <h3>Success Story:</h3>
                    <p>
                        During the survey in Kamahan village, the CBR team encountered Abdullah, an 8-yearold child with cerebral palsy who walked on his toes. Following an examination, he
                        was referred to PSRD for further evaluation where he was recommended a series of 8
                        surgeries.<br /><br />
                        Over the course of five months, with persistent efforts, all 8 surgeries were successfully
                        completed under the health card scheme. CBR, with the grace of Allah, managed to
                        arrange approximately Rs. 8000 per week to cover the associated medicine expenses.
                        <br /><br />
                        He was advised a post-surgical regimen including the use of special shoes, regular
                        physiotherapy, and medication till the age of 18. The CBR team facilitated the creation of
                        these special shoes through the Orthotics and Prosthetics Centre at PSRD.
                        <br /><br />
                    </p>
                    <div className="success-story-communityBased flex">
                        <div>
                            <div>
                                <img src={BeforeSurgery} alt="" />
                            </div>
                        </div>
                        <div>
                            <div>
                                <img src={afterSurgery} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default OutReach