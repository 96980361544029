
import "./SkillCentre.css"
import skewBg from "../../images/message-01.png"
import SkillCentreImg from "../../images/departments/skill center-01.png"
import SkillCentre1 from "../../images/gallery/skill center 1.JPG"
import SkillCentre2 from "../../images/gallery/skill center 2.JPG"
import SkillCentre3 from "../../images/gallery/skill center 3.jpg"
import SkillCentre4 from "../../images/gallery/skill center 4.JPG"


import { useEffect } from "react"



const SkillCentre = () => {
    useEffect(() => {
        const department = document.getElementById("department");
        department.classList.add("active-page");
        document.title="Skill Development Centre"
        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);

    return (
        <>
            <section className="skillCentre skew-style">
                <h2 className="mobile-heading">SKILL DEVELOPMENT
                    <span> Centre</span>
                    <br/><strong>Achievements: July 2022 - June 2023</strong>
                    </h2>
                <img src={skewBg} alt="" className="skew-img" />
                <div className="maxwidth flex column">
                    <div className="flex heading-skew">
                        <div>
                            <h2>SKILL DEVELOPMENT
                                <span> centre</span>
                                <br/><strong>Achievements: July 2022 - June 2023</strong>
                                </h2>
                            <p>
                                The Skill Development Department at PSRD
                                achieved remarkable success, distributing over
                                Rs. 5.7 million in wages despite the challenges
                                posed by a struggling economy. Our arts and
                                crafts segment offered a diverse range of
                                products, including delicately handcrafted painted
                                envelopes, elegant tissue boxes for daily use, and
                                a variety of well-decorated gift bags.</p>
                        </div>
                        <div>
                            <img src={SkillCentreImg} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="skillCentre-2">
                <div className="maxwidth flex">
                    <div>
                        <img src={SkillCentre1} alt="" />
                    </div>
                    <div>
                        <p>
                            In the Vocational Department, we
                            produced practical items such as
                            everyday use dust bags, stylish
                            pouches, beautiful handbags, travel
                            bags and bid pouches.
                            Notably, we proudly collaborated with
                            renowned brands like Ehsan Chappal
                            Store (ECS), Haji Karim Buksh (HKB),
                            Hilal Silk, Molty Foam, and Bulleh
                            Shah Packages. With their support, we
                            have not only sustained and grown but
                            also created meaningful employment
                            opportunities for differently-abled
                            individuals.
                        </p>
                    </div>
                </div>
            </section>
            <section className="skillCentre-3">
                <div className="maxwidth flex">
                    <div>
                        <img src={SkillCentre2} alt="" />
                    </div>
                    <div>
                        <img src={SkillCentre3} alt="" />
                    </div>
                </div>
            </section>
            <section className="skillCentre-4">
                <div className="maxwidth flex">
                    <div className="flex column" >
                        <h3>
                            Computer Training Centre
                        </h3>
                        <p>
                            The section initially had only one computer, but thanks to generous donors, it
                            is now furnished with twenty-two computers. Internet facilities have also been
                            extended to benefit our students.
                        </p>
                        <h4>
                            We offer the following Computer<br />
                            Diploma Courses:
                        </h4>
                        <ul>
                            <li>
                                <p>
                                    Diploma In Computerized Embroidery
                                    Designing
                                </p>
                            </li>
                            <li>
                                <p>
                                    Diploma In Web Page Designing
                                </p>
                            </li>
                            <li>
                                <p>
                                    Diploma In Office Automation
                                </p>
                            </li>
                        </ul>
                        <div>
                            <img src={SkillCentre4} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="skillCentre-5">
                <div className="maxwidth">
                    <h5>
                        Total students who completed the<br />
                        Diploma and Certification<span></span>
                        96
                    </h5>
                    <div className="skillCentreTable">
                        <div className="flex">
                            <h5>STUDENTS</h5>
                            <h5>INCOME</h5>
                        </div>
                        <div className="flex">
                            <div>
                                <h5>DISABLED</h5>
                                <h5>ABLE BODIED</h5>
                            </div>
                            <p>
                                Rs. 710,100
                            </p>
                        </div>
                        <div className="flex">
                            <h5>CONCESSIONS</h5>
                            <h5>Rs. 154,300</h5>
                        </div>
                        <div className="skillabsolute">
                            <h5><span></span>Income from Tuition Fee for Current Year </h5>
                        </div>
                    </div>
                </div>
            </section>
            <section className="skillCentre-6">
                <div className="maxwidth">
                    <p>
                        Since the inception of the computer training program, we have trained 2,517 disabled/
                        able bodied students.
                    </p>
                    <div>
                        <h3>Job Opportunity</h3>
                        <p>Several of our students have secured employment in both the private and
                            government sectors, including positions in entities such as banks, the post office, AG
                            Office, PTCL, schools, etc. Additionally, a number of our students have found work
                            abroad.</p>
                    </div>
                    <div>
                        <h3>Future Plan</h3>
                        <p>In the future, we plan to initiate classes for audio/video editing using the latest software.</p>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SkillCentre