import "./History.css"
import Logo from "../../images/psrd.png"
import { useEffect } from "react";




const History = () => {
    useEffect(() => {
        const aboutButton = document.getElementById("about");
        aboutButton.classList.add("active-page");
        document.title = "History";
        // Cleanup function
        return () => {
            aboutButton.classList.remove("active-page");
        };
    }, []);
    return (
        <>
            <section className="history">
                <div className="maxwidth">
                    <div className="flex">
                        <p style={{ color: "white" }}><span>Pakistan Society for Rehabilitation of Differently Abled</span><br /> is a charitable organization run mainly on donations, zakat, and sadqaat. Its primary mission is to provide preventive, remedial, and rehabilitative treatment to differently abled and handicapped persons so that they are integrated into society in a productive and useful manner.</p>
                        <div className="flex">
                            <div>
                                <img src={Logo} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="historyHeading">
                <div className="maxwidth">
                    <div className="historyHeading-block">
                        <p>PSRD was set up as a physiotherapy centre by a small group of orthopedic doctors in 1957 in one room within the Mayo Hospital in Lahore, as they felt that physiotherapy was essential for the rehabilitation of patients after surgery.</p>
                    </div>
                    <p>
                        In 1964, the society was able to set up a physiotherapy department in its current premises on Ferozepur Road, Lahore. By 1969, a new building block was completed for the Prosthetics and Orthotics workshop. The block, now housing the physiotherapy department and the surgical unit, was completed in 1972. Among the patients coming to PSRD were a number of children, whose education was neglected because of the nature of their treatment. An education program was started in 1965, and the school was basically developed with financial assistance from foundations in Holland and England. The program proved very beneficial, and a new school block was constructed with the help of a government grant and public donations in 1980. With the increase in patients, a new building was required for the Out-Patient Department, with a seating capacity for over a hundred patients, an X-ray unit, a clinical laboratory, and examination rooms for the doctors, and social welfare officers. This was built with the help of the American Friends of PSRD and PSRD’s own resources in 2003.
                    </p>
                </div>
            </section>
        </>

    )
}
export default History