import "./contectUs.css"

import { useEffect, useState } from "react";
import { CreateForm } from "../../actions/FormAction"
import { useDispatch, useSelector } from "react-redux"
import Alert from "../alert/Alert"

const ContectUs = () => {

    const dispatch = useDispatch()


    const [isName, setIsName] = useState(true)
    const [isEmail, setisEmail] = useState(true)
    const [isSubject, setisSubject] = useState(true)
    const [isMessage, setIsmessage] = useState(true)

    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [subject, setSubject] = useState()
    const [message, setmessage] = useState()

    const { loading, success } = useSelector((state) => state.Form)

    const submitForm = (e) => {
        e.preventDefault()

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!name) {
            setIsName(false)
        } else {
            setIsName(true)
        }



        if (emailRegex.test(email)) {
            setisEmail(true)
        }
        else {
            setisEmail(false)
        }



        if (!subject) {
            setisSubject(false)
        } else {
            setisSubject(true)
        }


        if (!message) {
            setIsmessage(false)
        } else {
            setIsmessage(true)
        }

        const form = {
            name: name,
            email: email,
            subject: subject,
            message: message
        }



        if (isName && isEmail && isSubject && isMessage) {
            dispatch(CreateForm(form))
        } else {
            return
        }

    }




    useEffect(() => {
        const contact = document.getElementById("contact");
        contact.classList.add("active-page");
        document.title = "Contact Us"

        // Cleanup function
        return () => {
            contact.classList.remove("active-page");
        };
    }, []);
    useEffect(() => {
        if (success) {
            setName("");
            setEmail("");
            setSubject("");
            setmessage("");
        }

    }, [success]);
    return (
        <>
            <section className="contectUs">
                {
                    success ? <Alert type={true} time={4} message={"Thank you for Contacting us."} ></Alert> : ""
                }

                <div className="maxwidth flex column">
                    <h2>Contact US</h2>
                    <div className="social-contact flex">
                        <div className="flex column">
                            <i className="fa-solid fa-phone"></i>
                            <h3>Call</h3>
                            <a href="https://wa.me/+923065555201" target={"_blank"} rel="noreferrer"><p>+92-306-5555201</p></a>
                            <a href="tel:042 37427130"><p>042 37427130</p></a>
                        </div>
                        <div className="flex column">
                            <i className="fa-solid fa-envelope"></i>
                            <h3>Mail</h3>
                            <a href="mailto:info@psrd.org.pk"><p>info@psrd.org.pk</p></a>
                            <a href="mailto:psrd@brain.net.pk"><p>psrd@brain.net.pk</p></a>
                        </div>
                        <div className="flex column">
                            <i className="fa-solid fa-thumbs-up"></i>
                            <h3>Follow</h3>
                            <div className="flex">
                                <a target={"_blank"} rel="noreferrer" href="https://www.facebook.com/profile.php?id=100085683547755"><i className="fa-brands fa-facebook-f"></i></a>
                                <a target={"_blank"} rel="noreferrer" href="https://youtube.com/channel/UCWOanPUFY07jLLtWO7-C82w"><i className="fa-brands fa-youtube"></i></a>
                                <a target={"_blank"} rel="noreferrer" href="https://instagram.com/psrd.org.pk?igshid=YmMyMTA2M2Y="><i className="fa-brands fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className="contactusForm">
                <div className="maxwidth flex">
                    <form onSubmit={submitForm}>
                        <h3>Get in Touch</h3>
                        <div>
                            <p>
                                Your name <span>*</span>
                            </p>
                            <input type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <p style={{ display: `${isName ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Required</p>
                        </div>
                        <div>
                            <p>
                                Your Email <span>*</span>
                            </p>
                            <input type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <p style={{ display: `${isEmail ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Please Enter a valid Email</p>
                        </div>
                        <div>
                            <p>
                                Subject <span>*</span>
                            </p>
                            <input type="text"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                            <p style={{ display: `${isSubject ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Required</p>
                        </div>
                        <div>
                            <p>
                                Your message
                            </p>
                            <textarea
                                value={message}
                                onChange={(e) => setmessage(e.target.value)}
                            ></textarea>
                            <p style={{ display: `${isMessage ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Required</p>
                        </div>
                        <div>
                            <button className="button2" type="submit" disabled={loading ? true : false}>
                                {
                                    loading ? "loading . . ." : "Submit"
                                }
                            </button>
                        </div>
                    </form>
                    <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13603.332854846294!2d74.3225178!3d31.5287405!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919049a91571d0f%3A0x7abfcad02a330ad!2sPSRD%20(Pakistan%20Society%20for%20the%20Rehabilitation%20of%20the%20Disabled)!5e0!3m2!1sen!2s!4v1711380254986!5m2!1sen!2s" width="100%" height="100%" style={{ border: "0" }} allowfullscreen="" loading="lazy"
                            title="psrd"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ContectUs