import skewBg from "../../images/message-01.png"

import Circle from "../../images/departments/college circle image.png"

import Img1 from "../../images/departments/Img1.jpeg"
import Img2 from "../../images/departments/Img2.jpeg"
import Img3 from "../../images/departments/Img3.jpeg"
import Img4 from "../../images/departments/Img4.jpeg"
import Img5 from "../../images/departments/Img5.jpeg"

import "./college.css"
import { useEffect } from "react"



const College = () => {

    useEffect(() => {
        const department = document.getElementById("department");
        department.classList.add("active-page");
        document.title = "PSRD College of Rehabilitation Sciences"

        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);

    return (
        <>

            <section className="college skew-style">
                <h2 className="mobile-heading">PSRD COLLEGE OF<br />
                    <span>REHABILITATION SCIENCES</span> <br /><strong>Achievements: July 2022 - June 2023</strong></h2>
                <img src={skewBg} alt="" className="skew-img" />
                <div className="maxwidth flex column">
                    <div className="flex heading-skew">
                        <div>
                            <h2 className="orthaticesHeading">PSRD COLLEGE OF<br />
                                <span>REHABILITATION SCIENCES</span>
                                <br /><strong>Achievements: July 2022 - June 2023</strong>
                            </h2>
                            <h3 style={{ paddingTop: "5vmax" }}> Courses Offered</h3>
                            <p style={{ margin: "1.6vmax 0 0 3.4vmax" }} className="mobile-error-3">
                                Doctor of Physical Therapy (DPT),<br />
                                B.Sc Occupational Therapy (OT) and<br />
                                B.Sc Speech & Language Pathology (SLP)<br />
                            </p>
                        </div>
                        <div>
                            <img src={Circle} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="college-2">
                <div className="maxwidth flex">
                    <div>
                        <img src={Img1} alt="" />
                    </div>
                    <div>
                        <img src={Img2} alt="" />
                    </div>
                    <div>
                        <img src={Img3} alt="" />
                    </div>
                </div>
            </section>



            <section className="CommunityBased-2">
                <div className="maxwidth flex column" style={{ alignItems: "flex-start" }}>
                    <h3 style={{ paddingTop: "0", marginBottom: "-2vmax" }}>Research & Development</h3>
                    <div className="communityBasedPoints">
                        <ul>
                            <li>

                                <p>Initiated and finalized a Memorandum of Understanding with well-reputed
                                    organizations in Lahore, namely Superior University and UCP, to enhance the
                                    understanding of their curricula and integrate theoretical content into clinical
                                    training</p>
                            </li>
                            <br /> <br />
                            <li>
                                <p>Granted permission to students enrolled in degree programs at other organizations
                                    following an ethical review of their research work</p>
                            </li>
                            <br /> <br />
                            <li>
                                <p>Conducted a critical review of research projects by DPT, OT, and SLP students
                                    through the institutional review board</p>
                            </li>
                            <br /> <br />
                        </ul>
                    </div>

                </div>
            </section>

            <section className="CommunityBased-3">
                <div className="maxwidth flex column" style={{ alignItems: "flex-start", gap: "0.7vmax" }}>
                    <h3>Examination Session 2022</h3>
                    <p>Students of PSRD College held 4 positions in University of Health Sciences Exams.</p>
                    <div className="Medical-treatment ">
                        <br/>
                        <br/>
                        <h3 className="donate-heading flex" style={{ marginBottom: "1.6vmax", padding: "0.3vmax 0.7vmax", width: "100%", textAlign: "center" }}>UHS Exam Positions 2022</h3>

                    </div>


                </div>

                <div className="maxwidth table-header">
                    <div className="table flex column mobile-error-4" style={{ width: "80%", margin: "auto" }}>
                        <div className="table-heading flex orange">
                            <h5>
                                CLASS
                            </h5>
                            <h5>
                                ROLL NO.
                            </h5>
                            <h5>
                                NAME
                            </h5>
                            <h5>
                                POSITION IN UHS
                            </h5>

                        </div>
                        <div className="table-content  flex orange table-content-orange">
                            <p>OT 1st Year</p>
                            <p>723</p>
                            <p>Minahil Tahir</p>
                            <p>1st</p>

                        </div>
                        <div className="table-content flex orange table-content-orange">
                            <p>OT 1st Year</p>
                            <p>725</p>
                            <p>Sanwal Saddique</p>
                            <p>2nd</p>

                        </div>
                        <div className="table-content flex orange table-content-orange">
                            <p>SLP 1st Year</p>
                            <p>738</p>
                            <p>Laraib Shafique</p>
                            <p>3rd</p>

                        </div>
                        <div className="table-content flex orange table-content-orange">
                            <p>SLP 2nd Year</p>
                            <p>632</p>
                            <p>Aima Najam</p>
                            <p>1st</p>

                        </div>
                    </div>
                </div>
            </section>

            <section style={{ margin: "12vmax 0 2.4vmax 0" }} className="tab-error-2">
                <div className="maxwidth flex house-job">
                    <div className=" column">
                        <h3 className="donate-heading" style={{ background: "var(--blue)" }}>HOUSE JOB</h3>
                        <p>PSRD College extended house
                            job opportunities to all newly
                            graduated students, providing
                            them with a monthly stipend. </p>
                    </div>



                    <div>
                        <img src={Img4} alt="" />
                    </div>
                </div>
            </section>


            <section style={{ marginBottom: "12.5vmax" }} className="tab-error-2">
                <div className="leader maxwidth">
                    <img src={Img5} alt="" />
                </div>
            </section>
        </>
    )
}
export default College