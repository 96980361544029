import { Link } from "react-router-dom"
import "./Donation_in_kind.css"

import campus1 from "../../images/donation/campus.jpg"
import campus2 from "../../images/donation/campus2.jpeg"
import headingImg from "../../images/home/doctor checking child.jpg"
import { useEffect } from "react"


const Donation_in_kind = () => {

    useEffect(() => {
        const department = document.getElementById("donate");
        department.classList.add("active-page");
        document.title="Donation Category"
        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);
    return (
        <>
            <section className="Donation_in_kind">
                <div className="maxwidth flex column">
                    <h2>Donation <span>category</span></h2>
                    <p style={{
                        padding: "4vmax 4vmax 0 4vmax"
                    }}>The PSRD hospital, with 110 beds, is one of the largest orthopedic hospitals in Pakistan and receives patients from all over the country. It does not refuse treatment for any patient and provides quality treatment without any discrimination. A qualified team of orthopedic surgeons and supportive paramedical staff carry out all types of surgeries. An average of 15 operations per day are performed, including total joint replacement, fracture, non-union, malunion, and various types of congenital deformities like clubfoot, etc.
                    </p>



                </div>
            </section>



            <section className="Abled flex column" data-aos="flip-up">

                <div className="maxwidth flex">
                    <div className="flex column">
                        <div><img src={headingImg} alt="" /></div>
                        <h3>Our vision</h3>
                        <p>We seek a Society which</p>
                        <ul className="abled-ul">
                            <li>
                                <p>
                                    Respects and values all
                                    persons with disabilities
                                </p>
                            </li>
                            <li>
                                <p>
                                    Listens and learns
                                    from them
                                </p>
                            </li>
                            <li>
                                <p style={{ marginBottom: "20px" }}> Gives them hope
                                    and opportunity</p>
                            </li>
                        </ul>
                        <div className="mobile-error">
                            <Link className="button2" to={"/donate-now"}>Help us</Link>
                        </div>
                    </div>
                    <div className="flex abled-blocks column">

                        <div className="flex flex column">
                            <div className="numbering">01</div>
                            <h3>Sponsor a Differently Abled Patient</h3>
                            <p>Sponsoring a Differently Abled Patient allows you to assume direct responsibility for the treatment of one or more differently abled patients.</p>
                            <Link className="button2 button3" style={{ marginTop: "1vmaxs" }} to={"/donate-now"}>Donate</Link>
                        </div>
                        <div className="flex flex column">
                            <div className="numbering">02</div>
                            <h3>General Donations and Zakat</h3>
                            <p>Give your Zakat and donations to PSRD and help the differently abled meet their basic needs, medical treatments, and education to help them stand on their own feet.</p>
                            <Link className="button2 button3" to={"/donate-now"}>Donate</Link>
                        </div>
                        <div className="flex flex column">
                            <div className="numbering">03</div>
                            <h3 style={{ lineHeight: "2.4vmax" }}>Sponsor a Differently Abled Child’s Education
                            </h3>
                            <p>Your donations can educate an orthopedically challenged child from a low-income family for one year.</p>
                            <Link className="button2 button3" to={"/donate-now"}>Donate</Link>
                        </div>
                    </div>
                </div>
            </section>


            <section className="Campus">
                <div className="maxwidth flex">
                    <div className=" barki">
                        <div>
                            <img src={campus2} alt="" />
                            <h3 style={{ margin: "1.6vmax 0" }}>PSRD KIMGUL barki campus</h3>
                            <p style={{ marginBottom: "1.6vmax" }}>Help PSRD build a new facility in Kimgul Barki to help children with disabilities.</p>
                            <div style={{ marginBottom: "1.6vmax" }}>
                                <Link className="button2" to={"/donate-now"} style={{ display: "inline-block" }}>Help us</Link>
                            </div>
                        </div>
                    </div>
                    <div className="barki">
                        <div>
                            <img src={campus1} alt="" />
                            <h3 style={{ margin: "1.6vmax 0" }}>PSRD - Islamabad Chapter</h3>
                            <p style={{ marginBottom: "1.6vmax " }}>Help PSRD build a new facility in Islamabad to help children with disabilities.</p>
                            <div style={{ marginBottom: "1.6vmax" }}>
                                <Link className="button2" to={"/donate-now"} style={{ display: "inline-block" }}>Help us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Donation_in_kind