import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DonateUpdateFormForPsrd, DonategetFormDetails } from '../../../actions/DonateFormAction';
import Img from "../../images/cancel.png"
import { Link, useNavigate } from 'react-router-dom';



const FailurePage = () => {
    const dispatch = useDispatch()
    const navigate=useNavigate()

    const [id, setId] = useState()
    useEffect(() => {
        const url = window.location.href;
        const params = new URLSearchParams(url);
        const basketId = params.get('basket_id');
        setId(basketId);

        const footer = document.querySelector(".totalFooter")
        footer.style.display = "none"
        document.title = "Payment Canceled"
        
        return () => {
            footer.style.display = "block"
        }
    }, []);

    useEffect(() => {
        const object = {
            paymentStatus: "Canceled"
        };

        // Ensure id is not undefined before dispatching the action
        if (id) {
            dispatch(DonateUpdateFormForPsrd(id, object));
        }
    }, [id]); // Add id as a dependency




    return (
        <div>
            <section className="absolute failurePage flex" style={{ background: "#ff4b4b" }}>
                <div className="flex column">
                    <h3 style={{ color: "#ff4b4b" }}>Payment Canceled</h3>
                    <div>
                        <img src={Img} alt="" />
                    </div>
                    <p>
                        We understand if you've decided not to proceed with your donation at this time. Your consideration means a lot to us. Thank you for your support.
                    </p>
                    <Link to={"/"} className='button2' style={{ background: "#ff4b4b" }}>HOME</Link>
                </div>
            </section>
        </div>
    );
};

export default FailurePage;
