
import "./Vocational.css"

import skewBg from "../../images/message-01.png"
import vocational2 from "../../images/departments/vocational2.jpg"
import vocational3 from "../../images/departments/vocational3.jpg"
import President from "../../images/departments/vrc centre.png"

import { useEffect } from "react"




const Vocational = () => {
    useEffect(() => {
        const department = document.getElementById("department");
        department.classList.add("active-page");
        document.title = "Vocational Rehabilitation Centre"
        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);
    return (
        <>

            <section>
                <section className="presidentMessage skew-style">
                    <h2 className="mobile-heading">VOCATIONAL
                        REHABILITATION <span>CENTRE</span>
                        <br /><strong>Achievements: July 2022 - June 2023</strong>
                    </h2>

                    <img src={skewBg} alt="" className="skew-img" />
                    <div className="maxwidth flex column">
                        <div className="P-M-first-child flex heading-skew">
                            <div>
                                <h2 className="orthaticesHeading">VOCATIONAL
                                    REHABILITATION <span>CENTRE</span>
                                    <br /><strong>Achievements: July 2022 - June 2023</strong>
                                </h2>
                            </div>
                            <div>
                                <img src={President} alt="" />
                            </div>
                        </div>

                    </div>
                </section>
            </section>




            <section>
                <div className="maxwidth flex">
                    <div className="flex Vocational center-text">
                        <div className="Vocational-1">
                            <i class="fa-solid fa-hand-holding-dollar"></i>
                            <p style={{ color: "white" }}>The Vocational Rehabilitation Centre of
                                PSRD plays a pivotal role in empowering
                                underprivileged persons with
                                disabilities to achieve self-sufficiency
                                through interest free loans. <br /> <br /><br />
                                A grace period of two months is
                                provided to the beneficiaries before
                                they start returning their monthly
                                installments. <br /> <br /><br />
                                Since the inception of the Micro-credit
                                Scheme, a total of Rs. 30.8 million has
                                been disbursed to 1,055 individuals
                                with disabilities. This financial aid has
                                undoubtedly made a significant positive
                                impact on the lives of these differently
                                abled people. <br /> <br /><br />
                                In the year 2022-23, a total of
                                Rs. 2.38 million was disbursed to 48
                                individuals with disabilities. Notably,
                                the project boasts an outstanding loan
                                recovery rate of 97.83%, underscoring
                                the effectiveness and efficiency of
                                the prudent management and the
                                responsible conduct of the recipients.
                                <br /> <br /><br />
                            </p>
                        </div>
                        <div className="Vocational-2">
                            <p style={{ color: "white" }}>The Vocational Rehabilitation Centre
                                also provided various assistive
                                devices to enhance the mobility and
                                accessibility of deserving differently
                                abled persons, particularly crutches,
                                tripod stands, commode chairs,
                                walkers, wheelchairs and tricycles.
                                These devices were given free of cost.</p>
                            <div><img src={vocational2} alt="" /></div>
                        </div>
                    </div>
                </div>
            </section>





            <section>
                <div className="maxwidth v-c-r-section center-text">
                    <p>VRC conducted business management trainings before disbursing the loans so as to not only
                        provide financial assistance but also equip the beneficiaries with the necessary skills to manage
                        their resources and make informed decisions. The training program focused on four key areas:
                        planning, budgeting, innovation, and record-keeping.
                        VRC feels proud to help people with disabilities to stand on their own feet by providing them
                        with interest free loans.</p>
                </div>
            </section>


            <section className="maxwidth tab-error-3 center-text">

                <div>

                    <div>
                        <h4>Success Story:</h4>
                        <p style={{ padding: "1.6vmax 10vmax 0 0" }}>Nazia Vicky, a married woman with primary education and a beautician diploma, has faced
                            the challenge of polio affecting her right leg since childhood. Married with two daughters,
                            her husband’s road accident resulted in injuries to both legs, adding further
                            difficulty to the family. To make ends meet, Nazia initially worked at a beauty parlour, but her
                            earnings were insufficient for the entire family.
                        </p>
                    </div>

                </div>
            </section>



            <section className=" Nazia center-text">
                <div className="flex Fortunately maxwidth">
                    <div className="Fortunately-1">

                        <div>
                            <img src={vocational3} alt="" />
                        </div>
                    </div>

                    <div>
                        <p style={{ color: "white" }}>Fortunately, Nazia discovered the
                            Vocational Rehabilitation Centre of PSRD
                            and applied for a loan of Rs. 35,000 to
                            purchase makeup and tools necessary to
                            offer her beauty services to clients at their
                            doorstep. With dedication and experience,
                            she earned Rs. 18,000 monthly. <br /> <br />
                            Encouraged by her success, Nazia applied
                            for a second loan of Rs. 45,000 to establish
                            her own parlour, a request accepted by the
                            VRC based on her commendable record.<br /> <br />
                            Now, with her own parlour, Nazia is
                            earning more than Rs. 35,000 monthly.
                            This significant increase in income has
                            transformed her life, and Nazia expresses
                            her sincere gratitude to the VRC for making
                            her life more comfortable.<br /> <br />
                        </p>

                    </div>
                </div>
            </section>

        </>
    )
}
export default Vocational