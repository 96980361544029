
export const CREATE_FORM_REQUEST = "CREATE_FORM_REQUEST"
export const CREATE_FORM_SUCCESS = "CREATE_FORM_SUCCESS"
export const CREATE_FORM_FAIL = "CREATE_FORM_FAIL"

export const GET_ALL_FORMS_REQUEST = "GET_ALL_FORMS_REQUEST"
export const GET_ALL_FORMS_SUCCESS = "GET_ALL_FORMS_SUCCESS"
export const GET_ALL_FORMS_FAIL = "GET_ALL_FORMS_FAIL"

export const DELETE_FORM_REQUEST = "DELETE_FORM_REQUEST"
export const DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS"
export const DELETE_FORM_FAIL = "DELETE_FORM_FAIL"

export const UPDATE_FORM_REQUEST = "DELETE_FORM_REQUEST"
export const UPDATE_FORM_SUCCESS = "DELETE_FORM_SUCCESS"
export const UPDATE_FORM_FAIL = "DELETE_FORM_FAIL"

export const GET_FORM_DETAILS_REQUEST = "GET_FORM_DETAILS_REQUEST"
export const GET_FORM_DETAILS_SUCCESS = "GET_FORM_DETAILS_SUCCESS"
export const GET_FORM_DETAILS_FAIL = "GET_FORM_DETAILS_FAIL"


export const CLEAR_ERRORS = "CLEAR_ERRORS"