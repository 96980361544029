import { Fragment } from "react"

import ortho from "../../images/departments/ortho child-01.png"
import dr1 from "../../images/departments/DR1.JPG"
import dr2 from "../../images/departments/DR2.JPG"
import dr3 from "../../images/departments/DR3.JPG"
import "./Orthotics.css"
import skewBg from "../../images/message-01.png"

import { useEffect } from "react"






const Orthotics = () => {
    useEffect(() => {
        const department = document.getElementById("department");
        department.classList.add("active-page");
        document.title = "Orthotices and Prosthetices Centre"

        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);
    return (
        <Fragment>

            <section className="presidentMessage skew-style ">
                <h2 className="mobile-heading orthaticesHeading">Orthotics & 
                    Prosthetics <span >centre</span>
                    <br /><strong>Achievements: July 2022 - June 2023</strong>
                </h2>
                <img src={skewBg} alt="" className="skew-img" />

                <div className="maxwidth flex column">
                    <div className=" flex heading-skew">
                        <div>
                            <h2>Orthotics & 
                                Prosthetics <span >centre</span>
                                <br/><strong>Achievements: July 2022 - June 2023</strong>
                                </h2>
                            <p style={{ marginTop: "6vmax" }}>
                                The Orthotics and Prosthetics Workshop has
                                attended to 5,191 patients and manufactured/
                                delivered 4,972 appliances including upper and lower limb
                                prostheses, splints, braces, orthopedic shoes etc. The silicon
                                items, hyperextension and lumbo-sacral corset have also shown
                                good response as these items are delivered to patients in the same
                                visit.
                            </p>
                        </div>
                        <div>
                            <img src={ortho} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section >
                <div className="maxwidth flex  altso">
                    <div className="altso-block-1 flex column">
                        <h3 style={{ color: "white" }}>ALTSO Project</h3>
                        <p style={{ color: "white", paddingBottom: "7vmax" }}>336 patients were delivered 514 appliances</p>
                        <div>
                            <img src={dr1} alt="" />
                        </div>
                    </div>
                    <div className="altso-block-2">
                        <div>
                            <img src={dr2} alt="" />
                        </div>
                        <div>
                            <img src={dr3} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="maxwidth orthoTable flex column">
                    <div className="table flex column" style={{ width: "60%" }}>
                        <div className="table-heading flex">

                            <h5>
                                Patients Registered
                            </h5>

                        </div>
                        <div className="table-content flex">
                            <p>Patients from OPD</p>
                            <p>1,836</p>



                        </div>
                        <div className="table-content flex">
                            <p>Patients referred from surgeon</p>

                            <p>3,019</p>


                        </div>
                        <div className="table-content flex">
                            <p>ALTSO</p>
                            <p>336</p>

                        </div>

                    </div>
                    <div className="lineHeading flex" style={{ justifyContent: "space-between", width: "60%", padding: "20px 0" }}>
                        <div style={{ width: "85%", height: "0.2vmax", backgroundColor: "var(--blue)" }} >
                        </div>
                        <h5>
                            TOTAL: 5,191
                        </h5>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default Orthotics