

import {
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    CLEAR_ERRORS,

    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,

    VERIFY_EMAIL_REQUEST,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAIL,


    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,

    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,

    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,

    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAIL,

} from "../constants/userConstants";
import axios from "axios";

export const RegisterUser = (user) => async (dispatch) => {
    try {
        dispatch({ type: REGISTER_USER_REQUEST });

        const config = { headers: { "content-type": "application/json" } };

        const { data } = await axios.post("/api/v1/register/user", user, config);

        dispatch({
            type: REGISTER_USER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: REGISTER_USER_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const logoutUser = () => async (dispatch) => {
    try {
        await axios.get(`/api/v1/logout`)
        dispatch({ type: LOGOUT_USER_SUCCESS, })
    } catch (error) {
        dispatch({
            type: LOGOUT_USER_FAIL,
            payload: error.response.data.message,
        })
    }
}
export const loadUser = () => async (dispatch) => {
    try {
        dispatch({ type: LOAD_USER_REQUEST })
        const { data } = await axios.get(`/api/v1/me`)
        dispatch({ type: LOAD_USER_SUCCESS, payload: data, })
    } catch (error) {
        dispatch({
            type: LOAD_USER_FAIL,
            payload: error.response.data.message,
        })
    }
}

export const loginUser = (user) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_USER_REQUEST });

        // Correct the content-type header
        const config = { headers: { "content-type": "application/json" } };

        const { data } = await axios.post("/api/v1/login", user, config);

        dispatch({
            type: LOGIN_USER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: LOGIN_USER_FAIL,
            payload: error.response.data.message,
        });
    }
};

export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};


export const ForgotPasswordAction = (email) => async (dispatch) => {
    try {
        dispatch({ type: FORGOT_PASSWORD_REQUEST })
        const config = { headers: { "content-type": "application/json" } };

        const { data } = await axios.post(`/api/v1/password/forgot/`, email, config)
        dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: FORGOT_PASSWORD_FAIL,
            payload: error.response.data.message,
        })
    }
}

export const resetPassword = (token, passwords) => async (dispatch) => {
    try {
        dispatch({ type: RESET_PASSWORD_REQUEST })
        const config = { headers: { "content-Type": "application/json" } }
        const { data } = await axios.put(`/api/v1/password/reset/${token}`, passwords, config)
        dispatch({
            type: RESET_PASSWORD_SUCCESS,
            payload: data.success,
        })
    } catch (error) {
        dispatch({
            type: RESET_PASSWORD_FAIL,
            payload: error.response.data.message,
        })
    }
}

export const logout = () => async (dispatch) => {
    try {

        await axios.get(`/api/v1/logout`)
        dispatch({ type: LOGOUT_USER_SUCCESS })
    } catch (error) {
        dispatch({
            type: LOGOUT_USER_FAIL,
            payload: error.response.data.message,
        })
    }
}