import { useEffect } from "react";
import "./boardOfGovernors.css"
import President from "../../images/about/President-of-PSRD.png"


import Nighat from "../../images/about/board of governer/Nighat Saeed.png"


import Naureen1 from "../../images/about/board of governer/Naureen L. Siddiqui-01.png"
import Naureen from "../../images/about/board of governer/Naureen Latif.png"
import Alina from "../../images/about/board of governer/Alina.png"
import Aqeela from "../../images/about/board of governer/Aqeela Saeed.png"
import Parvez from "../../images/about/board of governer/Muhammad Parvez Masud S.I..png"
import Tanweer from "../../images/about/board of governer/Tanweer Zia Kazmi.png"
import Rehana from "../../images/about/board of governer/Rehana M. Baray.png"
import Amar from "../../images/about/board of governer/Amar Hameed.png"
import Parveen from "../../images/about/board of governer/Parveen Umar.png"
import Bakhtiar from "../../images/about/board of governer/Lt. Col. Muhammad Bakhtiar.png"
import Ferhana from "../../images/about/board of governer/Ferhana Qayum Sher.png"
import Asma from "../../images/about/board of governer/Asma Waziri.png"
import Zahida from "../../images/about/board of governer/Zahida Faisal Beig.png"
import Altaf from "../../images/about/board of governer/Dr. Altaf Hussain.png"
import Mamoona from "../../images/about/board of governer/Mamoona Mustansar.png"
import Samina from "../../images/about/board of governer/Samina Asif Shah.png"
import Ismat from "../../images/about/board of governer/Ismat Zehra.png"




const BoardOfGovernors = () => {
    useEffect(() => {
        const aboutButton = document.getElementById("about");
        aboutButton.classList.add("active-page");
        document.title = "Board of Governors";
        
        // Cleanup function
        return () => {
            aboutButton.classList.remove("active-page");
        };
    }, []);
    return (
        <section className="boardOfGovernors">
            <div className="maxwidth flex column">
                <h2 style={{ textTransform: "uppercase" }}>board <span>Of Governors</span></h2>
                <div className="flex">
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={President} alt="" />
                        </div>
                        <h3>M. Saeed Shamsi</h3>
                        <p>President</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Parvez} alt="" />
                        </div>
                        <h3>Muhammad Parvez Masud</h3>
                        <p>former president</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Tanweer} alt="" />
                        </div>
                        <h3>Tanweer Zia Kazmi</h3>
                        <p>Vice President</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Rehana} alt="" />
                        </div>
                        <h3>Rehana Maroof Baray</h3>
                        <p>Vice President</p>
                    </div>

                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Amar} alt="" />
                        </div>
                        <h3>Sheikh Amar Hameed</h3>
                        <p>Treasurer</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Parveen} alt="" />
                        </div>
                        <h3>Parveen Umar</h3>
                        <p>General Secretary</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Nighat} alt="" />
                        </div>
                        <h3>Nighat Saeed </h3>
                        <p>Secretary</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Bakhtiar} alt="" />
                        </div>
                        <h3>Lt. Col. Muhammad Bakhtiar</h3>
                        <p>Joint Secretary</p>
                    </div>

                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Naureen1} alt="" />
                        </div>
                        <h3>Naureen Latif Siddiqui</h3>
                        <p>Member</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Aqeela} alt="" />
                        </div>
                        <h3>Aqeela Saeed Shamsi </h3>
                        <p>Member</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Ferhana} alt="" />
                        </div>
                        <h3>Ferhana Qayum Sher</h3>
                        <p>Member</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Asma} alt="" />
                        </div>
                        <h3>Asma Waziri</h3>
                        <p>Member</p>
                    </div>

                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Zahida} alt="" />
                        </div>
                        <h3>Zahida Feisal Beig</h3>
                        <p>Member</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Altaf} alt="" />
                        </div>
                        <h3>Dr. Altaf Hussain</h3>
                        <p>Member</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Mamoona} alt="" />
                        </div>
                        <h3>Mamoona Mustansar</h3>
                        <p>Member</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Naureen} alt="" />
                        </div>
                        <h3>Rubina Imtiaz</h3>
                        <p>Member</p>
                    </div>

                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Samina} alt="" />
                        </div>
                        <h3>Samina Asif </h3>
                        <p>Member</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img src={Ismat} alt="" />
                        </div>
                        <h3>Ismat Zehra</h3>
                        <p>Member</p>
                    </div>
                    <div className="B-O-G-block flex column">
                        <div>
                            <img style={{ borderRadius: "50%" }} src={Alina} alt="" />
                        </div>
                        <h3>Alina A. Murtaza</h3>
                        <p>Member</p>
                    </div>
                </div>
            </div>
        </section >
    )
}
export default BoardOfGovernors