import React, { useEffect } from 'react';
import report from '../../images/annualReports/Annual Review 2022-2023 (1)_compressed.pdf'




function PdfViewer() {



  useEffect(() => {
    const gallery = document.getElementById("annual_report");
    gallery.classList.add("active-page");
    document.title = "Annual Review 2022-2023"
    // Cleanup function
    return () => {
      gallery.classList.remove("active-page");
    };
  }, [])
  return (

    <>

      <section className=' REPORT'>

        <div className='maxwidth flex column'>


          <h2 style={{ textAlign: "center" }}>Financial Summary <br /><span>
            2022-2023</span></h2>

        </div>
        <div className='flex column'>

          <embed src={report} type="application/pdf" width="70%" height="800px" style={{ border: "2px solid var(--blue)", margin: "2vmax" }} />

        </div>
      </section>
    </>
  );


}

export default PdfViewer;
