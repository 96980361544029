import { Fragment, useEffect, useState } from "react"

import Logo from "../images/psrd.png"
import { DonateCreateForm } from "../../actions/DonateFormAction"

import { useDispatch, useSelector } from "react-redux"
import Loader from "../../loader/Loader"
import Alert from "../alert/Alert"
import { useNavigate } from "react-router-dom"

const DonateForm = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loading, success, donateData, error } = useSelector((state) => state.Donate)

    const [isName, setIsName] = useState(true)
    const [isEmail, setisEmail] = useState(true)
    const [isnumber, setisnumber] = useState(true)
    const [isAddress, setIsAddress] = useState(true)
    const [isDonationtype, setisDonationtype] = useState(true)
    const [isamount, setisamount] = useState(true)

    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [number, setnumber] = useState()
    const [address, setaddress] = useState()
    const [donationType, setdonationType] = useState()
    const [amount, setAmount] = useState(null)


    useEffect(() => {
        if (success) {
            navigate(`/checkout/${donateData._id}`)
        }


    }, [success])



    const submitForm = (e) => {
        e.preventDefault()

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!name) {
            setIsName(false)
        } else {
            setIsName(true)
        }



        if (emailRegex.test(email)) {
            setisEmail(true)
        }
        else {
            setisEmail(false)
        }



        if (number && number.length === 11) {
            setisnumber(true)
        } else {
            setisnumber(false)
        }


        if (!address) {
            setIsAddress(false)
        } else {
            setIsAddress(true)
        }
        if (!donationType) {
            setisDonationtype(false)
        } else {
            setisDonationtype(true)
        }
        if (!amount) {
            setisamount(false)
        } else {
            setisamount(true)

        }

        const form = {
            name: name,
            email: email,
            number: number,
            address: address,
            donationType: donationType,
            amount: amount
        }

        console.log(form)

        if (isName && isEmail && isAddress && isnumber && isDonationtype) {
            dispatch(DonateCreateForm(form))
            console.log(form)
        } else {
            return
        }

    }


    return (
        <Fragment>
            {
                loading ? <Loader /> :
                    <section className="home-donate-now flex">
                        {
                            error ? <Alert type={false} time={4} message={error} ></Alert> : ""
                        }
                        <div className="maxwidth flex">
                            <div className="home-donate-form">
                                <form action="" className="flex column">
                                    <div className="donate-logo">
                                        <img src={Logo} alt="" />
                                    </div>
                                    <h3 className="donate-heading">Support Us</h3>
                                    <div className="flex">
                                        <span style={{ background: `${isamount}` }}>PKR</span>
                                        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                    </div>
                                    <label className="button2" for="inputName1">Next <i className="fa-solid fa-arrow-right"></i></label>
                                </form>
                            </div>
                            <div className="home-donate-heading">
                                <form onSubmit={submitForm} className="flex column">
                                    <h3 className="donate-heading">Form Details </h3>
                                    <div>
                                        <p>Donate<span>*</span></p>
                                        <div className="flex radio">
                                            <div>
                                                <input type="radio" id="radio1" name="radios" value="Zakat" onChange={(e) => setdonationType(e.target.value)} />
                                                <label for="radio1">Zakat</label>
                                            </div>
                                            <div>
                                                <input type="radio" id="radio2" name="radios" value="Donation" onChange={(e) => setdonationType(e.target.value)} />
                                                <label for="radio2">Donation</label>
                                            </div>
                                            <div>
                                                <input type="radio" id="radio3" name="radios" value="Sadqat" onChange={(e) => setdonationType(e.target.value)} />
                                                <label for="radio3">Sadqat</label>
                                            </div>
                                            <p style={{ display: `${isDonationtype ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Required</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p>Full Name<span>*</span></p>
                                        <input type="text"
                                            id="inputName1"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        <p style={{ display: `${isName ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Required</p>
                                    </div>
                                    <div>
                                        <p>Phone Number<span>*</span></p>
                                        <input type="number"
                                            value={number}
                                            onChange={(e) => setnumber(e.target.value)}
                                        />
                                        <p style={{ display: `${isnumber ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> please Enter a valid number</p>
                                    </div>
                                    <div>
                                        <p>Email Address<span>*</span></p>
                                        <input type="text"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <p style={{ display: `${isEmail ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Please Enter a valid Email</p>
                                    </div>

                                    <div>
                                        <p>Town / city<span>*</span></p>
                                        <input type="text"
                                            value={address}
                                            onChange={(e) => setaddress(e.target.value)}
                                        />
                                        <p style={{ display: `${isAddress ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Required</p>
                                    </div>
                                    <div>
                                        <button type="submit" className="button2"
                                            disabled={amount ? false : true}
                                            style={{ cursor: amount ? "pointer" : "not-allowed", background: amount ? "var(--blue" : "lightGrey" }}
                                        >Donate</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
            }
        </Fragment>
    )
}


export default DonateForm