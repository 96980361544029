import React, { useEffect, useState } from 'react';
import './alert.css';
import Img1 from "../images/checked.png"
import Img2 from "../images/cancel.png"
import Img3 from "../images/close.png"



const Alert = ({ type, time, message }) => {

    const [seconds, setSeconds] = useState(time);
    useEffect(() => {
        if (seconds > 0) {
            const Alert = document.querySelector(".alert")
            Alert.classList.add("active-alert")
            const intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [seconds]);

    useEffect(() => {
        if (seconds === 0) {
            const Alert = document.querySelector(".alert")
            Alert.classList.remove("active-alert")
        }
    }, [seconds]);
    const hideAlert = () => {
        const Alert = document.querySelector(".alert")
        Alert.classList.remove("active-alert")
        setSeconds(0)
    }
    return (
        <section className='alert'>
            {
                type ?
                    <img src={Img1} alt="" />
                    :
                    <img src={Img2} alt="" />
            }
            <span>{message}</span>
            <button onClick={() => hideAlert()}><img src={Img3} alt="" /></button>
        </section>
    );
};

export default Alert;
