import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import Loader from "../../../loader/Loader"
import { DonateDeleteForm, DonategetFormDetails } from "../../../actions/DonateFormAction"




const FormDetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(true);
    const { donateData } = useSelector((state) => state.DonationDetails)
    const { success } = useSelector((state) => state.DeleteDonation)

    const deleteForm = (id) => {
        dispatch(DonateDeleteForm(id))
    }


    useEffect(() => {

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);
    useEffect(() => {
        if (success) {
            navigate("/Admin-panel/dashboard")
        }
    }, [success, navigate]);


    useEffect(() => {

        dispatch(DonategetFormDetails(id))

        const footer = document.querySelector(".totalFooter")
        footer.style.display = "none"
        document.title = "Form Details"
        return () => {
            footer.style.display = "block"
        }
    }, [])
    return (
        <>
            {
                loading ? <Loader /> :
                    <section className="formDetails absolute">
                        <div>
                            <div>
                                <h1>{donateData && donateData.name.slice(0, 1)}</h1>
                                <div>
                                    <button className="button2 button3" onClick={() => deleteForm(donateData._id)}>Delete</button>
                                </div>

                            </div>
                            <div>
                                <div>
                                    <h3>
                                        <i className="fa-regular fa-user"></i>Name
                                    </h3>
                                    <p>{donateData && donateData.name}</p>
                                </div>
                                <div>
                                    <h3>
                                        <i className="fa-regular fa-envelope"></i>Email
                                    </h3>
                                    <p>{donateData && donateData.email}</p>
                                </div>
                                <div>
                                    <h3>
                                        <i className="fa-solid fa-arrows-to-eye"></i>Donation Type
                                    </h3>
                                    <p>{donateData && donateData.donationType}</p>
                                </div>
                                <div>
                                    <h3>
                                        <i className="fa-solid fa-location"></i>Address
                                    </h3>
                                    <p>{donateData && donateData.address}</p>
                                </div>
                                <div>
                                    <h3>
                                        <i className="fa-solid fa-phone"></i>Phone Number
                                    </h3>
                                    <p>{donateData && donateData.number}</p>
                                </div>
                                <div>
                                    <h3>
                                        <i className="fa-regular fa-eye"></i>Payment Status
                                    </h3>
                                    <p style={{ color: donateData && donateData.paymentStatus === "received" ? "green" : "red" }}>{donateData && donateData.paymentStatus}</p>
                                </div>
                                <div>
                                    <p className="fixDate"><i>{donateData && donateData.createdAt.slice(0, 10)}</i></p>
                                </div>
                            </div>
                        </div>
                    </section >

            }

        </>

    )
}
export default FormDetails