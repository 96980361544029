import { useEffect, useState } from "react"
import Logo from "../../images/psrd.png"
import { Link, useNavigate } from "react-router-dom"
import "./Login.css"
import { useDispatch, useSelector } from "react-redux"
import { loginUser, ForgotPasswordAction } from "../../../actions/userAction"
import Alert from "../../alert/Alert"



const Login = () => {
    const dispatch = useDispatch()
    const Navigate = useNavigate()

    const { loading, success, error } = useSelector((state) => state.user)
    const { loading: forgetLoading, success: forgetSuccess, error: forgetError } = useSelector((state) => state.ForgotPassword)



    const [isEmail, setIsEmail] = useState(true)
    const [isPassword, setIsPassword] = useState(true)

    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)

    const [isForgetEmail, setIsForgetEmail] = useState(true)
    const [forget, setForget] = useState(false)
    const [forgetEmail, setForgetEmail] = useState(null)

    const submitForm = (e) => {
        e.preventDefault()

        if (!email) {
            setIsEmail(false)
        } else {
            setIsEmail(true)
        }

        if (!password) {
            setIsPassword(false)
        } else {
            setIsPassword(true)
        }

        const user = {
            email: email,
            password: password,
        }

        if (isEmail && isPassword) {
            dispatch(loginUser(user))
        } else {
            return
        }
    }

    const forgetPassword = () => {
        if (!forgetEmail) {
            setIsForgetEmail(false)
        } else {
            setIsForgetEmail(true)
        }

        const forgetEmailData = {
            email: forgetEmail,
        }

        if (isForgetEmail) {
            dispatch(ForgotPasswordAction(forgetEmailData))
        } else {
            return
        }
    }

    useEffect(() => {
        const footer = document.querySelector(".totalFooter")
        footer.style.display = "none"
        document.title="Login -- PSRD"

        return () => {
            footer.style.display = "block"
        }
    }, [])

    useEffect(() => {
        if (success) {
            Navigate("/")
        }
    }, [success])
    useEffect(() => {
        if (forgetSuccess) {
            Navigate("/admin-panel/email-verification")
        }
    }, [forgetSuccess])

    return (
        <>
            <section className="login absolute">

                {error && <Alert type={false} time={4} message={error}></Alert>}
                <div>
                    <div className="login-left">
                        <div>
                            <img src={Logo} alt="" />
                        </div>
                        <h3 style={{ color: "white" }}>WELCOME BACK</h3>
                        <p>Join Hands with PSRD!<br />
                            Your contribution as donations, zakat or sadqat can help us shape future of Differetly Abled children</p>
                    </div>
                    <div className="loginContent">
                        <h2>Sign In to your account</h2>
                        <p>Enter your email & password to continue</p>
                        <form onSubmit={submitForm}>
                            <div>
                                <div><i className="fa-solid fa-envelope"></i></div>
                                <input
                                    type="text" placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <p style={{ display: `${isEmail ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Please Enter your Email</p>
                            </div>
                            <div>
                                <div><i className="fa-solid fa-lock"></i></div>
                                <input type="password" placeholder="Passsword"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <p style={{ display: `${isPassword ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Please Enter Password</p>
                            </div>
                            <div className="ForgetPassword">
                                <button onClick={() => setForget(true)} type="button">Forgot Password?</button>
                            </div>
                            <div>
                            </div>
                            <div>
                                <button type="submit" className="button2" style={{ background: "var(--purple)" }}
                                    disabled={loading ? true : false}
                                >{loading ? "loading..." : "login"}</button>
                            </div>
                            <div className="SwitchToAccount">
                                <p>Don't Have an account? <Link to={"/Admin-panel/register-psrd"} style={{ color: "var(--purple)", borderBottom: "0.1vmax solid var(--purple)" }}>Register</Link> </p>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section className="forgetPasswordSection flex" style={{ display: `${forget ? "" : "none"}` }}>
                <div className="flex column">
                    <div>
                        <button onClick={() => setForget(false)}>
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div style={{ position: "relative" }}>
                        <p> your Email address</p>
                        <input type="text" value={forgetEmail} onChange={(e) => setForgetEmail(e.target.value)} />
                        <p style={{ display: `${forgetError ? "block" : "none"}`, paddingTop: "1vmax" }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> {forgetError}</p>
                    </div>
                    <div>
                        <button className="button2" onClick={() => forgetPassword()} disabled={forgetLoading ? true : false}>
                            {
                                forgetLoading ? "loading..." : "send"
                            }
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login
