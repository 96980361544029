import {
    CREATE_NEWSLETTER_REQUEST,
    CREATE_NEWSLETTER_SUCCESS,
    CREATE_NEWSLETTER_FAIL,
    GET_ALL_NEWSLETTER_REQUEST,
    GET_ALL_NEWSLETTER_SUCCESS,
    GET_ALL_NEWSLETTER_FAIL,
    DELETE_NEWSLETTER_REQUEST,
    DELETE_NEWSLETTER_SUCCESS,
    DELETE_NEWSLETTER_FAIL,
    GET_NEWSLETTER_DETAILS_REQUEST,
    GET_NEWSLETTER_DETAILS_SUCCESS,
    GET_NEWSLETTER_DETAILS_FAIL,
    CLEAR_ERRORS,
}
    from "../constants/newsLetterconstants"
import axios from "axios";


export const CreateNewsletter = (form) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_NEWSLETTER_REQUEST });

        const config = { headers: { "content-type": "application/json" } };

        const { data } = await axios.post("/sub/api/v1/newsletter", form, config);

        dispatch({
            type: CREATE_NEWSLETTER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_NEWSLETTER_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const GetAllNewsletter = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_NEWSLETTER_REQUEST });


        const { data } = await axios.get("/api/v1/all-newsletter");

        dispatch({
            type: GET_ALL_NEWSLETTER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_ALL_NEWSLETTER_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const DeleteNewsletter = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_NEWSLETTER_REQUEST });


        const { data } = await axios.delete(`/api/v1/delete/newsletter/${id}`);

        dispatch({
            type: DELETE_NEWSLETTER_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DELETE_NEWSLETTER_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const getNewsletterDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_NEWSLETTER_DETAILS_REQUEST });


        const { data } = await axios.get(`/api/v1/newsletter/details/${id}`);

        dispatch({
            type: GET_NEWSLETTER_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_NEWSLETTER_DETAILS_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
};