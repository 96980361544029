
export const CREATE_NEWSLETTER_REQUEST = "CREATE_NEWSLETTER_REQUEST"
export const CREATE_NEWSLETTER_SUCCESS = "CREATE_NEWSLETTER_SUCCESS"
export const CREATE_NEWSLETTER_FAIL = "CREATE_NEWSLETTER_FAIL"

export const GET_ALL_NEWSLETTER_REQUEST = "GET_ALL_NEWSLETTER_REQUEST"
export const GET_ALL_NEWSLETTER_SUCCESS = "GET_ALL_NEWSLETTER_SUCCESS"
export const GET_ALL_NEWSLETTER_FAIL = "GET_ALL_NEWSLETTER_FAIL"

export const DELETE_NEWSLETTER_REQUEST = "DELETE_NEWSLETTER_REQUEST"
export const DELETE_NEWSLETTER_SUCCESS = "DELETE_NEWSLETTER_SUCCESS"
export const DELETE_NEWSLETTER_FAIL = "DELETE_NEWSLETTER_FAIL"


export const GET_NEWSLETTER_DETAILS_REQUEST = "GET_NEWSLETTER_DETAILS_REQUEST"
export const GET_NEWSLETTER_DETAILS_SUCCESS = "GET_NEWSLETTER_DETAILS_SUCCESS"
export const GET_NEWSLETTER_DETAILS_FAIL = "GET_NEWSLETTER_DETAILS_FAIL"


export const CLEAR_ERRORS = "CLEAR_ERRORS"