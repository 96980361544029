import "./presidentMessage.css"
import President from "../../images/about/President-of-PSRD.png"
import skewBg from "../../images/message-01.png"
import { useEffect } from "react";



const PresidentMessage = () => {
    useEffect(() => {
        const aboutButton = document.getElementById("about");
        aboutButton.classList.add("active-page");
        document.title = "President's Message";

        // Cleanup function
        return () => {
            aboutButton.classList.remove("active-page");
        };
    }, []);
    return (
        <>
            <section className="presidentMessage skew-style">
                <h2 className="mobile-heading">President's <span>Message</span></h2>
                <img src={skewBg} alt="" className="skew-img skew-img-desktop" />
                <div className="maxwidth flex column">
                    <div className="P-M-first-child flex heading-skew">
                        <div>
                            <h2>President's <span>Message</span></h2>
                            <h3>
                                Muhammad Saeed Shamsi <br />
                                <span>President <span>PSRD</span></span>
                            </h3>
                        </div>
                        <div>
                            <img src={President} alt="" />
                        </div>
                    </div>
                    <p>
                    The last year was full of new challenges and achievements.
                        <br /><br />
                        We are pleased to announce that we have started the Out Patient Department at the Islamabad Chapter of PSRD. Though the footfall of patients is low presently but it was important to let the people around know that affordable Orthopedic Facility will soon be fully operational. The approvals from CDA are still awaited to complete the construction of the front multi-storied block.
                        <br /><br />
                        At KimGul we have started the construction of the first building which is going to house the Physiotherapy Department on the ground floor and the Skill Development Department on the first floor. In previous years we have done a lot of Community Based Service Programs there and our experience is that Skill Development is the biggest need in the area.
                        <br /><br />
                        To ensure the availability of genuine medicines at concessional rates we have set up our own Pharmacy which provides medicines at a discount of 10% to all patients and 100% to the neediest patients.<br /><br />
                        Occupational Therapy has become very prominent especially in handling stroke patients to be able to manage their daily tasks.<br /><br />
                        Speech and Language Therapy is helping patients, mostly young children to be able to use their tongues to make audible sounds.<br /><br />
                        PSRD College of Rehabilitation Sciences has done extremely well by getting positions in all the Professional Exams held by the University of Health Sciences.<br /><br />
                        Unfortunately, the unprecedented rains in June caused flooding of the ground floor and the basement
                        of the OPD. Our staff worked day and night to be back in operation within two days.<br /><br />
                        I take this opportunity to thank all the members of the Board of Governors, doctors, paramedical  staff,
                        teachers and the admin department for their hard work. I am particularly grateful to all our donors,
                        whose donations and support keep us moving forward to follow our Mission and Vision to rehabilitate
                        Differently Abled persons.<br /><br />
                    </p>
                </div>
            </section>
        </>
    )
}
export default PresidentMessage