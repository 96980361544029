import "./Donte_now.css"

import Img1 from "../../images/donation/cheque.png"
import Img2 from "../../images/donation/Direct deposits.png"
import Img3 from "../../images/donation/mobile-payment.png"
import Img4 from "../../images/donation/international.png"
import { useEffect, useState } from "react"

import DonateForm from "../../donateForm/donateForm"


const Donte_now = () => {

    const [viewForm, setviewForm] = useState(false)


    const showForm = () => {
        setviewForm(true)
    }
    const HideForm = () => {
        setviewForm(false)
    }
    useEffect(() => {
        const department = document.getElementById("donate");
        department.classList.add("active-page");
        document.title = "Donate Now"
        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);

    return (
        <>
            <section className="donate_now flex column">
                <div className="maxwidth flex column">
                    <h2>Donate <span>Now</span></h2>
                    <h2 className="h2-style">
                        Donate through online<br /> banking and direct deposits.
                    </h2>
                    <div className="flex donate-block">
                        <div className="flex donate-block-1">
                            <div>
                                <img src={Img1} alt="" />
                            </div>
                            <div className="flex column">
                                <h3>DONATE BY CHEQUE</h3>
                                <p>
                                    Give your zakat or donations to PSRD to help the differently abled stand on their own two feet.
                                    Post or deposit your cheques to PSRD <br />111, Ferozepur Road, Lahore, Pakistan.
                                </p>
                            </div>
                        </div>
                        <div className="flex donate-block-2">
                            <div>
                                <img src={Img2} alt="" />
                            </div>
                            <div className="flex column">
                                <h3>Direct Deposits</h3>
                                <p style={{ lineHeight: "2.4vmax" }}>
                                    BANK NAME: Faysal Bank   <br />
                                    Account Mode: Zakat<br />
                                    IBAN: PK49FAYS3599301000001849<br />
                                    SWIFT Code: FAYSPKKA<br />
                                </p>
                            </div>
                        </div>
                        <div className="flex donate-block-3">
                            <div>
                                <img src={Img3} alt="" />
                            </div>
                            <div className="flex column">
                                <h3>Online deposits</h3>
                                <br />
                                <button className="donateButton" onClick={() => showForm()}>
                                    Donate Now
                                </button>
                                <iframe src="https://lottie.host/embed/12e6f4d1-d75a-496a-b27a-a37fa3eb566d/lnrapnAjPo.json"
                                    title="Psrd"
                                    style={{ border: "none" }}
                                    className="iframe"
                                    onClick={() => showForm()}
                                ></iframe>
                            </div>
                        </div>
                        <div className="flex donate-block-3">
                            <div>
                                <img src={Img4} alt="" />
                            </div>
                            <div className="flex column">
                                <h3>
                                    Deposit through I Care</h3>
                                <br />
                                <p>
                                    PSRD. Partnering with I Care for <br />International Deposits
                                </p>
                                <a href="https://i-care-america.org/charity/pakistan-society-for-rehabilitation-of-differently-abled-psrd/donate/?org=Pakistan%20Society%20for%20Rehabilitation%20of%20Differently-abled%20(PSRD)&amount=0" target="_blank" className="button2 donate-button2">Donate Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className={`donate-form ${viewForm ? "show-form" : ""}`}>
                <DonateForm />
                <button className="popup-close-button" onClick={() => HideForm()}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
        </>
    )
}
export default Donte_now