import "./formDetails.css"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { DeleteForm, getFormDetails } from "../../../actions/FormAction"
import { useEffect, useState } from "react"




const FormDetails = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(true);
    const { formData } = useSelector((state) => state.formDetails)
    const { success } = useSelector((state) => state.DeleteForm)

    const deleteForm = (id) => {
        dispatch(DeleteForm(id))
    }


    useEffect(() => {

        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);
    useEffect(() => {
        if (success) {
            navigate("/Admin-panel/dashboard")
        }
    }, [success, navigate]);


    useEffect(() => {

        dispatch(getFormDetails(id))
        
        const footer = document.querySelector(".totalFooter")
        footer.style.display = "none"
        document.title = "Form Details"
        return () => {
            footer.style.display = "block"
        }
    }, [])
    return (
        <>

            <section className="formDetails absolute">
                <div>
                    <div>
                        <h1>{formData && formData.name.slice(0, 1)}</h1>
                        <div>
                            <button className="button2 button3" onClick={() => deleteForm(formData._id)}>Delete</button>
                        </div>

                    </div>
                    <div>
                        <div>
                            <h3>
                                <i className="fa-regular fa-user"></i>Name
                            </h3>
                            <p>{formData && formData.name}</p>
                        </div>
                        <div>
                            <h3>
                                <i className="fa-regular fa-envelope"></i>Email
                            </h3>
                            <p>{formData && formData.email}</p>
                        </div>
                        <div>
                            <h3>
                                <i className="fa-solid fa-arrows-to-eye"></i>Subject
                            </h3>
                            <p>{formData && formData.subject}</p>
                        </div>
                        <div>
                            <h3>
                                <i className="fa-solid fa-message"></i>Message
                            </h3>
                            <p style={{ height: "6vmax", overflowY: "auto" }}>{formData && formData.message}</p>
                        </div>
                        <div>
                            <p className="fixDate"><i>{formData && formData.createdAt.slice(0, 10)}</i></p>

                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
export default FormDetails