import "./gallery.css"

import Img1 from "../images/gallery/hospital.png"
import Img2 from "../images/gallery/injury.png"
import Img3 from "../images/gallery/walking-aid.png"
import Img4 from "../images/gallery/prothesis.png"
import Img5 from "../images/gallery/wheelchair.png"
import Img10 from "../images/gallery/graduating-student.png"
import Img7 from "../images/gallery/skill-development.png"
import Img8 from "../images/gallery/philanthropy.png"
import Img9 from "../images/gallery/professional-development.png"
import Img11 from "../images/gallery/public-relation.png"
import Img12 from "../images/gallery/fundraiser icon.png"



import School1 from "../images/gallery/school 1.jpg"
import School2 from "../images/gallery/school 2.jpg"
import School3 from "../images/gallery/school 3.jpg"
import School4 from "../images/gallery/school 4.jpg"
import School5 from "../images/gallery/school 5.jpg"
import School6 from "../images/gallery/school 6.jpg"
import School7 from "../images/gallery/school 7.jpg"
import School8 from "../images/gallery/school 8.jpg"
import School9 from "../images/gallery/school 9.jpg"
import School10 from "../images/gallery/school 10.jpg"


import Hospital1 from "../images/gallery/hospital 1.jpg"
import Hospital2 from "../images/gallery/hospital 2.jpg"
import Hospital3 from "../images/gallery/hospital 3.jpg"
import Hospital4 from "../images/gallery/hospital 4.jpg"
import Hospital5 from "../images/gallery/hospital 5.jpg"
import Hospital6 from "../images/gallery/hospital 6.jpg"
import Hospital7 from "../images/gallery/hospital 7.jpg"

import Opd1 from "../images/gallery/opd 1.jpg"
import Opd2 from "../images/gallery/opd 2.jpg"
import Opd3 from "../images/gallery/opd 3.jpg"
import Opd4 from "../images/gallery/opd 4.jpg"
import Opd6 from "../images/gallery/opd 6.jpg"
import Opd7 from "../images/gallery/opd 7.jpg"
import Opd8 from "../images/gallery/opd 8.jpg"
import Opd9 from "../images/gallery/opd 9.jpg"
import Opd10 from "../images/gallery/opd 10.jpg"

import OP1 from "../images/gallery/op 1.jpg"
import OP2 from "../images/gallery/op 2.jpg"
import OP3 from "../images/gallery/op 3.jpg"
import OP4 from "../images/gallery/op 4.jpg"
import OP5 from "../images/gallery/op 5.jpg"

import pc1 from "../images/gallery/pc1.jpg"
import pc2 from "../images/gallery/pc2.jpg"
import pc3 from "../images/gallery/pc3.jpg"
import pc4 from "../images/gallery/pc4.jpg"
import pc5 from "../images/gallery/pc5.jpg"
import pc6 from "../images/gallery/pc6.jpg"

import co1 from "../images/gallery/co1.jpg"
import co2 from "../images/gallery/co2.jpg"
import co3 from "../images/gallery/co3.jpg"
import co4 from "../images/gallery/co4.jpg"
import co5 from "../images/gallery/co5.jpg"
import co6 from "../images/gallery/co6.jpg"
import co7 from "../images/gallery/co7.jpg"
import co8 from "../images/gallery/co8.jpg"
import co9 from "../images/gallery/co9.jpg"
import co10 from "../images/gallery/co10.jpg"


import ot1 from "../images/gallery/ot1.jpg"
import ot2 from "../images/gallery/ot2.jpg"
import ot3 from "../images/gallery/ot3.jpg"
import ot4 from "../images/gallery/ot4.jpg"
import ot5 from "../images/gallery/ot5.jpg"
import ot6 from "../images/gallery/ot6.jpg"
import ot7 from "../images/gallery/ot7.jpg"
import ot8 from "../images/gallery/ot8.jpg"
import ot9 from "../images/gallery/ot9.jpg"
import ot10 from "../images/gallery/ot10.jpg"

import sd1 from "../images/gallery/sd1.jpg"
import sd2 from "../images/gallery/sd2.jpg"
import sd3 from "../images/gallery/sd3.jpg"
import sd4 from "../images/gallery/sd4.jpg"
import sd6 from "../images/gallery/sd6.jpg"
import sd7 from "../images/gallery/sd7.jpg"
import sd8 from "../images/gallery/sd8.jpg"
import sd9 from "../images/gallery/sd9.jpg"
import sd10 from "../images/gallery/sd10.jpg"

import cb1 from "../images/gallery/cb1.jpg"
import cb2 from "../images/gallery/cb2.jpg"
import cb3 from "../images/gallery/cb3.jpg"
import cb4 from "../images/gallery/cb4.jpg"
import cb5 from "../images/gallery/cb5.jpg"


import vrc1 from "../images/gallery/vrc1.jpg"
import vrc2 from "../images/gallery/vrc2.jpg"
import vrc3 from "../images/gallery/vrc3.jpg"
import vrc4 from "../images/gallery/vrc4.jpg"
import vrc5 from "../images/gallery/vrc5.jpg"
import vrc6 from "../images/gallery/vrc6.jpg"
import vrc7 from "../images/gallery/vrc7.jpg"
import vrc8 from "../images/gallery/vrc8.jpg"
import vrc9 from "../images/gallery/vrc9.jpg"


import FundImg1 from "../images/gallery/hund raiser 1.jpg"
import FundImg2 from "../images/gallery/hund raiser 2.jpg"
import FundImg3 from "../images/gallery/hund raiser 3.jpg"
import FundImg4 from "../images/gallery/hund raiser 4.jpg"
import FundImg5 from "../images/gallery/hund raiser 5.jpg"
import FundImg6 from "../images/gallery/hund raiser 6.jpg"
import FundImg7 from "../images/gallery/hund raiser 7.jpg"
import FundImg8 from "../images/gallery/hund raiser 8.jpg"
import FundImg9 from "../images/gallery/hund raiser 9.jpg"
import FundImg10 from "../images/gallery/hund raiser 10.jpg"




import prImg1 from "../images/gallery/prImg1.jpg"
import prImg2 from "../images/gallery/prImg2.jpg"
import prImg3 from "../images/gallery/prImg3.jpg"







import { useState } from "react"

import { useEffect } from "react"

const Gallery = () => {


    useEffect(() => {
        const gallery = document.getElementById("gallery");
        gallery.classList.add("active-page");
        document.title="Gallery"
        // Cleanup function
        return () => {
            gallery.classList.remove("active-page");
        };
    }, []);



    const [tab, setTab] = useState(1)




    const TabHandler = (i) => {
        setTab(() => i)
    }


    return (
        <>
            <section className="gallery">
                <div className="maxwidth flex column">
                    <h2>Our Gallery</h2>
                    <div className="gallery-tab flex">
                        <div className="gallery-tab-button">
                            <a href="#HighSchool" className={`flex ${tab === 1 ? "active-gallery-button" : ""}`} onClick={() => TabHandler(1)}>
                                <div className="flex"><img src={Img1} alt="" /></div>
                                <span>PSRD Hospital</span>
                            </a>
                            <a href="#HighSchool" className={`flex ${tab === 2 ? "active-gallery-button" : ""}`} onClick={() => TabHandler(2)}>
                                <div className="flex"><img src={Img2} alt="" /></div>
                                <span>OPD Department</span>
                            </a>
                            <a href="#HighSchool" className={`flex ${tab === 3 ? "active-gallery-button" : ""}`} onClick={() => TabHandler(3)}>
                                <div className="flex"><img src={Img4} alt="" /></div>
                                <span>OP Centre</span>
                            </a>
                            <a href="#HighSchool" className={`flex ${tab === 4 ? "active-gallery-button" : ""}`} onClick={() => TabHandler(4)}>
                                <div className="flex"><img src={Img3} alt="" /></div>
                                <span>Pysiotherapy Centre</span>
                            </a>
                            <a href="#HighSchool" className={`flex ${tab === 5 ? "active-gallery-button" : ""}`} onClick={() => TabHandler(5)}>
                                <div className="flex"><img src={Img5} alt="" /></div>
                                <span>High School</span>
                            </a>
                            <a href="#HighSchool" className={`flex ${tab === 6 ? "active-gallery-button" : ""}`} onClick={() => TabHandler(6)}>
                                <div className="flex"><img src={Img10} alt="" /></div>
                                <span>PSRD College</span>
                            </a>
                            <a href="#HighSchool" className={`flex ${tab === 10 ? "active-gallery-button" : ""}`} onClick={() => TabHandler(10)}>
                                <div className="flex"><img src={Img8} alt="" /></div>
                                <span>CBR</span>
                            </a>
                            <a href="#HighSchool" className={`flex ${tab === 7 ? "active-gallery-button" : ""}`} onClick={() => TabHandler(7)}>
                                <div className="flex"><img src={Img1} alt="" /></div>
                                <span>OT & SLP</span>
                            </a>
                            <a href="#HighSchool" className={`flex ${tab === 8 ? "active-gallery-button" : ""}`} onClick={() => TabHandler(8)}>
                                <div className="flex"><img src={Img7} alt="" /></div>
                                <span>Skill Development</span>
                            </a>
                            <a href="#HighSchool" className={`flex ${tab === 9 ? "active-gallery-button" : ""}`} onClick={() => TabHandler(9)}>
                                <div className="flex"><img src={Img9} alt="" /></div>
                                <span>VRC</span>
                            </a>
                            
                            <a href="#HighSchool" className={`flex ${tab === 11 ? "active-gallery-button" : ""}`} onClick={() => TabHandler(11)}>
                                <div className="flex"><img src={Img11} alt="" /></div>
                                <span>Advocacy & PR</span>
                            </a>
                            <a href="#HighSchool" className={`flex ${tab === 12 ? "active-gallery-button" : ""}`} onClick={() => TabHandler(12)}>
                                <div className="flex"><img src={Img12} alt="" /></div>
                                <span>Fundraiser Event</span>
                            </a>
                        </div>
                        <div className={`gallery-tab-content flex ${tab === 1 ? "active-gallery-tab" : ""}`}>
                            <div>
                                <img SRC={Hospital1} alt="" />
                            </div>
                            <div>
                                <img SRC={Hospital2} alt="" />
                            </div>
                            <div>
                                <img SRC={Hospital3} alt="" />
                            </div>
                            <div>
                                <img SRC={Hospital4} alt="" />
                            </div>
                            <div>
                                <img SRC={Hospital5} alt="" />
                            </div>
                            <div>
                                <img SRC={Hospital6} alt="" />
                            </div>
                            <div>
                                <img SRC={Hospital7} alt="" />
                            </div>
                        </div>
                        <div className={`gallery-tab-content flex ${tab === 2 ? "active-gallery-tab" : ""}`}>
                            <div>
                                <img SRC={Opd1} alt="" />
                            </div>
                            <div>
                                <img SRC={Opd2} alt="" />
                            </div>
                            <div>
                                <img SRC={Opd3} alt="" />
                            </div>
                            <div>
                                <img SRC={Opd4} alt="" />
                            </div>
                            <div>
                                <img SRC={Opd6} alt="" />
                            </div>
                            <div>
                                <img SRC={Opd7} alt="" />
                            </div>
                            <div>
                                <img SRC={Opd8} alt="" />
                            </div>
                            <div>
                                <img SRC={Opd9} alt="" />
                            </div>
                            <div>
                                <img SRC={Opd10} alt="" />
                            </div>
                        </div>
                        <div className={`gallery-tab-content flex ${tab === 3 ? "active-gallery-tab" : ""}`}>
                            <div>
                                <img SRC={OP1} alt="" />
                            </div>
                            <div>
                                <img SRC={OP2} alt="" />
                            </div>
                            <div>
                                <img SRC={OP3} alt="" />
                            </div>
                            <div>
                                <img SRC={OP4} alt="" />
                            </div>
                            <div>
                                <img SRC={OP5} alt="" />
                            </div>
                        </div>

                        <div className={`gallery-tab-content flex ${tab === 4 ? "active-gallery-tab" : ""}`}>
                            <div>
                                <img SRC={pc1} alt="" />
                            </div>
                            <div>
                                <img SRC={pc2} alt="" />
                            </div>
                            <div>
                                <img SRC={pc3} alt="" />
                            </div>
                            <div>
                                <img SRC={pc4} alt="" />
                            </div>
                            <div>
                                <img SRC={pc5} alt="" />
                            </div>

                            <div>
                                <img SRC={pc6} alt="" />
                            </div>


                        </div>
                        <div className={`gallery-tab-content flex ${tab === 5 ? "active-gallery-tab" : ""}`}>
                            <div>
                                <img SRC={School1} alt="" />
                            </div>
                            <div>
                                <img SRC={School2} alt="" />
                            </div>
                            <div>
                                <img SRC={School3} alt="" />
                            </div>
                            <div>
                                <img SRC={School4} alt="" />
                            </div>
                            <div>
                                <img SRC={School5} alt="" />
                            </div>
                            <div>
                                <img SRC={School6} alt="" />
                            </div>
                            <div>
                                <img SRC={School7} alt="" />
                            </div>
                            <div>
                                <img SRC={School8} alt="" />
                            </div>
                            <div>
                                <img SRC={School9} alt="" />
                            </div>
                            <div>
                                <img SRC={School10} alt="" />
                            </div>
                        </div>
                        <div className={`gallery-tab-content flex ${tab === 6 ? "active-gallery-tab" : ""}`}>
                            <div>
                                <img SRC={co1} alt="" />
                            </div>
                            <div>
                                <img SRC={co2} alt="" />
                            </div>
                            <div>
                                <img SRC={co3} alt="" />
                            </div>
                            <div>
                                <img SRC={co4} alt="" />
                            </div>
                            <div>
                                <img SRC={co5} alt="" />
                            </div>
                            <div>
                                <img SRC={co6} alt="" />
                            </div>
                            <div>
                                <img SRC={co7} alt="" />
                            </div>

                            <div>
                                <img SRC={co8} alt="" />
                            </div>
                            <div>
                                <img SRC={co9} alt="" />
                            </div>
                            <div>
                                <img SRC={co10} alt="" />
                            </div>
                        </div>
                        <div className={`gallery-tab-content flex ${tab === 7 ? "active-gallery-tab" : ""}`}>
                            <div>
                                <img SRC={sd1} alt="" />
                            </div>
                            <div>
                                <img SRC={sd2} alt="" />
                            </div>
                            <div>
                                <img SRC={sd3} alt="" />
                            </div>
                            <div>
                                <img SRC={sd4} alt="" />
                            </div>
                            <div>
                                <img SRC={sd6} alt="" />
                            </div>
                            <div>
                                <img SRC={sd7} alt="" />
                            </div>
                            <div>
                                <img SRC={sd8} alt="" />
                            </div>
                            <div>
                                <img SRC={sd9} alt="" />
                            </div>
                            <div>
                                <img SRC={sd10} alt="" />
                            </div>
                        </div>
                        <div className={`gallery-tab-content flex ${tab === 8 ? "active-gallery-tab" : ""}`}>
                            <div>
                                <img SRC={cb1} alt="" />
                            </div>
                            <div>
                                <img SRC={cb2} alt="" />
                            </div>
                            <div>
                                <img SRC={cb3} alt="" />
                            </div>
                            <div>
                                <img SRC={cb4} alt="" />
                            </div>
                            <div>
                                <img SRC={cb5} alt="" />
                            </div>

                        </div>
                        <div className={`gallery-tab-content flex ${tab === 9 ? "active-gallery-tab" : ""}`}>
                            <div>
                                <img SRC={vrc1} alt="" />
                            </div>
                            <div>
                                <img SRC={vrc2} alt="" />
                            </div>
                            <div>
                                <img SRC={vrc3} alt="" />
                            </div>
                            <div>
                                <img SRC={vrc4} alt="" />
                            </div>
                            <div>
                                <img SRC={vrc5} alt="" />
                            </div>
                            <div>
                                <img SRC={vrc6} alt="" />
                            </div>
                            <div>
                                <img SRC={vrc7} alt="" />
                            </div>
                            <div>
                                <img SRC={vrc8} alt="" />
                            </div>
                            <div>
                                <img SRC={vrc9} alt="" />
                            </div>
                        </div>
                        <div className={`gallery-tab-content flex ${tab === 10 ? "active-gallery-tab" : ""}`}>
                            <div>
                                <img SRC={ot1} alt="" />
                            </div>
                            <div>
                                <img SRC={ot2} alt="" />
                            </div>
                            <div>
                                <img SRC={ot3} alt="" />
                            </div>
                            <div>
                                <img SRC={ot4} alt="" />
                            </div>
                            <div>
                                <img SRC={ot5} alt="" />
                            </div>
                            <div>
                                <img SRC={ot6} alt="" />
                            </div>
                            <div>
                                <img SRC={ot7} alt="" />
                            </div>
                            <div>
                                <img SRC={ot8} alt="" />
                            </div>
                            <div>
                                <img SRC={ot9} alt="" />
                            </div>
                            <div>
                                <img SRC={ot10} alt="" />
                            </div>
                        </div>
                        <div className={`gallery-tab-content flex ${tab === 11 ? "active-gallery-tab" : ""}`}>

                            <div>
                                <img SRC={prImg1} alt="" />
                            </div>
                            <div>
                                <img SRC={prImg2} alt="" />
                            </div>
                            <div>
                                <img SRC={prImg3} alt="" />
                            </div>

                        </div>
                        <div className={`gallery-tab-content flex ${tab === 12 ? "active-gallery-tab" : ""}`}>
                            <div>
                                <img SRC={FundImg1} alt="" />
                            </div>
                            <div>
                                <img SRC={FundImg2} alt="" />
                            </div>
                            <div>
                                <img SRC={FundImg3} alt="" />
                            </div>
                            <div>
                                <img SRC={FundImg4} alt="" />
                            </div>
                            <div>
                                <img SRC={FundImg5} alt="" />
                            </div>
                            <div>
                                <img SRC={FundImg6} alt="" />
                            </div>
                            <div>
                                <img SRC={FundImg7} alt="" />
                            </div>
                            <div>
                                <img SRC={FundImg8} alt="" />
                            </div>
                            <div>
                                <img SRC={FundImg9} alt="" />
                            </div>
                            <div>
                                <img SRC={FundImg10} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
export default Gallery