

import {
    CREATE_DONATE_REQUEST,
    CREATE_DONATE_SUCCESS,
    CREATE_DONATE_FAIL,

    GET_ALL_DONATE_REQUEST,
    GET_ALL_DONATE_SUCCESS,
    GET_ALL_DONATE_FAIL,

    DELETE_DONATE_REQUEST,
    DELETE_DONATE_SUCCESS,
    DELETE_DONATE_FAIL,

    UPDATE_DONATE_REQUEST,
    UPDATE_DONATE_SUCCESS,
    UPDATE_DONATE_FAIL,

    GET_DONATE_DETAILS_REQUEST,
    GET_DONATE_DETAILS_SUCCESS,
    GET_DONATE_DETAILS_FAIL,

    CLEAR_ERRORS,
}


    from "../constants/DonateFormConstants"
import axios from "axios";


export const DonateCreateForm = (form) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_DONATE_REQUEST });

        const config = { headers: { "content-type": "application/json" } };

        const { data } = await axios.post("/sub/api/v1/donate-create-form", form, config);

        dispatch({
            type: CREATE_DONATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_DONATE_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const DonateGetAllForm = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_DONATE_REQUEST });


        const { data } = await axios.get("/api/v1/donate/get/all/forms");

        dispatch({
            type: GET_ALL_DONATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_ALL_DONATE_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const DonateDeleteForm = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_DONATE_REQUEST });


        const { data } = await axios.delete(`/api/v1/donate/delete/form/${id}`);

        dispatch({
            type: DELETE_DONATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DELETE_DONATE_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const DonateUpdateFormForPsrd = (id, updateData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_DONATE_REQUEST });

        const config = { headers: { "content-type": "application/json" } };

        const { data } = await axios.put(`/sub/api/v1/donate/update/form/${id}`, updateData, config);

        dispatch({
            type: UPDATE_DONATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_DONATE_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const DonategetFormDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_DONATE_DETAILS_REQUEST });


        const { data } = await axios.get(`/sub/api/v1/donate-get-form-details/${id}`);

        dispatch({
            type: GET_DONATE_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_DONATE_DETAILS_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};