
import "./hospital.css"
import doctor from "../../images/departments/doctor checking child-01.png"
import ecg from "../../images/departments/heart-rate-monitor.png"
import bed from "../../images/departments/stretcher.png"
import skewBg from "../../images/message-01.png"

import { useEffect } from "react"


const Hospital = () => {

    useEffect(() => {
        const department = document.getElementById("department");
        department.classList.add("active-page");
        document.title = "PSRD Hospital"
        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);

    return (
        <>
            <section className="skew-style">
                <h2 className="mobile-heading">PSRD <span>Hospital</span> <br /><strong>Achievements: July 2022 - June 2023</strong></h2>
                <img src={skewBg} alt="" className="skew-img" />
                <div className="maxwidth flex column">
                    <div className=" flex heading-skew">
                        <div>
                            <h2>PSRD <span>Hospital</span> <br /><strong>Achievements: July 2022 - June 2023</strong></h2>
                            <p>
                                PSRD dedicates itself to offering a plethora of
                                facilities and services aimed at empowering differently abled individuals to become valuable contributors to society.
                                A cornerstone of these offerings is our one hundred-bed
                                orthopedic hospital, managed by a team of highly qualified and
                                competent orthopedic surgeons who conduct an average of fifteen
                                operations daily.
                            </p>
                        </div>
                        <div>
                            <img src={doctor} alt="" />
                        </div>
                    </div>

                    <p>
                        <br />
                        Within this hospital, major orthopedic procedures, such as hip and knee replacements, non unions, malunions, and various congenital deformities, are expertly handled under the vigilant supervision of our capable staff. Those falling under the E Category receive treatment free of charge. Similarly, individuals in other categories benefit from highly subsidized treatment, tailored to their socio-economic status and ability to cover costs.

                        <br /> <br />
                        Our surgical team collaborates seamlessly with qualified medical officers and skilled paramedical and technical staff. The hospital boasts separate wards for men and women, with a Resident Medical Officer who is provided accommodation within the facility to ensure round-the-clock availability. Additionally, well-furnished private rooms are available for patients, while the hospital’s kitchen ensures that patients and their attendants receive freshly cooked meals at no cost. PSRD remains committed to providing holistic care and support to those in need.</p>
                </div>
            </section>


            <section className="Diagnostics center-text">
                <div className="maxwidth flex">
                    <div className="Diagnostics-block">
                        <div className="flex" style={{ justifyContent: "flex-start" }}>
                            <div><img src={ecg} alt="" /></div>
                            <h5>Diagnostics:</h5>

                        </div>
                        <p>
                            The hospital offers an extensive array
                            of diagnostic facilities, encompassing
                            X-ray and ECG services, along with
                            a well-equipped laboratory. To
                            ensure swift and efficient service for
                            each patient, a clear demarcation
                            is maintained between the services
                            provided to outdoor and indoor
                            patients.</p>
                    </div>
                    <div className="Theatre ">
                        <div className="flex" style={{ justifyContent: "flex-start" }}>
                            <div><img src={bed} alt="" /></div>
                            <h5>Operation Theatre:</h5>

                        </div>
                        <p>The operation theatre is functional
                            six days a week. There are two fully
                            equipped operation theaters with three
                            operating tables with a central oxygen
                            supply.</p>
                    </div>

                </div>

            </section>

            <section className="center-text">
                <div className="maxwidth  latest">
                    <h3>Latest Development:</h3>
                    <p>
                        <br />
                        In the year 2022-23, the PSRD Orthopedic Hospital embarked on several pioneering
                        initiatives. The introduction of arthroscopic surgeries marked a significant advancement.
                        Moreover, patient services were enhanced through the implementation of the Health Card
                        system. To optimize patient flow and elevate the standard of digital documentation for both
                        patients and hospital records, the inaugural phase of the Hospital Management Information
                        System (HMIS) was successfully executed.
                        <br /> <br />
                        On the academic forefront, the hospital actively collaborates with and caters to the clinical
                        requirements of the PSRD College of Rehabilitation Sciences. Within this collaboration, DPT
                        and B.Sc. Occupational Therapy classes are conducted in affiliation with the University of
                        Health Sciences (UHS). Concurrently, the hospital is presently overseeing the progression of
                        the tenth batch of the paramedical training course, affiliated with the Punjab Medical Faculty.
                        These initiatives underscore our commitment to innovation, education, and the continuous
                        enhancement of healthcare services.<br /><br /></p>
                </div>
            </section>
            <section>
                <div className="maxwidth table-header">
                    <h3 style={{ margin: "20px 0", width: "100%", textAlign: "center" }}>Performance Table 2022-23
                        (PSRD Hospital)</h3>
                    <div className="table flex column" style={{ width: "80%", margin: "auto" }}>
                        <div className="table-heading flex">
                            <h5>
                                Operations
                            </h5>
                            <h5>
                                Total
                            </h5>
                            <h5>
                                Free
                            </h5>
                            <h5>
                                Subsidized
                            </h5>

                        </div>
                        <div className="table-content flex">
                            <p>Minor</p>
                            <p>1,017</p>
                            <p>370</p>
                            <p>647</p>

                        </div>
                        <div className="table-content flex">
                            <p>Major</p>
                            <p>156</p>
                            <p>5</p>
                            <p>151</p>

                        </div>
                        <div className="table-content flex">
                            <p>Medium</p>
                            <p>3,377</p>
                            <p>1,194</p>
                            <p>2,183</p>

                        </div>
                        <div className="table-content flex">
                            <p>Total</p>
                            <p>4,550</p>
                            <p>1,569</p>
                            <p>2,981</p>

                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="maxwidth table-header">
                    <div className="table flex column" style={{ width: "80%", margin: "auto" }}>
                        <div className="table-heading flex orange">
                            <h5>
                                Investigation
                            </h5>
                            <h5>
                                Total
                            </h5>
                            <h5>
                                Free
                            </h5>
                            <h5>
                                Subsidized
                            </h5>

                        </div>
                        <div className="table-content flex orange table-content-orange">
                            <p>X-Rays</p>
                            <p>15,742</p>
                            <p>4,603</p>
                            <p>11,139</p>

                        </div>
                        <div className="table-content flex orange table-content-orange">
                            <p>Lab Test</p>
                            <p>6,529</p>
                            <p>17,779</p>
                            <p>4,750</p>

                        </div>
                        <div className="table-content flex orange table-content-orange">
                            <p>E.C.G</p>
                            <p>178</p>
                            <p>54</p>
                            <p>124</p>

                        </div>
                        <div className="table-content flex orange table-content-orange">
                            <p>Injections</p>
                            <p>31</p>
                            <p>28</p>
                            <p>3</p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Hospital