import React, { Fragment, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import "./App.css"
import Header from "./component/header/Header.jsx"
import Footer from './component/footer/footer.jsx'

import Home from "./component/home/Home.jsx"

import History from "./component/about/History/History.jsx"
import PresidentMessage from "./component/about/PresidentMessage/PresidentMessage.jsx"
import BoardOfGovernors from "./component/about/BoardOfGovernors/BoardOfGovernors.jsx"
import DirectorMessage from "./component/about/DirectorMessage/DirectorMessage.jsx"


import A_R_21_22 from "./component/annual-reports/A_R_21_22/A_R_21_22.jsx"
import A_R_22_23 from "./component/annual-reports/A_R_22_23/A_R_22_23.jsx"
import A_R_20_23 from "./component/annual-reports/A_Re_20_23/A_Re_20_23.jsx"





import PsrdHospital from "./component/departments/PSRD hospital/hospital.jsx"
import OutPatient from "./component/departments/OutPatient/OutPatient.jsx"
import PhysiotherapyCentre from "./component/departments/PhysiotherapyCentre/PhysiotherapyCentre.jsx"
import Orthotics from "./component/departments/Orthotics/Orthotics.jsx"
import School from "./component/departments/School/School.jsx"
import OutReachServices from "./component/departments/outReachServices/outReachServices.jsx"
import SkillCentre from "./component/departments/SkillCentre/SkillCentre.jsx"
import College from "./component/departments/College/College.jsx"
import Occupational from "./component/departments/Occupational/Occupationaal.jsx"
import Vocational from "./component/departments/VOCATIONAL/Vocational.jsx"
import Pr_department from "./component/departments/VOCATIPR_DepartmentONAL/PR_Department.jsx"


import DonateNow from "./component/donation/Donate_now/Donate_now.jsx"
import Donation_in_kind from "./component/donation/Donation_in_kind/Donation_in_kind.jsx"
import Doners_list from "./component/donation/Doners_list/Doners_list.jsx"
import Checkout from "./component/donation/Checkout/Checkout.jsx"
import Failure from "./component/donation/Failure/Failure.jsx"
import Success from "./component/donation/Failure/Success.jsx"


import NewsJulySep from "./component/newsletter/NewsJulySep/NewsJulySep.jsx"
import NewsOctDec from "./component/newsletter/NewsOctDec/NewsOctDec.jsx"
import NewsJanJune from "./component/newsletter/newsJanJune/newsJanJune.jsx"
import NewsJanMar from "./component/newsletter/NewsJanMar/NewsJanMar.jsx"



import Gallery from "./component/Gallery/Gallery.jsx"

import ContectUs from "./component/ContectUs/ContectUs.jsx"


import Login from "./component/loginSinup/login/Login"
import EmailVerification from "./component/loginSinup/EmailVerification/EmailVerification.jsx"
import Signup from "./component/loginSinup/signup/SignUp"
import ResetPassword from "./component/loginSinup/ResetPassword/ResetPassword.jsx"



import Dashboard from "./component/adminPanel/dashboard/Dashboard"
import FormDetails from "./component/adminPanel/FormDetails/FormDetails.jsx"
import DonationDetails from "./component/adminPanel/DonationDetails/DonationDetails.jsx"


import PageNotFound from "./component/PageNotFound/PageNotFound.jsx"
import Payment from "./component/payment.jsx"



import "./responsive/tab.css"
import "./responsive/mobile.css"

import AOS from 'aos';
import 'aos/dist/aos.css';
import { loadUser } from "./actions/userAction"

import { useDispatch, useSelector } from "react-redux"
import Loader from "./loader/Loader.jsx"

const App = () => {
    const dispatch = useDispatch()


    const { user } = useSelector((state) => state.user)
    const [loading, setLoading] = useState(true);



    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: "ease-in-out",
            once: true,
        });
    }, [])


    useEffect(() => {

        dispatch(loadUser())

    }, [dispatch])

    useEffect(() => {
        const handleLoad = () => {
          setLoading(false); // Once the document is fully loaded, set loading to false
        };
    
        if (document.readyState === 'complete') {
          setLoading(false); // If the document is already fully loaded, set loading to false immediately
        } else {
          document.addEventListener('readystatechange', handleLoad); // Add event listener for document readystatechange event
        }
    
        // Cleanup function to remove event listener when component unmounts
        return () => {
          document.removeEventListener('readystatechange', handleLoad);
        };
      }, []); // Empty dependency array ensures useEffect runs only once after initial render
    return (
        <Fragment>

            {
                loading ? <Loader />
                    :
                    <BrowserRouter>
                        <Header />
                        <Routes>
                            <Route path='/' element={<Home />} />



                            {/* about */}
                            <Route path='/history' element={<History />} />
                            <Route path='/president/message' element={<PresidentMessage />} />
                            <Route path='/board/of/governors' element={<BoardOfGovernors />} />
                            <Route path='/director/message' element={<DirectorMessage />} />



                            <Route path='/annual-report-2021-2022/' element={<A_R_21_22 />} />
                            <Route path='/annual-report-2022-2023/' element={<A_R_22_23 />} />
                            <Route path='/financial-summary-2020-2023/' element={<A_R_20_23 />} />





                            {/* departments */}
                            <Route path='/psrd-hospital' element={<PsrdHospital />} />
                            <Route path='/our-patient-department-psrd' element={<OutPatient />} />
                            <Route path='/physiotherapy-centre' element={<PhysiotherapyCentre />} />
                            <Route path='/orthotic-and-prosthetic-centre/' element={<Orthotics />} />
                            <Route path='/psrd-high-school' element={<School />} />
                            <Route path='/psrd-college-of-rehabilitation-sciences/' element={<College />} />
                            <Route path='/community-based-rehabilitation-outreach-services/' element={<OutReachServices />} />
                            <Route path='/skill-development-centre/' element={<SkillCentre />} />
                            <Route path='/occupational-therapy-and-speech-therapy/' element={<Occupational />} />
                            <Route path='/vocational-rehabilitation-centre/' element={<Vocational />} />
                            <Route path='/advocacy-and-public-relations-unit/' element={<Pr_department />} />



                            {/* donate */}
                            <Route path='/donate-now/' element={<DonateNow />} />
                            <Route path='/donation-category' element={<Donation_in_kind />} />
                            <Route path='/donors-list/' element={<Doners_list />} />
                            <Route path="/payment/" element={<Payment />} />
                            <Route path="/checkout/:id" element={<Checkout />} />
                            <Route path="/failure" element={<Failure />} />
                            <Route path="/success" element={<Success />} />


                            <Route path="/newsletter-july-to-sept-2023/" element={<NewsJulySep />} />
                            <Route path="/newsletter-Oct-to-Dec-2023/" element={<NewsOctDec />} />
                            <Route path="/newsletter-Jan-to-June-2023/" element={<NewsJanJune />} />
                            <Route path="/newsletter-Jan-to-mar-2024/" element={<NewsJanMar />} />




                            <Route path='/gallery/' element={<Gallery />} />



                            <Route path='/contact-us' element={<ContectUs />} />



                            <Route path='/admin-panel/login-psrd' element={<Login />} />
                            <Route path='/admin-panel/register-psrd' element={<Signup />} />
                            <Route path='/admin-panel/dashboard' element={
                                user && user.role === "admin" ? <Dashboard /> : <PageNotFound />
                            } />
                            <Route path='/admin-panel/form-details/:id' element={
                                user && user.role === "admin" ? <FormDetails /> : <PageNotFound />
                            } />
                            <Route path='/admin-panel/Donation-details/:id' element={
                                user && user.role === "admin" ? <DonationDetails /> : <PageNotFound />
                            } />
                            <Route path='/admin-panel/email-verification' element={<EmailVerification />} />
                            <Route path='/admin-panel/reset-password/:id' element={<ResetPassword />} />





                            <Route path='/*' element={<PageNotFound />} />
                        </Routes>
                        <Footer />
                    </BrowserRouter>
            }
        </Fragment>


    )
}

export default App