import { useEffect } from "react"
import "./PageNotFound.css"



const PageNotFound=()=>{
    useEffect(()=>{
        document.title="Page not Found"
        
    },[])
    return (
        <>
        <section className="PageNotFound">
            <div className="maxwidth flex column">
                <h2>
                    Oops<span>!</span>
                </h2>
                <h3>404-Page not found</h3>
                <p>
                    The page you are looking for is not found
                </p>
                <a href="/" className="button3 button2">
                    Home
                </a>
            </div>
        </section>
        </>
    )
}
export default PageNotFound
