

import "./Doners_list.css"


import Img1 from "../../images/donation/corporate doners.png"
import Img2 from "../../images/donation/individual doners.png"
import { useEffect, useState } from "react"

const Doners_list = () => {

    const [index, setIndex] = useState(3)
    const [selectedButtton, setselectedButtton] = useState("July 2022 - June 2023")


    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };
    const togglePage = (i, value) => {
        setIndex(i)
        setselectedButtton(value)
    }


    useEffect(() => {
        const department = document.getElementById("donate");
        department.classList.add("active-page");
        document.title = "Donors List"
        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (showDropdown) {
                setShowDropdown(false)
            }
        }, 10000);
    }, [showDropdown]);
    return (



        <>

            <section className="  excel-file">
                <h2>PSRD <span>Donors</span></h2>
                <div className="dropes-down  maxwidth">

                    <div className="flex">
                        <div className="flex " onClick={toggleDropdown}><span>{selectedButtton}</span>
                            <i className="fa-solid fa-caret-down"></i>
                            {showDropdown && (
                                <div className="three-button flex column">
                                    <button className="button1 button2 excel" onClick={() => togglePage(2, "July 2021- June 2022")} style={{ display: `${index === 2 ? "none" : "block"}`, marginBottom: '0.4vmax' }}>July 2021- JUNE 2022</button>
                                    <button className="button1 button2 excel" onClick={() => togglePage(3, "July 2022 - June 2023")} style={{ display: `${index === 3 ? "none" : "block"}` }}>July 2022 - JUNE 2023</button>



                                </div>

                            )}
                        </div>
                    </div>
                </div>
                <div className={`flex  maxwidth season2022 year-selector`} style={{ display: `${index === 2 ? "flex" : "none"}` }}>


                    <div className="Corporate-donors">


                        <div className="donors-list-heading flex">
                            <img src={Img1} alt="" />
                            <h3>Corporate <br />donors</h3>

                        </div>

                        <div className="flex list_content_block list_content_block_blue">
                            <p>A.S. Publishers</p>
                            <p>ADK Enterprises Limited</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p>Camara Chica</p>
                            <p>Deens Sanitary Store</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p>A.S. Publishers</p>
                            <p>ADK Enterprises Limited</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p>Camara Chica</p>
                            <p>Deens Sanitary Store</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p>A.S. Publishers</p>
                            <p>ADK Enterprises Limited</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p>Camara Chica</p>
                            <p>Deens Sanitary Store</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p>A.S. Publishers</p>
                            <p>ADK Enterprises Limited</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p>Camara Chica</p>
                            <p>Deens Sanitary Store</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p>A.S. Publishers</p>
                            <p>ADK Enterprises Limited</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p>Camara Chica</p>
                            <p>Deens Sanitary Store</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p>A.S. Publishers</p>
                            <p>ADK Enterprises Limited</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p>Camara Chica</p>
                            <p>Deens Sanitary Store</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p>A.S. Publishers</p>
                            <p>ADK Enterprises Limited</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p>Camara Chica</p>
                            <p>Deens Sanitary Store</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p>A.S. Publishers</p>
                            <p>ADK Enterprises Limited</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p>Camara Chica</p>
                            <p>Deens Sanitary Store</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p>A.S. Publishers</p>
                            <p>ADK Enterprises Limited</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p>Camara Chica</p>
                            <p>Deens Sanitary Store</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p>A.S. Publishers</p>
                            <p>ADK Enterprises Limited</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p>Camara Chica</p>
                            <p>Deens Sanitary Store</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p>A.S. Publishers</p>
                            <p>ADK Enterprises Limited</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p>Camara Chica</p>
                            <p>Deens Sanitary Store</p>
                        </div>


                    </div>



                    <div className="induaval-donors">


                        <div className="donors-list-heading flex">
                            <img src={Img2} alt="" style={{ width: "6.6vmax" }} />
                            <h3 style={{ color: "var(--purple)" }}>individual <br />donors</h3>

                        </div>

                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abid Aziz</p>
                            <p>Abid Wazi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Abu Bauer Saleems</p>
                            <p>Adeel Haide</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abid Aziz</p>
                            <p>Abid Wazi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Abu Bauer Saleems</p>
                            <p>Adeel Haide</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abid Aziz</p>
                            <p>Abid Wazi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Abu Bauer Saleems</p>
                            <p>Adeel Haide</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abid Aziz</p>
                            <p>Abid Wazi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Abu Bauer Saleems</p>
                            <p>Adeel Haide</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abid Aziz</p>
                            <p>Abid Wazi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Abu Bauer Saleems</p>
                            <p>Adeel Haide</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abid Aziz</p>
                            <p>Abid Wazi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Abu Bauer Saleems</p>
                            <p>Adeel Haide</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abid Aziz</p>
                            <p>Abid Wazi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Abu Bauer Saleems</p>
                            <p>Adeel Haide</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abid Aziz</p>
                            <p>Abid Wazi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Abu Bauer Saleems</p>
                            <p>Adeel Haide</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abid Aziz</p>
                            <p>Abid Wazi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Abu Bauer Saleems</p>
                            <p>Adeel Haide</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abid Aziz</p>
                            <p>Abid Wazi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Abu Bauer Saleems</p>
                            <p>Adeel Haide</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abid Aziz</p>
                            <p>Abid Wazi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Abu Bauer Saleems</p>
                            <p>Adeel Haide</p>
                        </div>

                    </div>
                </div>
                <div className={`flex  maxwidth season2023 year-selector`} style={{ display: `${index === 3 ? "flex" : "none"}` }}>


                    <div className="Corporate-donors">


                        <div className="donors-list-heading flex">
                            <img src={Img1} alt="" />
                            <h3>Corporate <br />donors</h3>

                        </div>

                        <div className="flex list_content_block list_content_block_blue">
                            <p>A.S. Publishers</p>
                            <p>ADK Enterprises Limited</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p>AEO Pakistan</p>
                            <p>Camara Chica</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p> Deens Sanitary Store</p>
                            <p> Director General Health Services</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p> Hanif Fabrics</p>
                            <p> House of Juniors School Pvt Ltd.</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p> Lahore Grammar School </p>
                            <p> Lahore School of Economics</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p> M. Naeem Goods Transport Company</p>
                            <p> Mannan Shahid Salamat Waqf</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p> MTR Enterprise</p>
                            <p>Mughals Pakistan Pvt Ltd</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p> National Welfare Organization
                            </p>
                            <p> Pak Precise Engineering Pvt Ltd</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p> Seedcred Financial Services Ltd.</p>
                            <p> Servis Foundation</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p> Shabbir Medical Store</p>
                            <p> Shahi Sweets</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p>Shahtaj Sugar Mills Ltd.</p>
                            <p>Smash Feeds</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_blue">
                            <p> Starlet International</p>
                            <p> Toyota Ravi Motors</p>
                        </div>
                        <div className="flex list_content_block list_content_block_blue">
                            <p> Waheed Sons</p>
                            <p> Zaman Foundation</p>
                        </div>


                    </div>



                    <div className="induaval-donors">


                        <div className="donors-list-heading flex">
                            <img src={Img2} alt="" style={{ width: "6.6vmax" }} />
                            <h3 style={{ color: "var(--purple)" }}>individual <br />donors</h3>

                        </div>

                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abdul Majed Arshad</p>
                            <p>Abdul Majeed Butt</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p> Abdul Rehman</p>
                            <p>Abdul Rehman Sail</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p> Abdul Wahab</p>
                            <p> Abdullah Butt</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Abid Aziz</p>
                            <p> Abid Wazir</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Abida Rasheed
                            </p>
                            <p> Abu Bauer Saleem</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p> Adeel Haider</p>
                            <p> Adil Nawaz Khan
                            </p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p> Ahmad
                            </p>
                            <p> Ahmad Hassan
                            </p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p> Ahmad Siddiqi</p>
                            <p> Ahmad Tasneem</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Ajmal</p>
                            <p> Aleemud Din</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p> Ali Haroon
                            </p>
                            <p> Ali Khalil</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p> Alina & Parveen Malik</p>
                            <p>Amber Daniyal</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Amjad</p>
                            <p>Aleemud Din</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Ali Haroon</p>
                            <p>Ali Khalil</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Alina & Parveen Malik</p>
                            <p>Amber Daniyal</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Amjad</p>
                            <p>Andleeb Jalal</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Aneela Imran</p>
                            <p>Anees ur Rehman Baray</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Aniqa Khan</p>
                            <p>Aqeela Saeed Shamsi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Arfa Younus</p>
                            <p>Arifa Saboohi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Arooj Hassan</p>
                            <p>Arooze Tahir</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Arqaan</p>
                            <p>Arshad Iqbal</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Arshad Mahmood</p>
                            <p>Asad Siddiqi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Asim Khan</p>
                            <p>Asma Bhatti</p>
                        </div>



                        <div className="flex list_content_block list_content_block_purple">
                            <p>Athar Syed</p>
                            <p>Awais Saeed</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Ayesha Imran</p>
                            <p>Ayesha Inam</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Ayesha Roohi</p>
                            <p>Ayesha Shehzad</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Azam Shareef</p>
                            <p>Azam ul Haq</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Azra Iqbal Khan</p>
                            <p>Azra Parveen</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Babar Mirza</p>
                            <p>Begum Farhat Ali</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Begum Fatima Mirza</p>
                            <p>Bilal Pervaiz</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Binna Saulat</p>
                            <p>Brig. Asad Ali Khan & Asma</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Brig. Mobin Jawwad</p>
                            <p>Bushra Ahmed</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Bushra Yonus</p>
                            <p>Amjad Ali</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Muhammad Bakhtiar</p>
                            <p>Rushida Zareen Yusuf</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Bilal Patel</p>
                            <p>Farooq Chugtai</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Ijaz Ahmad Saud</p>
                            <p>Imran Riaz</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Jameela Shoukat</p>
                            <p>Kamran Majeed & Zeenat</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>S.M. Zubair</p>
                            <p>Sadia Khattak</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Shirin Khan</p>
                            <p>Shoaib</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Tahir Zahid</p>
                            <p>Zareen Zaidi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Effat Alvi</p>
                            <p>Emaan Dada Bhai</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Eram Ali Aziz</p>
                            <p>Faisal Tehseen</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Faiza Gul</p>
                            <p>Farah Ibrahim Ahmad</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Fareeda Tun Nissa</p>
                            <p>Fareeha Nadeem</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Farhan Ashraf Khan</p>
                            <p>Farhan Hassan</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Farhana Tarar</p>
                            <p>Farhat Aleem</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Farhat Zahid</p>
                            <p>Farheen Khalid</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Farid Badar</p>
                            <p>Farida Azad Iqbal</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Fariha Khan</p>
                            <p>Faris</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Farooq Shafiq</p>
                            <p>Farzana Iman Ullah</p>
                        </div>




                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Farzana Mukhtar</p>
                            <p>Fatima Abid</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Fatima Rashid</p>
                            <p>Fatima Usman</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Fatimah Hassan</p>
                            <p>Fauzia Beig</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Fawad Halim</p>
                            <p>Fawad Jahanzeb Saeed</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Fayyaz Ahmad</p>
                            <p>Fazal Ahmed Khan</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Ferhana Qayum Sher</p>
                            <p>Fiaz Ahmad</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Gaitee Ara Siddiqi</p>
                            <p>Gangaram College Students</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Gul Muddaasir</p>
                            <p>Hadi Mubbashir Kazmi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Hafiz Zarrar</p>
                            <p>Haji Mian Safdar Hussain</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Haji Saab</p>
                            <p>Haji Sadiq</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Hameed Asghar</p>
                            <p>Hammad Rashid</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Hamna</p>
                            <p> Hamza Khalil</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Hanna Nasr</p>
                            <p>Haroon Ahmed</p>
                        </div>



                        <div className="flex list_content_block list_content_block_purple">
                            <p>Hassan Ghaffer</p>
                            <p>Hassan Wadood</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Hina, Marva, Amanullah</p>
                            <p>Hospital Medical Store</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Huma Ijaz</p>
                            <p>Ibrahim Ahmad</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Iftikhar Hussain</p>
                            <p>Imtiaz Ahmad</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Iram Gohar</p>
                            <p>Irfan Malik</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Islam Sheikh</p>
                            <p>Jabran</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Jahangir Mirza</p>
                            <p>Jameela Shauqat</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Jamila Nasir Butt</p>
                            <p>Jamshed</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Junaid</p>
                            <p>K.B. Abdul Aziz</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Karamat Ullah Khan Niazi</p>
                            <p>Kashif Parvez</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Kashifa Rahat</p>
                            <p>Khadija Hameed</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Khalid Kazi</p>
                            <p>Khalid Mahmood</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Khalid Nizami</p>
                            <p>Khalid Parvez</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Khateeb Paracha</p>
                            <p>Khurshid Ahmad</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Kishwar Qazi</p>
                            <p>M. Abdul Jabbar</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>M. Ahmad</p>
                            <p>M. Faique Tahir</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>M. Imran Ajmal</p>
                            <p>M. Junaid</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>M. Talha Ihsan</p>
                            <p>M. Tariq Meer</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Madia Usman</p>
                            <p>Mahjabeen Noon</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Maimoon Naseem</p>
                            <p>Majida Mumtaz Hameed</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Malik M Jahangir</p>
                            <p>Malik Turhaib Shafiq</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Mansoor Azam Sultan</p>
                            <p>Mariyam Ali</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Maryam Bilal Patel</p>
                            <p>Maryam Fatimah</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Masoooma</p>
                            <p>Mehreen Amir</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Mehreen Anwar Raja</p>
                            <p>Mehreen Naseem</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Memoona Rafaq Ali</p>
                            <p>Memoona Rafat Ali</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Meraj</p>
                            <p>Mian Ahmad Kamal</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Mian Fazal Rahim</p>
                            <p>Mian Muhammad Shafique</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Mirza Faisal Baig</p>
                            <p>Moeen ud Din</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Mohammad Osman Raie</p>
                            <p>Moiz Farooq</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Moiz Uddin Sahri</p>
                            <p>Mona Tahir, Mussarat Javaid</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Mrs. Aqil</p>
                            <p>Mrs. Bilal</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Mrs. Javaid</p>
                            <p>Muhammad Affan ud Din</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Muhammad Ahmad</p>
                            <p>Muhammad Ashraf</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Muhammad Awais</p>
                            <p>Muhammad Buksh</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Muhammad Fahad Ayub</p>
                            <p>Muhammad Faique Tahir</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Muhammad Fiaz</p>
                            <p>Muhammad Hanif</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Muhammad Imran</p>
                            <p>Muhammad Junaid</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Muhammad Nasir</p>
                            <p>Muhammad Nauman Chughtai</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Muhammad Nauman Zakria</p>
                            <p>Muhammad Parvez Masud</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Muhammad Saad Aftab</p>
                            <p>Muhammad Saeed Shamsi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Muhammad Safdar Malik</p>
                            <p>Muhammad Shafiq</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Muhammad Sharif</p>
                            <p>Muhammad Tariq Meer</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Muhammad Usman</p>
                            <p>Muhammad Zakria</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Muhmmad Ayub</p>
                            <p>Mukarram Hussain</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Mussarat Aslam</p>
                            <p>Naaz Mansha</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Nabeel Afzal</p>
                            <p>Nadeem Shahab</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Nadeem Zaman</p>
                            <p>Naeem ur Rehman Khalid</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Nafay Noor</p>
                            <p>Naheed</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Naheed Fakhar</p>
                            <p>Naheed Parvaiz</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Najeeb ul Qamar</p>
                            <p>Najma Butt</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Namra Hammad</p>
                            <p>Nasreen Shah</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Nausheen Iftikhar Ahmad</p>
                            <p>Nausheen Pirzada</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Naveed Bandiyal</p>
                            <p>Naveed Wasim Yousuf</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Nazia Hammad</p>
                            <p>Nazim Mukaddam</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Nighat Ahmad</p>
                            <p>Nighat Mughal</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Nighat Naseem</p>
                            <p>Nighat Saeed</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Noreen Latif Siddiqi</p>
                            <p>Nuzhat Malik</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Omar Aslam Mullick</p>
                            <p>Owaiz Kazi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Parveen Umar</p>
                            <p>Qurat Malik</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>R.M. Zafaryab Haider</p>
                            <p>Rabia Fida</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Rahat Naseem</p>
                            <p>Rahat Shahid</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Rao Akbar Latif</p>
                            <p>Rashid Hassan</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Razia Naeem</p>
                            <p>Rehan Chohan</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Rehana Hassan</p>
                            <p>Rehana Maroof Baray</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Rehana Mirza</p>
                            <p>Riffat Saeed Khan</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Rizwan</p>
                            <p>Ruby Arshad</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Rukhsana Zeb</p>
                            <p>Ruqaya Raziz</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Saad</p>
                            <p>Saad Khalil</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Saad Khan</p>
                            <p>Saad Umar Aziz</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Saaim Latif Butt</p>
                            <p>Saamia Ahmad</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Saba Farhat</p>
                            <p>Sabeen Pirzada</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Saira Fazli</p>
                            <p>Sajid Zubairi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Sajida Abbas</p>
                            <p>Sajida Jabeen Shah</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Salah ud Din</p>
                            <p>Saleem uz Zaman Adhmi</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Salma</p>
                            <p>Salman Hassan</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Samina Bakhtiar</p>
                            <p>Samina Rehman</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Samina Shahrik</p>
                            <p>Sara Ahmad Vine</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Sara Khan</p>
                            <p>Sarah Ahmad</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Sarfraz Khurshid</p>
                            <p>Sh. Iftikhar Ahmed</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Sh. Khurshid Ahmad</p>
                            <p>Shafaq Ahmad</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Shafeeq Ahmad</p>
                            <p>Shahid Aziz</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Shahid Elahi</p>
                            <p>Shahid Naseer</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Shahnaz Hashmi</p>
                            <p>Shahnaz Peerzada</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Shahnaz Tiwana</p>
                            <p>Shahzad Waheed</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Shaista Kazmi</p>
                            <p>Shama Hassan</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Shamim Mukhtar</p>
                            <p>Shamim Sarwar</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Shanza Ahmed</p>
                            <p>Shaqufta Qadri</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Shaukat Ali</p>
                            <p>Shazia Aqeel</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Shazia Ijaz</p>
                            <p>Sheikh Afzaal Ahmed</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Sheikh Amar Hameed</p>
                            <p>Sheikh Khalil & Sons</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Sheikh Muhammad</p>
                            <p>Sheikh Muhammad Shah</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Sheren Masoud</p>
                            <p>Shireen Abid</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Sitwat Misbah</p>
                            <p>Sohail Munir</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Sohail Shafique</p>
                            <p>Suleman Khurshid</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Sultan Burq</p>
                            <p>Syed Ahmed Masood Pirzada</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Syed Akbar Hussain Jafry</p>
                            <p>Syed Anwar ul Haq</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Syed Fazal Ahmad</p>
                            <p>Syed Jaffar Shah</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Syed Parvaiz Haidar</p>
                            <p>Syeda Yasmin Waqar</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Tabasum Sadat</p>
                            <p>Tahir Latif</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Tahira Yasmeen</p>
                            <p>Tahira Zahid</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Tanweer Zia Kazmi</p>
                            <p>Tariq Liaqat Ali</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Tariq Riaz</p>
                            <p>Tauqir Ahmad</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Tauqir Ahmed Sharifi</p>
                            <p>Tehseen Ahmad</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Usman Aslam Mali</p>
                            <p>Uzma Murad Ashraf</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Uzma Tariq Haroon</p>
                            <p>Viqar Fatima</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Waheed</p>
                            <p>Wajeeha Ishfaq</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Wajid Hussain</p>
                            <p>Waqar Ahmad Mughal</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Waqas Shams</p>
                            <p>Yawar Umar</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Zaheer Ahmed</p>
                            <p>Zahida Feisal Beig</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Zahida Khalid Aslam</p>
                            <p>Zahida Tahir</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Zahra Ashraf</p>
                            <p>Zahra Aslam</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Zainab Saeed</p>
                            <p>Zaki Rehman</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Zeeshan Haider</p>
                            <p>Zenab Qayum Khan</p>
                        </div>
                        <div className="flex list_content_block list_content_block_purple">
                            <p>Zia ul Islam</p>
                            <p>Zubaida</p>
                        </div>
                        <div className="flex list_content_block list_content_block_light_purple">
                            <p>Zubaidah Ijaz Malik</p>
                            <p>Zubyr Soomro</p>
                        </div>



                    </div>
                </div>
            </section>

        </>
    )
}


export default Doners_list