import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DonateUpdateFormForPsrd, DonategetFormDetails } from '../../../actions/DonateFormAction';
import "./success.css"
import { Link, useNavigate } from 'react-router-dom';
import Img from "../../images/checked.png"

const FailurePage = () => {


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [id, setId] = useState()
    const [ErrorCode, setErrorCode] = useState()
    const [total_amount, setTotalAmount] = useState()


    useEffect(() => {
        const url = window.location.href;
        const params = new URLSearchParams(url);
        const basketId = params.get('basket_id');
        const err_code = params.get('err_code');
        const TotalAmount = params.get('merchant_amount');

        setId(basketId);
        setErrorCode(err_code);
        setTotalAmount(TotalAmount);
        const footer = document.querySelector(".totalFooter")
        footer.style.display = "none"
        document.title = "Payment Successfull"
        return () => {
            footer.style.display = "block"
        }
    }, []);



    useEffect(() => {

        const object = {

            paymentStatus: "received"
        };

        // Ensure id is not undefined before dispatching the action
        if (id && ErrorCode === "000") {
            dispatch(DonateUpdateFormForPsrd(id, object));
        }

        console.log(ErrorCode)
        console.log(id)
    }, [id, ErrorCode]); // Add id as a dependency




    return (
        <section className="absolute successPage flex" style={{ background: "lightGreen" }}>
            <div className="flex column">
                <h3 style={{ color: "lightgreen" }}>Payment Successfull</h3>
                <div>
                    <img src={Img} alt="" />
                </div>
                <p>
                    Thank you for your impactful donation<br /> of <span>{total_amount}</span> to the children of PSRD. Your generosity is deeply appreciated.
                </p>
                <Link to={"/"} className='button2' style={{ background: "lightGreen" }}>HOME</Link>
            </div>
        </section>
    );
};

export default FailurePage;
