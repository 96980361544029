
import "./Occupational.css"

import skewBg from "../../images/message-01.png"
import speach1 from "../../images/departments/ot circle-01.png"
import speach4 from "../../images/gallery/speach4.JPG"
import speach5 from "../../images/gallery/speach5.JPG"
import speach2 from "../../images/gallery/speach2.JPG"
import speach3 from "../../images/gallery/speach3.jpg"
import speach6 from "../../images/gallery/speach6.JPG"
import speach7 from "../../images/gallery/speach7.JPG"

import Speech_therepy1 from "../../images/departments/Speech Therapy 1.png"
import Speech_therepy2 from "../../images/departments/Speech Therapy 2.png"
import Speech_therepy3 from "../../images/departments/Speech Therapy 3.png"
import Speech_therep4 from "../../images/departments/Speech Therapy 4.png"
import Speech_therep5 from "../../images/departments/Speech Therapy 5.png"

import SuccessStory from "../../images/departments/Abdulaha.jpg"

import { useEffect } from "react"



const Occupationaal = () => {


    useEffect(() => {
        const department = document.getElementById("department");
        department.classList.add("active-page");
        document.title = "Occupational Therapy & Speeh Therapy"
        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);
    return (
        <>
            <section className="skew-style">
                <h2 className="mobile-heading orthaticesHeading">OCCUPATIONAL THERAPY & <br />
                    SPEECH THERAPY <span>centre</span><br /><strong>Achievements: July 2022 - June 2023</strong></h2>

                <img src={skewBg} alt="" className="skew-img" />
                <div className="maxwidth flex column">
                    <div className=" flex heading-skew">
                        <div>
                            <h2 className="orthaticesHeading">OCCUPATIONAL THERAPY & <br />
                                SPEECH THERAPY <span>centre</span>
                                <br /><strong>Achievements: July 2022 - June 2023</strong>
                            </h2>

                            <h3 style={{ paddingTop: "4vmax" }}>Occupational Therapy</h3>
                            <p style={{ marginTop: "1vmax" }}>
                                This year, our Occupational Therapy (OT) department
                                experienced a notable increase in both patient volume and
                                diversity of cases, catering to various orthopedic, psychiatric,
                                neurological, and systemic disorders. The department achieved one of its highest monthly
                                revenues during morning hours and successfully implemented an OT screening program in the
                                Out Patient Department (OPD). Moreover, an interprofessional initiative with Medical Officers
                                and Surgeons was established to raise awareness and enhance referrals to OT services. The
                                OT Volunteer Program and internships for A-Levels and College students were conducted
                                successfully, contributing to our commitment to education and training.
                            </p>
                        </div>
                        <div>
                            <img src={speach1} alt="" />
                        </div>
                    </div>

                </div>
            </section>

            <section className="occupational">
                <div className="flex column maxwidth">
                    <div className="Therapy flex" style={{ marginTop: "-4vmax", marginBottom: "4vmax" }}>
                        <p>A new “Comprehensive Cognitive
                            Assessments” OT program was introduced,
                            focusing on stroke patients to assess
                            and rule out cognitive deficits. To
                            elevate departmental performance, new
                            documentation standards, goal-setting
                            criteria, and regular documentation checks
                            were implemented. </p>
                        <div><img src={speach2} alt="" /></div>
                    </div>
                    <div className="Therapy Therapy-2 flex" style={{ marginTop: "-4vmax" }}>
                        <div><img src={speach3} alt="" /></div>
                        <p>A workshop on “Working as an
                            Occupational Therapist in Ireland” by
                            Dr. Farhat Aslam proved insightful for
                            our team. The department actively
                            engaged in providing clinical supervision
                            and training to students from PSRD
                            College and Children’s Hospital.</p>
                    </div>
                    <div className="achievements flex">
                        <p>Our achievements extended to securing top positions in OT first, second, and third years
                            at the University of Health Sciences (UHS). We maintained a successful OT evening clinic
                            throughout the year and presented a research article at the International Conference for
                            Uniting Mental Health. Actively participating in professional development opportunities at
                            institutions such as Pakistan Kidney and Liver Institute (PKLI), Children’s Hospital, Fountain
                            House, and Superior University, we ensured continuous growth and learning. <br /> <br />
                            Furthermore, we facilitated clinical rotation opportunities for occupational therapy training
                            at prominent institutions like Shaukat Khanum, Children’s Hospital, and Social Welfare &
                            Bait-ul-Maal. Our collaboration with PSRD High School for wheelchair assessments and
                            provision of students highlighted our commitment to holistic support.
                        </p>
                        <div>
                            <img src={speach7} alt="" />
                        </div>
                    </div>
                </div>
            </section>








            <section className="Complex">
                <div className="maxwidth Afiyat">
                    <div>
                        <p>In advocating for Occupational Therapy, our department conducted talks, workshops, and
                            keynote speeches, representing PSRD OT at Superior University, University of Lahore, CMH
                            Lahore, Sehat Medical Complex, Afiyat Old Age Home and the Suno TV Morning Show. These
                            diverse efforts underscore our dedication to advancing occupational therapy services and
                            education within and beyond our community.</p>
                    </div>
                    <div className="advocating flex">
                        <div ><img src={speach5} alt="" /></div>
                        <div><img src={speach4} alt="" /></div>
                        <div><img src={speach6} alt="" /></div>
                    </div>
                </div>
            </section>


            <section className="speech-therapy">
                <div className="maxwidth">
                    <h3 style={{ marginTop: "4vmax " }}>Speech Therapy</h3>
                    <p style={{
                        paddingRight: "8vmax",
                        letterSpacing: "0.05vmax",
                        wordSpacing: "0.05vmax",
                        marginTop: "1.6vmax",
                        marginBottom: "4vmax"
                    }}>Speech Therapy Department provided Speech therapy services and used various <br />
                        mechanisms to work on the Speech impairments. </p>

                    <div className="flex Speech-Therapy " >
                        <div>
                            <img src={Speech_therepy1} alt="" style={{ width: "9vmax" }} />
                            <p>Addition of sound
                                proof room for hearing
                                impaired patients</p></div>

                        <div className="dark-purple-color">


                            <img src={Speech_therepy3} alt="" style={{ width: "7vmax" }} />
                            <p>Conducted Workshop
                                on Standardized
                                Assessment of Tools
                                of Pediatric Language
                                in collaboration with
                                Superior University</p></div>

                        <div>
                            <img src={Speech_therepy1} alt="" style={{ width: "7vmax" }} />
                            <p>Conducted Clinical
                                Rotation of SLP
                                students at Combined
                                Military Hospital
                                (CMH), Children
                                Hospital and Hamza
                                Foundation</p></div>

                    </div>

                    <div className="flex Speech-Therapy">

                        <div className="dark-purple-color">
                            <img src={Speech_therep4} alt="" style={{ width: "9vmax" }} />

                            <p>Celebrated World
                                Speech Therapy Day in
                                October 2022</p>
                        </div>

                        <div>
                            <img src={Speech_therepy2} alt="" style={{ width: "9vmax" }} />
                            <p>Achieved top three
                                positions in UHS by
                                students of B.Sc (Hons)
                                Speech & Language
                                Pathology</p></div>

                        <div className="dark-purple-color">
                            <img src={Speech_therep5} alt="" style={{ width: "9vmax" }} />

                            <p>Started school-based
                                Speech Therapy
                                programs for
                                individuals and groups</p></div>

                    </div>
                </div>
            </section>
            <section className="oc-success-story">
                <div className="maxwidth">
                    <div className="flex successStory-speech">
                        <div>
                            <h3>Success Story:</h3>
                            <p>Abdullah, a 16-year-old boy, sought assistance from the speech Therapy department,
                                presenting with a concern of a female-like voice (puberphonia). Referred by the ENT
                                Department of Services Hospital, his voice was characterized by breathiness, roughness,
                                and a lack of power, contributing to emotional stress. Upon diagnosis of puberphonia, a
                                tailored regimen of regular voice therapy, including vocal exercises, cough techniques, glottal
                                attack before vowels, effortful closure technique, and adjustments to lower the larynx to
                                an appropriate position, was initiated. Through consistent therapy sessions, significant
                                improvements in his voice quality were observed. Abdullah can now confidently communicate
                                with a low-pitched, masculine voice.</p>
                        </div>
                        <div className="success-story-communityBased">
                            <img src={SuccessStory} alt="" />
                        </div>
                    </div>

                </div>
            </section>



        </>


    )
}
export default Occupationaal