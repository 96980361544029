

import "./AdminDashboard.css"
import Logo from "../../images/psrd.png"



import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GetAllForm, getFormDetails } from "../../../actions/FormAction"
import { DeleteNewsletter, GetAllNewsletter, getNewsletterDetails } from "../../../actions/newsletterAction"
import { logoutUser } from "../../../actions/userAction"
import { useNavigate } from "react-router-dom"
import { DonateGetAllForm } from "../../../actions/DonateFormAction"





const Dashboard = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {

        dispatch(DonateGetAllForm())
        dispatch(GetAllForm())
        dispatch(GetAllNewsletter())

    }, [dispatch])

    const [index, setIndex] = useState(1);
    const [totalViewedNewsletter, settotalViewedNewsletter] = useState(0);
    const [totalViewedForms, settotalViewedForms] = useState(0);
    const [totalViewedDonation, settotalViewedDonation] = useState(0);
    const { formData, totalForm } = useSelector((state) => state.allFormData)
    const { donateData, totalDonations } = useSelector((state) => state.allDonations)
    const { newsletter, success, total } = useSelector((state) => state.newsletter)




    useEffect(() => {
        const header = document.querySelector(".header")
        const header2 = document.querySelector(".header2")
        const footer = document.querySelector(".totalFooter")
        header.style.display = "none"
        header2.style.display = "none"
        footer.style.display = "none"
        document.title = "Dashboard Admin Panel"


        return () => {
            header.style.display = "block"
            header2.style.display = "block"
            footer.style.display = "block"
        }
    }, [])

    useEffect(() => {
        if (newsletter && newsletter.length > 0) {
            const viewedCount = newsletter.reduce((count, form) => form.isViewed ? count + 1 : count, 0);
            settotalViewedNewsletter(viewedCount);
        }
    }, [newsletter]);



    const isViewed = (id) => {
        dispatch(getNewsletterDetails(id))
        dispatch(GetAllNewsletter())
    }
    const deleteNews = (id) => {
        dispatch(DeleteNewsletter(id))
        dispatch(GetAllNewsletter())
    }
    useEffect(() => {
        if (formData) {
            const viewedCount = formData.reduce((count, form) => form.isViewed ? count + 1 : count, 0);
            settotalViewedForms(viewedCount);
        }
        if (donateData) {
            const viewedCount = donateData.reduce((count, form) => form.paymentStatus ==="received"? count + 1 : count, 0);
            settotalViewedForms(viewedCount);
        }
    }, [formData, donateData]);





    const LogoutUser = () => {
        dispatch(logoutUser())
        navigate("/")

    }

    return (
        <>
            <section className="AdminDashboard">
                <div className="fixedDashboard">
                    <div className="logo-dashbord">
                        <div className="log-animate">
                            <img src={Logo} alt="" />
                        </div>
                        <h4 style={{ paddingTop: "2vmax", color: "white" }}>Admin Dashboard</h4>
                    </div>
                    <div>

                        <div className="DashboradButton">
                            <button className={`button2 button3 ${index === 1 ? "AdminDashboardActiveButton" : ""}`} onClick={() => setIndex(1)}><i class="fa-solid fa-chart-line"></i> Donations</button>
                        </div>
                        <div className="DashboradButton">
                            <button className={`button2 button3 ${index === 2 ? "AdminDashboardActiveButton" : ""}`} onClick={() => setIndex(2)}><i class="fa-solid fa-phone"></i> Contact Us</button>
                        </div>
                        <div className="DashboradButton">
                            <button className={`button2 button3 ${index === 3 ? "AdminDashboardActiveButton" : ""}`} onClick={() => setIndex(3)}><i className="fa-solid fa-envelopes-bulk"></i> Newsletters</button>
                        </div>
                        <div className="DashboradButton">
                            <button className="button2 button3" onClick={() => LogoutUser()}> <i class="fa-solid fa-arrow-right-from-bracket"></i> Logout</button>
                        </div>
                    </div>

                </div>
                <div className="dashboardContent" style={{ display: `${index === 1 ? "" : "none"}` }}>
                    <div className="FormCount">
                        <div>
                            <h2>
                                {totalViewedDonation}
                                <i class="fa-solid fa-hand-holding-dollar"></i>
                            </h2>
                            <h3>
                                Donation Received
                            </h3>
                        </div>
                        <div>
                            <h2>
                                {totalDonations ? totalDonations : 0}
                                <i class="fa-solid fa-list-check"></i>
                            </h2>
                            <h3>
                                Total Donations
                            </h3>
                        </div>
                        <div>
                            <h2>
                                {totalDonations ? totalDonations - totalViewedDonation : 0}
                                <i class="fa-solid fa-ban"></i>
                            </h2>
                            <h3>
                                Donation pending
                            </h3>
                        </div>
                    </div>
                    <div className="formData">
                        <div className="formDataHeading">
                            <h3>Name</h3>
                            <h3>Email</h3>
                            <h3>Donation Type</h3>
                            <h3>status</h3>

                        </div>
                        {donateData && donateData.map(data => (
                            <a href={`/admin-panel/Donation-details/${data._id}`} className="formDataContent" key={data.id}>
                                <p><span>{data.name ? data.name.slice(0, 1) : ""}</span>{data.name} </p>
                                <p>{data.email}</p>
                                <p>{data.donationType}</p>
                                <p style={{ color: data.paymentStatus === "received" ? "green" : "red" }}>
                                    {data.paymentStatus}
                                </p>
                            </a>
                        ))}
                        <h3 style={{ display: `${totalForm > 0 ? "none" : "block"}` }}>No submitted forms yet</h3>
                    </div>
                </div>
                <div className="dashboardContent" style={{ display: `${index === 2 ? "" : "none"}` }}>
                    <div className="FormCount">
                        <div>
                            <h2>
                                {totalViewedForms}
                                <i class="fa-solid fa-eye"></i>
                            </h2>
                            <h3>
                                Form viewed
                            </h3>
                        </div>
                        <div>
                            <h2>
                                {totalForm ? totalForm : 0}
                                <i class="fa-solid fa-list-check"></i>
                            </h2>
                            <h3>
                                Total form Submitted
                            </h3>
                        </div>
                        <div>
                            <h2>
                                {totalForm ? totalForm - totalViewedForms : 0}
                                <i class="fa-solid fa-eye-slash"></i>
                            </h2>
                            <h3>
                                Form Not viewed
                            </h3>
                        </div>
                    </div>
                    <div className="formData">
                        <div className="formDataHeading">
                            <h3>Name</h3>
                            <h3>Email</h3>
                            <h3>Subject</h3>
                            <h3>status</h3>

                        </div>
                        {formData && formData.map(data => (
                            <a href={`/admin-panel/form-details/${data._id}`} className="formDataContent" key={data.id}>
                                <p><span>{data.name ? data.name.slice(0, 1) : ""}</span>{data.name} {data.lastName}</p>
                                <p>{data.email}</p>
                                <p>{data.subject}</p>
                                <p><i className="fa-solid fa-eye"
                                    style={{ color: `${data.isViewed ? ("green") : ("red")}` }}></i>
                                </p>
                            </a>
                        ))}
                        <h3 style={{ display: `${totalForm > 0 ? "none" : "block"}` }}>No submitted forms yet</h3>
                    </div>
                </div>
                <div className="dashboardContent" style={{ display: `${index === 3 ? "" : "none"}` }}>
                    <div className="FormCount">
                        <div>
                            <h2>
                                {totalViewedNewsletter}
                                <i class="fa-solid fa-eye"></i>
                            </h2>
                            <h3>
                                Newsletter Viewed
                            </h3>
                        </div>
                        <div>
                            <h2>
                                {total ? total : 0}
                                <i class="fa-solid fa-list-check"></i>
                            </h2>
                            <h3>
                                Total Newsletters
                            </h3>
                        </div>
                        <div>
                            <h2>
                                {total ? { total } - { totalViewedNewsletter } : "0"}
                                <i class="fa-solid fa-eye-slash"></i>
                            </h2>
                            <h3>
                                newsletters Not viewed
                            </h3>
                        </div>
                    </div>
                    <div className="formData">
                        <div className="formDataHeading">
                            <h3>Email</h3>
                            <h3>Date</h3>
                            <h3>status</h3>
                            <h3>Delete</h3>

                        </div>
                        {success && newsletter && newsletter.map(data => (
                            <div className="formDataContent" key={data.id}>
                                <p><span>{data.email ? data.email.slice(0, 1) : ""}</span>{data.email}</p>
                                <p>{data.createdAt ? data.createdAt.slice(0, 10) : ""}</p>
                                <button onClick={() => isViewed(data._id)}><i className="fa-solid fa-eye"
                                    style={{ color: `${data.isViewed ? ("green") : ("red")}` }}></i>
                                </button>
                                <button onClick={() => deleteNews(data._id)}><i className="fa-solid fa-trash"
                                    style={{ color: "black" }}></i>
                                </button>
                            </div>
                        ))}
                        <h3 style={{ display: `${total > 0 ? "none" : "block"}` }}>No Newsletter subscribed Yet</h3>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Dashboard