

import outPtient from "../../images/little handicapped child.JPG"
import "./outPatient.css"
import AssistanceFund from "../../images/departments/out patient department psrd.JPG"
import skewBg from "../../images/message-01.png"
import { useEffect } from "react"




const OutPatient = () => {
    useEffect(() => {
        const department = document.getElementById("department");
        department.classList.add("active-page");
        document.title="Out Patient Department"
        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);
    return (
        <>
            <section className="skew-style outPatient">
            <h2 className="mobile-heading">Out Patient <span>Department</span>
            <br/><strong>Achievements: July 2022 - June 2023</strong>
            </h2>

                <img src={skewBg} alt="" className="skew-img" />
                <div className="maxwidth flex column">
                    <div className="P-M-first-child flex heading-skew outPatient-child-1">
                        <div>
                            <h2>Out Patient <span>Department</span>
                            <br/><strong>Achievements: July 2022 - June 2023</strong>
                            </h2>
                            <div className="flex numbering-heading" style={{ justifyContent: "flex-start" }}>
                                <div className="flex">
                                    1
                                </div>
                                <h3>
                                    Reception and
                                    Registration Section
                                </h3>
                            </div>
                            <p style={{ marginTop: "0" }}>
                                This is the first place where all patients report on arrival and<br />
                                get themselves registered on payment of Rs.50.
                            </p>
                        </div>
                        <div>
                            <img src={outPtient} alt="" />
                        </div>
                    </div>
                    <div className="outpatient-block-2 flex column">
                        <div className="flex numbering-heading" style={{ justifyContent: "flex-start" }}>
                            <div className="flex">
                                2
                            </div>
                            <h3>
                                Assessment Section (Socio-Economic Condition)
                            </h3>
                        </div>
                        <p>
                            The department is managed by two qualified and experienced Social Welfare Officers (SWOs).
                            After registration, all patients come to this section for assessment of their socio-economic
                            status so as to determine their paying capacity. Based on their circumstances the patient may be
                            placed anywhere in one of the five categories A to E. A minimum of 20 percent of the patients
                            are placed in E Category, who are treated totally free. While those in Category D and C enjoy
                            concessions of 75 percent and 50 percent, respectively.
                        </p>
                        <div className="table flex column">
                            <div className="table-heading flex">
                                <h5>
                                    CATEGORY A
                                </h5>
                                <h5>
                                    CATEGORY B
                                </h5>
                                <h5>
                                    CATEGORY C
                                </h5>
                                <h5>
                                    CATEGORY D
                                </h5>
                                <h5>
                                    CATEGORY E
                                </h5>
                            </div>
                            <div className="table-content flex">
                                <p>1,608</p>
                                <p>608</p>
                                <p>11,166</p>
                                <p>10,093</p>
                                <p>5,822</p>
                            </div>
                        </div>
                        <div className="lineHeading flex" style={{ justifyContent: "space-between", width: "100%" }}>
                            <div style={{ width: "85%", height: "0.2vmax", backgroundColor: "var(--blue)" }} >
                            </div>
                            <h5>
                                TOTAL: 29,297
                            </h5>
                        </div>
                    </div>
                </div>
            </section>
            <section className="assistance-fund">
                <div className="maxwidth">
                    <div className="assistance-fund-block-1 flex">
                        <div>
                            <h3>Assistance Fund</h3>
                            <p>The sub-committee unanimously
                                approved that medicines will be
                                provided at substantially subsidized
                                rates to deserving patients.
                                However, the most needy patients
                                are provided medicine free of cost as
                                determined by the SWO.</p>
                        </div>
                        <div>
                            <img src={AssistanceFund} alt="" />
                        </div>
                    </div>

                </div>
            </section>
            <section className="OutPatient-3">
                <div className="maxwidth">
                    <div style={{ padding: "2.4vmax", margin: "2.4vmax 0", backgroundColor: "var(--yellow)" }}>
                        <p>
                            <span>Counseling and Guidance</span><br />
                            SWOs offer moral and psychological guidance to patients to resolve their problems.
                            Patients are actively encouraged to pursue employment to support their families.
                            Those with the potential to initiate small businesses are referred to the Vocational
                            Rehabilitation Centre, while individuals with minor ailments are advised to consider
                            joining vocational training, including computer training.
                        </p>
                    </div>
                    <div style={{ padding: "2.4vmax", margin: "2.4vmax 0", backgroundColor: "var(--blue)" }}>
                        <p>
                            <span>Home Visits</span><br />
                            Sometimes, it becomes necessary to visit the homes of patients to assess their
                            socio-economic status. SWOs undertake these visits to validate and gather accurate
                            information about the patients.
                        </p>
                    </div>
                    <div className="studentTraining flex">
                        <h3>
                            Student <br />
                            Training
                        </h3>
                        <p>
                            Each year, the University of Punjab, Lahore College for Women, and
                            Government Islamia College for Women, Cooper Road Lahore send their
                            students from the Social Work department to PSRD for fieldwork training.
                            SWOs provide training, particularly in case-studies, to enhance their
                            practical skills. Additionally, final-year students from the PSRD College
                            receive lectures, further enriching their educational experience.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}
export default OutPatient