

import {
    CREATE_FORM_REQUEST,
    CREATE_FORM_SUCCESS,
    CREATE_FORM_FAIL,

    GET_ALL_FORMS_REQUEST,
    GET_ALL_FORMS_SUCCESS,
    GET_ALL_FORMS_FAIL,

    DELETE_FORM_REQUEST,
    DELETE_FORM_SUCCESS,
    DELETE_FORM_FAIL,

    UPDATE_FORM_REQUEST,
    UPDATE_FORM_SUCCESS,
    UPDATE_FORM_FAIL,

    GET_FORM_DETAILS_REQUEST,
    GET_FORM_DETAILS_SUCCESS,
    GET_FORM_DETAILS_FAIL,

    CLEAR_ERRORS,
}


from "../constants/FormConstants"
import axios from "axios";


export const CreateForm = (form) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_FORM_REQUEST });

        const config = { headers: { "content-type": "application/json" } };

        const { data } = await axios.post("/sub/api/v1/create-form", form, config);

        dispatch({
            type: CREATE_FORM_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: CREATE_FORM_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const GetAllForm = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_FORMS_REQUEST });


        const { data } = await axios.get("/api/v1/get/all/forms");

        dispatch({
            type: GET_ALL_FORMS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_ALL_FORMS_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const DeleteForm = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_FORM_REQUEST });


        const { data } = await axios.delete(`/api/v1/delete/form/${id}`);

        dispatch({
            type: DELETE_FORM_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DELETE_FORM_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const UpdateForm = (id, data) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_FORM_REQUEST });

        const config = { headers: { "content-type": "application/json" } };

        const { data } = await axios.post(`/api/v1/update/form/${id}`, data, config);

        dispatch({
            type: UPDATE_FORM_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_FORM_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};
export const getFormDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_FORM_DETAILS_REQUEST });

        const config = { headers: { "content-type": "application/json" } };

        const { data } = await axios.get(`/api/v1/get/form/details/${id}`);

        dispatch({
            type: GET_FORM_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: GET_FORM_DETAILS_FAIL,
            payload: error.response.data.message, // This should now contain the specific error message
        });
    }
};