import React, { useEffect, useState } from 'react';
import axios from 'axios';

const App = () => {
  const [formData, setFormData] = useState({
    CURRENCY_CODE: 'PKR',
    MERCHANT_ID: '21257',
    MERCHANT_NAME: "Ahmad Aziz",
    PROCCODE: "00",
    TXNAMT: 101,
    SECURED_KEY: '5nPVBNRB8jH3dPbFLuUBen2AZ8',
    BASKET_ID: 'ITEM-001',
    CUSTOMER_MOBILE_NO: "03484828702",
    CUSTOMER_EMAIL_ADDRESS: "ahmad@gmail.com",
    SIGNATURE: "donation",
    VERSION: "zakat",
    TXNDESC: "sadqat",
    ORDER_DATE: "2024-4-25",
    SUCCESS_URL: "http://localhost:3000/success",
    FAILURE_URL: "http://localhost:3000/failure",
    CHECKOUT_URL: "http://localhost:300/checkout"
  });
  const [token, setToken] = useState()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('http://localhost:4000/api/v1/pay', formData);
        setFormData({ ...formData, TOKEN: response.data });
        setToken(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    <div>
      <h2>PayFast Example Payment Form</h2>
      <form id='PayFast_payment_form' name='PayFast-payment-form' method='post' action="https://ipguat.apps.net.pk/Ecommerce/api/Transaction/PostTransaction">

        Currency Code:
        <input type="TEXT" name="CURRENCY_CODE" value={formData.CURRENCY_CODE} onChange={handleChange} />
        <br />
        Merchant ID:
        <input type="TEXT" name="MERCHANT_ID" value={formData.MERCHANT_ID} onChange={handleChange} />
        <br />
        Merchant Name:
        <input type="TEXT" name="MERCHANT_NAME" value={formData.MERCHANT_NAME} onChange={handleChange} />
        <br />
        Token:
        <input type="TEXT" name="TOKEN" value={token} onChange={handleChange} /><br />
        Success URL: <input type="TEXT" name="SUCCESS_URL" value={formData.SUCCESS_URL} onChange={handleChange} />
        <br />
        Failure URL:
        <input type="TEXT" name="FAILURE_URL" value={formData.FAILURE_URL} onChange={handleChange} />
        <br />
        Checkout URL:
        <input type="TEXT" name="CHECKOUT_URL" value={formData.CHECKOUT_URL} onChange={handleChange} />
        <br />
        Customer Email:
        <input type="TEXT" name="CUSTOMER_EMAIL_ADDRESS" value={formData.CUSTOMER_EMAIL_ADDRESS} onChange={handleChange} />
        <br />
        Customer Mobile:
        <input type="TEXT" name="CUSTOMER_MOBILE_NO" value={formData.CUSTOMER_MOBILE_NO} onChange={handleChange} />
        <br />
        Transaction Amount:
        <input type="TEXT" name="TXNAMT" value={formData.TXNAMT} onChange={handleChange} /><br />
        Basket ID:
        <input type="TEXT" name="BASKET_ID" value={formData.BASKET_ID} onChange={handleChange} />
        <br />
        Transaction Date:
        <input type="TEXT" name="ORDER_DATE" value={formData.ORDER_DATE} onChange={handleChange} />
        <br />
        Signature:
        <input type="TEXT" name="SIGNATURE" value={formData.SIGNATURE} onChange={handleChange} />
        <br />
        Version:
        <input type="TEXT" name="VERSION" value={formData.VERSION} onChange={handleChange} /><br />
        Item Description:
        <input type="TEXT" name="TXNDESC" value={formData.TXNDESC} onChange={handleChange} /><br />
        Proccode:
        <input type="TEXT" name="PROCCODE" value={formData.PROCCODE} onChange={handleChange} />
        <br />
        <input type="SUBMIT" value="SUBMIT" />
      </form>
    </div>
  );
};

export default App;