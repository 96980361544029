

import {
    CREATE_FORM_REQUEST,
    CREATE_FORM_SUCCESS,
    CREATE_FORM_FAIL,

    GET_ALL_FORMS_REQUEST,
    GET_ALL_FORMS_SUCCESS,
    GET_ALL_FORMS_FAIL,

    DELETE_FORM_REQUEST,
    DELETE_FORM_SUCCESS,
    DELETE_FORM_FAIL,

    UPDATE_FORM_REQUEST,
    UPDATE_FORM_SUCCESS,
    UPDATE_FORM_FAIL,

    GET_FORM_DETAILS_REQUEST,
    GET_FORM_DETAILS_SUCCESS,
    GET_FORM_DETAILS_FAIL,

    CLEAR_ERRORS,
}
    from "../constants/FormConstants"


export const CreateFormReducer = (state = { form: {} }, action) => {
    switch (action.type) {
        case CREATE_FORM_REQUEST:
            return {
                loading: true,
            }
        case CREATE_FORM_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                formData: action.payload.formData,
            }
        case CREATE_FORM_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const GetAllFormReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ALL_FORMS_REQUEST:
            return {
                loading: true,
            }
        case GET_ALL_FORMS_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                formData: action.payload.formData,
                totalForm: action.payload.totalForm,
            }
        case GET_ALL_FORMS_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const DeleteFormReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_FORM_REQUEST:
            return {
                loading: true,
            }
        case DELETE_FORM_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
            }
        case DELETE_FORM_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const updateFormReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_FORM_REQUEST:
            return {
                loading: true,
            }
        case UPDATE_FORM_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
            }
        case UPDATE_FORM_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
export const getFormReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_FORM_DETAILS_REQUEST:
            return {
                loading: true,
            }
        case GET_FORM_DETAILS_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                formData: action.payload.formData
            }
        case GET_FORM_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}