
import "./school.css"
import skewBg from "../../images/message-01.png"
import President from "../../images/departments/handicapped children group-01.png"
import school1 from "../../images/departments/school1.JPG"
import school2 from "../../images/departments/school2.JPG"
import school3 from "../../images/departments/school3.JPG"
import school4 from "../../images/departments/school4.JPG"
import school5 from "../../images/departments/school5.JPG"
import school6 from "../../images/departments/school6.jpg"
import { useEffect } from "react"




const School = () => {
    useEffect(() => {
        const department = document.getElementById("department");
        department.classList.add("active-page");
        document.title = "PSRD High School"

        // Cleanup function
        return () => {
            department.classList.remove("active-page");
        };
    }, []);
    return (
        <>
            <section className="presidentMessage skew-style">
                <h2 className="mobile-heading">High school
                    <br /><strong>Achievements: July 2022 - June 2023</strong>
                </h2>
                <img src={skewBg} alt="" className="skew-img" />
                <div className="maxwidth flex column">
                    <div className="P-M-first-child flex heading-skew">
                        <div>
                            <h2>High school
                                <br /><strong>Achievements: July 2022 - June 2023</strong>
                            </h2>
                            <p>PSRD High School performs an indispensable service for the benefit of orthopedically challenged students. High school gives an equal opportunity to all the students, providing them with a quality education. Children are educated to believe in themselves and to be productive citizens of Pakistan.<br/><br/>
                                PSRD  provides free eduction, free textbooks, free transportation, free uniforms and nutrition.
                                It also provides free medical treatment under the supervision of professional doctors, expert psychologists and therapists for the less privileged.
                            </p>

                        </div>
                        <div>
                            <img src={President} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <br/>
            <br/>
            <br/>
            <br/>

            <section className="center-text">
                <div className="maxwidth flex column">
                    <div className="Activities flex">
                        <div>
                            <div >
                                <img src={school1} alt="" />
                            </div>


                            <div className="lineHeading flex" style={{ justifyContent: "space-between", width: "100%", marginTop: "1.6vmax" }}>
                                <div style={{ width: "70%", height: "0.2vmax", backgroundColor: "var(--purple)" }} >
                                </div>

                                <h5 style={{ color: "var(--blue)" }}>Independence Day</h5>
                            </div>

                        </div>
                        <div>
                            <div>
                                <img src={school2} alt="" />
                            </div>


                            <div className="lineHeading flex" style={{ justifyContent: "space-between", width: "100%", marginTop: "1.6vmax" }}>
                                <div style={{ width: "67%", height: "0.2vmax", backgroundColor: "var(--purple)" }} >
                                </div>

                                <h5 style={{ color: "var(--blue)" }}>World Disability Day</h5>
                            </div>
                        </div>
                        <div>
                            <div>
                                <img src={school3} alt="" />
                            </div>

                            <div className="lineHeading flex" style={{ justifyContent: "space-between", width: "100%", marginTop: "1.6vmax" }}>
                                <div style={{ width: "70%", height: "0.2vmax", backgroundColor: "var(--purple)" }} >
                                </div>

                                <h5 style={{ color: "var(--blue)" }}>Eid Gift Program</h5>
                            </div>
                        </div>

                    </div>


                </div>
            </section>
            <section>
                <div className="maxwidth flex column" >


                    <div className="Activities flex" style={{ margin: "4vmax auto" }}>
                        <div>
                            <div >
                                <img src={school4} alt="" />
                            </div>


                            <div className="lineHeading flex" style={{ justifyContent: "space-between", width: "100%", marginTop: "1.6vmax" }}>
                                <div style={{ width: "68%", height: "0.2vmax", backgroundColor: "var(--purple)" }} >
                                </div>

                                <h5 style={{ color: "var(--blue)" }}>Independence Day</h5>
                            </div>

                        </div>
                        <div>
                            <div>
                                <img src={school5} alt="" />
                            </div>


                            <div className="lineHeading flex" style={{ justifyContent: "space-between", width: "100%", marginTop: " 1.6vmax" }}>
                                <div style={{ width: "60%", height: "0.2vmax", backgroundColor: "var(--purple)" }} >
                                </div>

                                <h5 style={{ color: "var(--blue)" }}>Annual Summer Training</h5>
                            </div>
                        </div>
                        <div>
                            <div>
                                <img src={school6} alt="" style={{ transform: "translateY(-9vmax)" }} />
                            </div>

                            <div className="lineHeading flex" style={{ justifyContent: "space-between", width: "100%", marginTop: "1.6vmax" }}>
                                <div style={{ width: "43%", height: "0.2vmax", backgroundColor: "var(--purple)" }} >
                                </div>

                                <h5 style={{ color: "var(--blue)" }}>Wheelchair Distribution Ceremony</h5>
                            </div>
                        </div>

                    </div>


                </div>
            </section>
        </>
    )
}
export default School