import { useState } from "react"
import { useEffect } from "react"
import "./resetPassword.css"
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { resetPassword } from "../../../actions/userAction"
import Alert from "../../alert/Alert"

import { GetAllNewsletter } from "../../../actions/newsletterAction"





const ResetPassword = () => {

    const { error } = useSelector((state) => state.ForgotPassword)
    const { isAuthenticated } = useSelector((state) => state.user)



    const { id } = useParams()


    const dispatch = useDispatch()
    const navigate = useNavigate()


    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [isPassowrdMatch, setisPassowrdMatch] = useState(true)



    const submitForm = (e) => {
        e.preventDefault()

        if (password === confirmPassword) {
            setisPassowrdMatch(true)
            if (!password || !confirmPassword) {
                setisPassowrdMatch(false)
                return
            }
        } else {
            setisPassowrdMatch(false)
            return
        }
        const data = {
            password: password,
            confirmPassword: confirmPassword
        }

        console.log(id)
        dispatch(resetPassword(id, data))
    }


    useEffect(() => {



        const footer = document.querySelector(".totalFooter")
        footer.style.display = "none"
        document.title="Reset Password"

        return () => {
            footer.style.display = "block"
        }
    }, [])
    useEffect(() => {

        if (isAuthenticated) {
            navigate("/")
        }
    }, [isAuthenticated, navigate])
    useEffect(() => {

        dispatch(GetAllNewsletter())
    }, [])




    return (
        <>
            <section className="Emailverification flex resetPassword absolute">
                {error && <Alert type={false} time={4} message={error}></Alert>}

                <div className="flex column">
                    <form className="flex column" onSubmit={submitForm}>
                        <h3>reset your passoword</h3>
                        <div>
                            <input type="text" placeholder="New Password" value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div>
                            <input type="text" placeholder="Confirm Password" value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                        <p color="red" style={{ display: `${isPassowrdMatch ? "none" : "block"}` }}><i className="fa-solid fa-triangle-exclamation"></i> Password does not match</p>
                        <button type="submit" className="button2">Change</button>
                    </form>
                </div>
            </section>
        </>
    )
}
export default ResetPassword