import { Fragment, useEffect, useState } from "react"
import "./home.css"
import { Link } from "react-router-dom"
import headingImg from "../images/home/doctor checking child.jpg"
import Logo from "../images/psrd.png"

import google from "../images/home/google logo.png"
import google_g from "../images/home/google g.png"
import OutImpact1 from "../images/home/education our impact-01.png"
import OutImpact2 from "../images/home/health our impact-01.png"
import OutImpact3 from "../images/home/vocational our impact-01.png"


import Partner1 from "../images/home/pcp.png"
import Partner2 from "../images/home/pcc.png"
import Partner3 from "../images/home/rj.png"
import Partner4 from "../images/home/iof.png"
import Partner5 from "../images/home/pwtd.jpg"
import Partner6 from "../images/home/altso.png"
import Partner7 from "../images/home/SS FINAL LOGO 2.a4b964b4e7a0336e95ab.png"
import Partner8 from "../images/home/Icare logo.png"

import Img1 from "../images/home/mother teresa.png"
import Img2 from "../images/home/walid iqbal.png"
import Img3 from "../images/home/mustansar-Hussain-Tarar.png"
import Img4 from "../images/home/samina alvi.png"
import Img5 from "../images/home/general zia ul haq.png"
import Img6 from "../images/home/raza riaz.png"
import Img7 from "../images/home/inzimal ul haq.png"
import Img8 from "../images/home/begum nusrat bhutto.png"
import Img9 from "../images/home/Zafar Masud.png"

import childImg from "../images/home/psrd child.png"
import Video from "../images/video.mp4"



import DonateForm from "../donateForm/donateForm"

import { DonateCreateForm } from "../../actions/DonateFormAction"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"




const Home = () => {


    const [viewForm, setviewForm] = useState(false)


    const showForm = () => {
        setviewForm(true)
    }
    const HideForm = () => {
        setviewForm(false)
    }
    useEffect(() => {
        const HomeButton = document.getElementById("home");
        HomeButton.classList.add("active-page");

        document.title = "PSRD";
        // Cleanup function
        return () => {
            HomeButton.classList.remove("active-page");
        };
    }, []);

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loading, success, donateData, error } = useSelector((state) => state.Donate)

    const [isName, setIsName] = useState(true)
    const [isEmail, setisEmail] = useState(true)
    const [isnumber, setisnumber] = useState(true)
    const [isAddress, setIsAddress] = useState(true)
    const [isDonationtype, setisDonationtype] = useState(true)
    const [isamount, setisamount] = useState(true)

    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [number, setnumber] = useState()
    const [address, setaddress] = useState()
    const [donationType, setdonationType] = useState()
    const [amount, setAmount] = useState(null)


    useEffect(() => {
        if (success) {
            navigate(`/checkout/${donateData._id}`)
        }


    }, [success])



    const submitForm = (e) => {
        e.preventDefault()

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!name) {
            setIsName(false)
        } else {
            setIsName(true)
        }



        if (emailRegex.test(email)) {
            setisEmail(true)
        }
        else {
            setisEmail(false)
        }



        if (number && number.length === 11) {
            setisnumber(true)
        } else {
            setisnumber(false)
        }


        if (!address) {
            setIsAddress(false)
        } else {
            setIsAddress(true)
        }
        if (!donationType) {
            setisDonationtype(false)
        } else {
            setisDonationtype(true)
        }
        if (!amount) {
            setisamount(false)
        } else {
            setisamount(true)

        }

        const form = {
            name: name,
            email: email,
            number: number,
            address: address,
            donationType: donationType,
            amount: amount
        }

        console.log(form)

        if (isName && isEmail && isAddress && isnumber && isDonationtype) {
            dispatch(DonateCreateForm(form))
            console.log(form)
        } else {
            return
        }

    }

    return (
        <Fragment>

            <section className="heroSection" data-aos="fade-up">
                <div className="maxwidth flex">
                    <div className="flex column">
                        <h1 data-aos="fade-up" data-aos-delay="1000">
                            <span className="storke">Contribute for the </span>
                            <br />
                            Differently Abled</h1>
                        <div data-aos="fade-up" data-aos-delay="1500">
                            <h4>MAKING PAKISTAN INCLUSIVE FOR THE DIFFERENTLY ABLED SINCE 1957</h4>
                        </div>
                        <div className="heroSectionButton flex" data-aos="fade-up" data-aos-delay="2000">
                            <Link to={'/donate-now'} className="button2">Donate</Link>
                            <Link to={'/contact-us'} className="button2 button3">Contact</Link>
                        </div>
                    </div>
                    <div>

                        <div class="heroSection-img">
                            <img src={childImg} alt="" />
                        </div>
                    </div>

                </div>
            </section>
            <section className="home-welcome flex" data-aos="flip-up">
                <div className="maxwidth flex">
                    <div>
                        <video src={Video} autoPlay controls muted>
                        </video>
                    </div>
                    <div className="flex column">
                        <h4>Welcome to <span> PSRD</span></h4>
                        <h2>Pakistan Society for Rehabilitation of Differently Abled</h2>
                        <p>By the Grace of Almighty Allah, we celebrate another successful year at
                            Pakistan Society for Rehabilitation of Differently Abled (PSRD). This year
                            has been marked by a renewed commitment to raising awareness about
                            the challenges faced by persons with disabilities. Our efforts extended
                            beyond Lahore, reaching areas where our support was needed the most.
                        </p>
                        <div className="heroSectionButton flex">
                            <button onClick={() => showForm()} className="button2 purplebutton">Donate now</button>
                        </div>
                    </div>

                </div>
            </section>
            <section className="Abled flex column" data-aos="fade-up">
                <h2 className="h2-style">With Your Charity</h2>
                <h4 >We Help Brighten Lives of Differently Abled</h4>
                <div className="maxwidth flex">
                    <div className="flex column">
                        <div><img src={headingImg} alt="" /></div>
                        <h3>our mission</h3>
                        <p>The mission of PSRD is to</p>
                        <ul className="abled-ul">
                            <li>
                                <p>
                                    Improve the quality of life of people with physical disabilities
                                </p>
                            </li>
                            <li>
                                <p>
                                    Provide preventive, remedial, and rehabilitative treatment to the disabled and physically handicapped
                                </p>
                            </li>
                            <li>
                                <p> Endeavour to integrate the disabled into society as full, productive, and useful partners</p>
                            </li>
                            <li>
                                <p style={{ marginBottom: "20px" }}>Increase awareness about the needs of disabled people</p>
                            </li>
                        </ul>

                        <div>
                            <Link className="button2" to={"/donate-now"}>Help us</Link>
                        </div>
                    </div>
                    <div className="flex abled-blocks column">

                        <div className="flex flex column">
                            <div className="numbering">01</div>
                            <h3>SEND US DONATIONS</h3>
                            <p>Join Hands with PSRD! Your contribution as donations, zakat, or sadaqat can help us shape the future of Differently Abled children</p>
                            <button className="button2 button3" onClick={() => showForm()}>Donate</button>
                        </div>
                        <div className="flex flex column">
                            <div className="numbering">02</div>
                            <h3>SPONSOR A CHILD</h3>
                            <p>Your donations can educate an orthopedically challenged child from a low-income family</p>
                            <button className="button2 button3" onClick={() => showForm()}>Donate</button>
                        </div>
                        <div className="flex flex column">
                            <div className="numbering">03</div>
                            <h3>BECOME A VOLUNTEER</h3>
                            <p>Your small efforts or initiatives can bring a significant change in Differently Abled children's lives</p>
                            <a href="https://wa.me/+923065555201" target="_blank" className="button2 button3">Contact</a>
                        </div>

                    </div>
                </div>
            </section>
            <section className="home-donate-now flex" data-aos="fade-up">
            <div className="maxwidth flex">
                            <div className="home-donate-form">
                                <form action="" className="flex column">
                                    <div className="donate-logo">
                                        <img src={Logo} alt="" />
                                    </div>
                                    <h3 className="donate-heading">Support Us</h3>
                                    <div className="flex">
                                        <span style={{ background: `${isamount}` }}>PKR</span>
                                        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                    </div>
                                    <label className="button2" for="inputName1">Next <i className="fa-solid fa-arrow-right"></i></label>
                                </form>
                            </div>
                            <div className="home-donate-heading">
                                <form onSubmit={submitForm} className="flex column">
                                    <h3 className="donate-heading">Form Details </h3>
                                    <div>
                                        <p>Donate<span>*</span></p>
                                        <div className="flex radio">
                                            <div>
                                                <input type="radio" id="radio1" name="radios" value="Zakat" onChange={(e) => setdonationType(e.target.value)} />
                                                <label for="radio1">Zakat</label>
                                            </div>
                                            <div>
                                                <input type="radio" id="radio2" name="radios" value="Donation" onChange={(e) => setdonationType(e.target.value)} />
                                                <label for="radio2">Donation</label>
                                            </div>
                                            <div>
                                                <input type="radio" id="radio3" name="radios" value="Sadqat" onChange={(e) => setdonationType(e.target.value)} />
                                                <label for="radio3">Sadqat</label>
                                            </div>
                                            <p style={{ display: `${isDonationtype ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Required</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p>Full Name<span>*</span></p>
                                        <input type="text"
                                            id="inputName1"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        <p style={{ display: `${isName ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Required</p>
                                    </div>
                                    <div>
                                        <p>Phone Number<span>*</span></p>
                                        <input type="number"
                                            value={number}
                                            onChange={(e) => setnumber(e.target.value)}
                                        />
                                        <p style={{ display: `${isnumber ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> please Enter a valid number</p>
                                    </div>
                                    <div>
                                        <p>Email Address<span>*</span></p>
                                        <input type="text"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <p style={{ display: `${isEmail ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Please Enter a valid Email</p>
                                    </div>

                                    <div>
                                        <p>Town / city<span>*</span></p>
                                        <input type="text"
                                            value={address}
                                            onChange={(e) => setaddress(e.target.value)}
                                        />
                                        <p style={{ display: `${isAddress ? "none" : "block"}` }} className="messageAlert"><i className="fa-solid fa-triangle-exclamation"></i> Required</p>
                                    </div>
                                    <div>
                                        <button type="submit" className="button2"
                                            disabled={amount ? false : true}
                                            style={{ cursor: amount ? "pointer" : "not-allowed", background: amount ? "var(--blue" : "lightGrey" }}
                                        >Donate</button>
                                    </div>
                                </form>
                            </div>
                        </div>
            </section>
            <section className="ourimpact flex column ourimpact-1" data-aos="fade-up">
                <h2 className="h2-style">OUR IMPACT IN THE LAST DECADE</h2>
                <div className="impactlast maxwidth flex">
                    <div className="impactlast-img" style={{ padding: "13vmax 0" }}></div>
                    <div className="flex column">
                        <h3>Education</h3>
                        <p>
                            Besides free quality education, PSRD High School provides free books, uniforms, pick-up and drop-off facilities, and free meals to the differently abled. PSRD’s Skill Development Centre is also performing exceptionally well. It not only imparts computer skills and arts and crafts to the students but also enables them to earn good wages by making items on order for commercial brand outlets. A total of 4361 students have been admitted to PSRD High School from 2005 to 2022.</p>
                        <button className="button2 button3" onClick={() => showForm()}>Donate Now</button>
                    </div>
                    <div className="flex column impactlast-counter">
                        <div>
                            <img src={OutImpact1} alt="" />
                        </div>
                        <h3>1
                            4,361+</h3>
                    </div>
                </div>
            </section>
            <section className="ourimpact" data-aos="fade-up">
                <div className="impactlast maxwidth flex  ">
                    <div className="flex column impactlast-counter">
                        <div>
                            <img src={OutImpact2} alt="" />
                        </div>
                        <h3>12,329,843+</h3>
                    </div>
                    <div className="flex column tab-error-1 center-div">
                        <h3>Health</h3>
                        <p>Every year, around 22,500 patients are registered and categorized according to their paying capacity. Out of this, almost 20% are treated free of charge, and the rest are treated at highly concessional rates. They are catered to properly, keeping quality standards high. Orthotics & Prosthetics provided 3630 appliances to 22869 patients. The physiotherapy centre attended 7713 patients who were given 59042 treatment sessions and 495 patients who were given 945 treatment sessions, specifically in collaboration with Shaukat Khanum Memorial Cancer Hospital, the School of Allied Health Sciences, and the Children’s Hospital.</p>
                        <button className="button2 button3" onClick={() => showForm()}>Donate Now</button>
                    </div>

                    <div className="impactlast-img our-impact-2" style={{ padding: "14.25vmax 0px" }}></div>

                </div>
            </section>
            <section className="ourimpact ourimpact-3 " data-aos="fade-up">
                <div className="impactlast maxwidth flex">
                    <div className="impactlast-img our-impact-3" style={{ padding: "12.4vmax 0px" }}></div>
                    <div className="flex column ">
                        <h3>Vocational</h3>
                        <p>CBR and VRC are active in providing valuable services to underprivileged men and women with disabilities to start their own small businesses. PSRD aims to provide them with microcredit facilities to start their own small businesses and help them stand on their own feet. They also provide immunization programs, primary education, and vocational training to poor women and children living in the outlying areas of Lahore.</p>
                        <button className="button2 button3" onClick={() => showForm()}>Donate Now</button>
                    </div>
                    <div className="flex column impactlast-counter">
                        <div>
                            <img src={OutImpact3} alt="" />
                        </div>
                        <h3>33,540+</h3>
                    </div>
                </div>
            </section>
            <section className="partners flex column">
                <h2 className="h2-style">PARTNERS & AFFILIATIONS</h2>
                <div className="maxwidth flex">
                    <a href="https://pcp.org.pk/" target={"_blank"} rel="noreferrer" className="flex">
                        <img src={Partner1} alt="" />
                    </a>
                    <a href="https://charitycommission.punjab.gov.pk/" target={"_blank"} rel="noreferrer" className="flex">
                        <img src={Partner2} alt="" />
                    </a>
                    <a href="https://www.riglobal.org/" target={"_blank"} rel="noreferrer" className="flex">
                        <img src={Partner3} alt="" />
                    </a>
                    <a href="https://www.osteoporosis.foundation/" target={"_blank"} rel="noreferrer" className="flex">
                        <img src={Partner4} alt="" />
                    </a>
                    <a href="https://pwtd.org.pk/" target={"_blank"} rel="noreferrer" className="flex">
                        <img src={Partner5} alt="" />
                    </a>
                    <a href="https://www.altso.org/" target={"_blank"} rel="noreferrer" className="flex">
                        <img src={Partner6} alt="" />
                    </a>
                    <a href="https://sabsaath.org/" target={"_blank"} rel="noreferrer" className="flex">
                        <img src={Partner7} alt="" />
                    </a>
                    <a href="https://i-care-foundation.org/" target={"_blank"} rel="noreferrer" className="flex">
                        <img src={Partner8} alt="" />
                    </a>
                </div>
            </section>
            <section className="google-reviews google-reviews-1" data-aos="fade-up">
                <div className="maxwidth flex">
                    <div className="reviews flex">
                        <div className="flex column">
                            <div className="reviews-block flex column">
                                <div><img src="https://lh3.googleusercontent.com/a/ACg8ocJ_HyjX2cVkOTpoDj7PeVGZsxgYqjjF13g8LwYXewCskT4LlA=w49-h49-p-rp-mo-br100" alt="" /></div>
                                <h4>Muhammad Abdullah</h4>
                                <div>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                </div>
                                <p>I came here with my two handicap kids. Doctor did . . .
                                    <a href="https://maps.app.goo.gl/rVb1rKHJqxznkW6f8" target="_blank" rel="noreferrer">
                                        <p
                                            style={{
                                                borderBottom: "1px solid",
                                                display: "inline-block",
                                                color: "var(--blue)",
                                                paddingLeft: "0.4vmax",
                                                fontSize: "1vmax"
                                            }}>
                                            Learn More
                                        </p>
                                    </a>
                                </p>
                                <dir>
                                    <img src={google} alt="" />
                                </dir>
                            </div>
                            <div className="reviews-block flex column">
                                <div><img src="https://lh3.googleusercontent.com/a-/ALV-UjVZC-SD7Qqar6heABwY7tPKCddMzlJrKhFFjTiLf6q4eB3pnQgh3g=w49-h49-p-rp-mo-ba4-br100" alt="" /></div>
                                <h4>Azhar Nazir</h4>
                                <div>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                </div>
                                <p>A great place for disables whom they make . . .
                                    <a href="https://maps.app.goo.gl/pPkqLyCeyUqmcFcTA" target="_blank" rel="noreferrer">
                                        <p
                                            style={{
                                                borderBottom: "1px solid",
                                                display: "inline-block",
                                                color: "var(--blue)",
                                                paddingLeft: "0.4vmax",
                                                fontSize: "1vmax"
                                            }}>
                                            Learn More
                                        </p>
                                    </a> </p>
                                <dir>
                                    <img src={google} alt="" />
                                </dir>
                            </div>

                        </div>
                        <div className="flex column">
                            <div className="reviews-block flex column official">
                                <div><img src={google_g} alt="" /></div>
                                <div>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                </div>
                            </div>
                            <div className="reviews-block flex column">
                                <div><img src="https://lh3.googleusercontent.com/a-/ALV-UjXwEddk43oehSGE16trDotE0_sVutgJvN8XaJYqekZ0eghd2cm_=w49-h49-p-rp-mo-ba3-br100" alt="" /></div>
                                <h4>Dr. Muhammad</h4>
                                <div>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                </div>
                                <p>PSRD is well organised setup for rehabilitation of disabled.</p>
                                <dir>
                                    <img src={google} alt="" />
                                </dir>
                            </div>
                            <div className="reviews-block flex column">
                                <div><img src="https://lh3.googleusercontent.com/a-/ALV-UjVUxOmQv8FDFc7pCN9aVKmQ9GE9y9MLDoEsadxFWii1l4DFiYYY=w49-h49-p-rp-mo-ba2-br100" alt="" /></div>
                                <h4>kashif latif</h4>
                                <div>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                </div>
                                <p>It's a good place for the treatment of both  rich & poor . . .
                                    <a href="https://maps.app.goo.gl/h1MSiMQgvyKvTbm7A" target="_blank" rel="noreferrer">
                                        <p
                                            style={{
                                                borderBottom: "1px solid",
                                                display: "inline-block",
                                                color: "var(--blue)",
                                                paddingLeft: "0.4vmax",
                                                fontSize: "1vmax"
                                            }}>
                                            Learn More
                                        </p>
                                    </a>
                                </p>
                                <dir>
                                    <img src={google} alt="" />
                                </dir>
                            </div>
                        </div>
                        <div className="flex column">
                            <div className="reviews-block flex column">
                                <div><img src="https://lh3.googleusercontent.com/a-/ALV-UjXABzW3sMgV6mz5-jLKLVG_2IVESp_JATFzOBVcdrzTJtgH8WFr8Q=w49-h49-p-rp-mo-ba6-br100" alt="" /></div>
                                <h4>Badal Baloch</h4>
                                <div>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star" style={{ color: "#eeeeee" }}></i>
                                </div>
                                <p>A very good hospital with dealing peaditric problems . . .

                                    <a href="https://maps.app.goo.gl/3Bdai5WpsDk5Waji8" target="_blank" rel="noreferrer">
                                        <p
                                            style={{
                                                borderBottom: "1px solid",
                                                display: "inline-block",
                                                color: "var(--blue)",
                                                paddingLeft: "0.4vmax",
                                                fontSize: "1vmax"
                                            }}>
                                            Learn More
                                        </p>
                                    </a>
                                </p>
                                <dir>
                                    <img src={google} alt="" />
                                </dir>
                            </div>
                            <div className="reviews-block flex column">
                                <div><img src="https://lh3.googleusercontent.com/a-/ALV-UjUhGITfSvGhdqCxUyAzH3W7hqwqJsLcV0Tc6mXJP2yqu7rLCFxG=w49-h49-p-rp-mo-ba4-br100" alt="" /></div>
                                <h4>Munam Khan</h4>
                                <div>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                </div>
                                <p>I went through a spine surgery on last Friday performed . . .
                                    <a href="https://maps.app.goo.gl/kGE89PxnMhvyft5Z6" target="_blank" rel="noreferrer">
                                        <p
                                            style={{
                                                borderBottom: "1px solid",
                                                display: "inline-block",
                                                color: "var(--blue)",
                                                paddingLeft: "0.4vmax",
                                                fontSize: "1vmax"
                                            }}>
                                            Learn More
                                        </p>
                                    </a>
                                </p>
                                <dir>
                                    <img src={google} alt="" />
                                </dir>
                            </div>
                        </div>

                    </div>
                    <div className="google-reviews-heading flex column">
                        <h3>
                            <span className="storke">PSRD </span>
                            <br />
                            Pakistan Society for  Rehabilitation of  differently Abled
                        </h3>
                        <div>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i class="fa-solid fa-star-half-stroke"></i>
                        </div>
                        <p>81 Google reviews</p>
                        <div className="mobile-error-5">
                            <a href="https://www.google.com/maps/place//data=!4m3!3m2!1s0x3919049a91571d0f:0x7abfcad02a330ad!12e1" target="_blank" rel="noreferrer" className="button2 button3">
                                Write Review
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="visitors" data-aos="fade-up">
                <div className=" flex maxwidth column">
                    <h2 className="h2-style">Our Visitors</h2>
                    <div className="visitors-block flex">
                        <div className="visitors-block-1 desktopView flex">
                            <div className="flex column">
                                <div className="B-O-G-block-2 flex column">
                                    <div>
                                        <img src={Img9} alt="" />
                                    </div>
                                    <h3>Zafar <br />Masud</h3>
                                </div>
                                <div className="B-O-G-block-2 flex column">

                                    <div>
                                        <img src={Img7} alt="" />
                                    </div>
                                    <h3>Inzamam <br /> ul Haq</h3>

                                </div>
                                <div className="B-O-G-block-2 flex column">
                                    <div>
                                        <img src={Img3} alt="" />
                                    </div>
                                    <h3>Mustansar<br /> Hussain Tarar</h3>

                                </div>
                            </div>
                            <div className="flex column">

                                <div className="B-O-G-block-2 flex column">
                                    <div>
                                        <img src={Img1} alt="" />
                                    </div>
                                    <h3>Mother <br />Teresa</h3>

                                </div>
                                <div className="B-O-G-block-2 flex column">
                                    <div>
                                        <img src={Img5} alt="" />
                                    </div>
                                    <h3>Muhammad<br /> Zia ul Haq</h3>

                                </div>
                                <div className="B-O-G-block-2 flex column">
                                    <div>
                                        <img src={Img6} alt="" />
                                    </div>
                                    <h3>Raza <br /> Riaz</h3>

                                </div>
                            </div>
                            <div className="flex column">
                                <div className="B-O-G-block-2 flex column">
                                    <div>
                                        <img src={Img4} alt="" />
                                    </div>
                                    <h3>Samina <br /> Alvi</h3>

                                </div>
                                <div className="B-O-G-block-2 flex column">
                                    <div>
                                        <img src={Img8} alt="" />
                                    </div>
                                    <h3>begum<br /> nusrat bhutto</h3>
                                </div>
                                <div className="B-O-G-block-2 flex column">
                                    <div>
                                        <img src={Img2} alt="" />
                                    </div>
                                    <h3>Walid <br />Iqbal</h3>

                                </div>
                            </div>

                        </div>
                        <div className="visitors-block-1 mobileView flex">
                            <div className="B-O-G-block flex column">
                                <div>
                                    <img src={Img1} alt="" />
                                </div>
                                <h3>Mother Teresa</h3>

                            </div>
                            <div className="B-O-G-block flex column">
                                <div>
                                    <img src={Img2} alt="" />
                                </div>
                                <h3>Walid Iqbal</h3>

                            </div>
                            <div className="B-O-G-block flex column">
                                <div>
                                    <img src={Img3} alt="" />
                                </div>
                                <h3>Mustansar Hussain Tarar</h3>

                            </div>
                            <div className="B-O-G-block flex column">
                                <div>
                                    <img src={Img4} alt="" />
                                </div>
                                <h3>Samina Alvi</h3>

                            </div>
                            <div className="B-O-G-block flex column">
                                <div>
                                    <img src={Img5} alt="" />
                                </div>
                                <h3>Muhammad Zia-ul-Haq</h3>

                            </div>
                            <div className="B-O-G-block flex column">
                                <div>
                                    <img src={Img6} alt="" />
                                </div>
                                <h3>Raza Riaz</h3>

                            </div>
                            <div className="B-O-G-block flex column">
                                <div>
                                    <img src={Img7} alt="" />
                                </div>
                                <h3>Inzamam-ul-Haq</h3>

                            </div>
                            <div className="B-O-G-block flex column">
                                <div>
                                    <img src={Img8} alt="" />
                                </div>
                                <h3>begum nusrat bhutto</h3>
                            </div>
                            <div className="B-O-G-block flex column">
                                <div>
                                    <img src={Img9} alt="" />
                                </div>
                                <h3>Zafar Masud</h3>
                            </div>
                        </div>
                        <div className="visitors-block-2 flex">
                            <iframe src="https://online.flippingbook.com/view/995362784/?_gl=1*wcdws7*_gcl_au*MTA3NTE5MTczMy4xNzEzNjM0NDM0" frameborder="0" style={{ width: "100%", height: "36vmax" }} allowFullScreen="true" scrolling="no" ></iframe>
                        </div>


                    </div>
                </div>
            </section>
            <div className={`donate-form ${viewForm ? "show-form" : ""}`}>
                <DonateForm />
                <button className="popup-close-button" onClick={() => HideForm()}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>

        </Fragment>



    )
}
export default Home